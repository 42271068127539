<template>
    <div class="timeline">
        <div class="timeline-wrapper">
            <div v-for="item, index in events" :class="step_class[index]" :key="index">
                <div class="timeline-status"></div>
                <div class="timeline-p">
                    <h1>{{ item.name }}</h1>
                    <span>{{ item.date }}</span>
                    <span v-if="item.delta"> ({{ item.delta }})</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    name: "Timeline",
    props: {
        events: {
            type: Array,
            default: () => { return []; }
        }
    },
    computed: {
        step_class: function() {
            return this.events.map(value => `timeline-object ${value?.status ? value.status : ""}`);
        }
    }
    }
</script>

<style scoped lang="scss">


$green: #1DBF73;
$red: #bd1616;
$gray: #C5C5C5;

    * {
        margin: 0;
        padding: 0;
        border: none;
        box-sizing: border-box;
    }

    .timeline {
        display: flex;
        align-items: center;
        //justify-content: center;

        user-select: none;
        margin-bottom: 1.5em;
    }

    %complete {
        content: "";
        display: block;
        position: absolute;
        width: 4px;
        height: 100px;
        left: 23px;
        top: 58px;
        transform: translateX(-50%);
        // z-index: -1;
    }
    

    .timeline-wrapper .timeline-object {

        display: flex;
        align-items: center;
        margin-top: 50px;
        position: relative;

        .timeline-status {
            height: 46px;
            width: 46px;
            border-radius: 50%;
            background: $green;
        }

        .timeline-p {
            margin-left: 15px;
    
            h1 {
                font-size: 22px;
                color: $green;
                background: #b4ffdc;
                padding: 5px 10px;
                border-radius: 5px;
                margin-bottom: 10px;
            }
    
            span {
                color: #103b27;
                font-size: 16px;
                font-weight: 500;
            }
            
        }

        &.complete:after {
            &:not(:last-child){ 
                @extend %complete;
                background: $green;
            }
        }

        &.illegal:after {
            &:not(:last-child){
                @extend %complete;
                background: $red;
            }
        }

        &.illegal {
            &:not(:last-child):after {
                @extend %complete;
                background: $red;
            }

            .timeline-status {
                background: $red;
                border: 4px solid $red;
            }

            .timeline-p h1 {
                color: $red;
                background: #dbdbdb;
            }
        }

        &.complete {
            &:not(:last-child):after {
                @extend %complete;
                background: $green;
            }

            .timeline-status {
                background: $green;
                border: 4px solid $green;
            }

            .timeline-p h1 {
                color: $green;
                background: #dbdbdb;
            }
        }

        &.pending {
            &:not(:last-child):after {
                @extend %complete;
                background: gray;
            }

            .timeline-status {
                background: #FFFFFF;
                border: 4px solid gray;
            }

            .timeline-p h1 {
                color: #8a8a8a;
                background: #dbdbdb;
            }
        }
    }

</style>

