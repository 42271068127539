<template>
    <div id="setup">
        <b-container v-if="userPerms">
            <b-row>
                <b-col cols="12">
                    <h1>Permisos</h1>
                    <div v-if="hasPerm('can_query_tts')" class="item-container-small">
                        <div class="item-img-small">
                            <img src="../assets/images/icns/flat/a-b-testing.png">
                        </div>
                        <div class="item-link-small">
                            <h2>Ticketera</h2>
                            <p>Podés ver los tickets relacionados a las órdenes de trabajo</p>
                        </div>
                    </div>
                    <h1>Configuración de operaciones</h1>
                    <div v-if="hasPerm('can_edit_timestripes')" class="item-container">
                        <div class="item-img">
                            <img src="../assets/images/icns/flat/prototyping.png">
                        </div>
                        <div class="item-link">
                            <h2><a :href="this.$router.resolve({ name: 'edit_subsidiaries'}).href">Editar franjas horarias</a></h2>
                            <p>Editar franjas horarias, por operación.</p>
                        </div>
                    </div>
                    <div v-if="hasPerm('can_edit_priorities')" class="item-container">
                        <div class="item-img">
                            <img src="../assets/images/icns/flat/ux-review.png">
                        </div>
                        <div class="item-link">
                            <h2><a :href="this.$router.resolve({ name: 'edit_priorities'}).href">Editar prioridades</a></h2>
                            <p>Editar prioridades según síntoma de Boxi</p>
                        </div>
                    </div>
                    <div v-if="hasPerm('can_edit_zones')" class="item-container">
                        <div class="item-img">
                            <img src="../assets/images/icns/flat/index.png">
                        </div>
                        <div class="item-link">
                            <h2><a :href="this.$router.resolve({ name: 'edit_zones'}).href">Editar Zonas</a></h2>
                            <p>Crear/Editar zonas (Por Nodos)</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { getUserProfile } from "../common"
    import store from "../store"

    export default {
    name: "SetupView",
    data: function() {
        return {
            userPerms: null
        }
    },
    created: async function() {
        {
            let tempPerms = await getUserProfile(store.state.username, store.state.token);
            this.userPerms = tempPerms.permissions;
        }
    },
    methods: {
        hasPerm: function(permission) {
            if (this.userPerms.indexOf(permission) >= 0)
            {
                return true;
            }
            return false;
        }
    }
    }
</script>

<style scoped>
    #setup {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }

    .item-container {
        min-height: 128px;
        width: 100%;
        display: inline-flex;
    }
    .item-img {
        padding: 15px;
        width: 3rem;
        height: 3rem;
    }
    .item-img img {
        width: 3rem;
        height: 3rem;
    }

    .item-link {
        padding: 15px;
        padding-left: 30px;
        font-size: 1.25em;
        width: 85%;
        vertical-align: middle;
        align-items: center;
    }

    .item-container-small {
        min-height: 64px;
        width: 100%;
        display: inline-flex;
    }
    .item-img-small {
        padding: 15px;
        width: 64px;
        height: 64px;
    }
    .item-img-small img {
        width: 49px;
        height: 49px;
    }

    .item-link-small {
        padding: 15px;
        font-size: 0.75em;
        width: 85%;
        vertical-align: middle;
        align-items: center;
    }

</style>

