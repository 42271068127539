<template>
    <div id="finishvisit">
        <b-modal :id="'FinishModal_' + visit_id" size="xl" :title="'Modificar visita'" scrollable @hide="resetModalData">
            <b-tabs v-model="tabIndex" content-class="mt-3">
                <b-tab title="Solución" active>
                    <b-card-text>
                        <b-form v-if="reasons">
                            <b-form-group id="input-group-3" label="Estado:" label-for="input-3">
                                <b-form-select
                                id="input-3"
                                name="input-3"
                                v-model="form.status"
                                :options="reasons"
                                required
                                @change="setEstadoTxt"
                                ></b-form-select>
                            </b-form-group>
                            <b-alert variant="warning"
                                v-if="capabilities && capabilities.order_canceled" show>La órden relacionada con esta visita se encuentra anulada en Boxicable</b-alert>
                            <b-alert variant="warning"
                                v-if="capabilities && capabilities.order_fulfilled" show>La órden relacionada con esta visita se encuentra cumplida en Boxicable</b-alert>
                            <div v-if="capabilities && capabilities.order_fulfilled && capabilities.order_tech_id && finalizes_order">
                                <b-form-checkbox
                                    v-if="capabilities && capabilities.order_fulfilled && capabilities.allow_enter_materials_and_labors && !capabilities.have_labor && !capabilities.have_materials"
                                    :disabled="capabilities && !capabilities.order_fulfilled || !capabilities.order_tech_id || !finalizes_order || capabilities.have_labor || capabilities.have_materials "
                                    id="checkbox-2"
                                    class="mb-1"
                                    v-model="form.set_materials"
                                    name="checkbox-2">
                                        Descargar materiales y/o mano de obra 
                                </b-form-checkbox>
                                <b-alert variant="warning" show v-if="this.warning_materials_and_labors"> {{this.warning_materials_and_labors}} </b-alert>
                            </div>

                            <div v-if="capabilities && (capabilities.can_close_order) && !capabilities.order_fulfilled && !capabilities.order_canceled && capabilities.order_tech_id && finalizes_order">
                                <b-form-checkbox
                                    v-if="capabilities && (capabilities.can_close_order)"
                                    :disabled="capabilities && capabilities.order_fulfilled || !capabilities.order_tech_id || !finalizes_order"
                                    id="checkbox-1"
                                    class="mb-1"
                                    v-model="form.finish_order"
                                    name="checkbox-1">
                                        Cumplir OT en Boxi (se descargarán materiales y/o mano de obra)
                                </b-form-checkbox>
                            </div>

                            <b-alert variant="warning"
                                v-if="capabilities && capabilities.can_close_order && capabilities &&  !capabilities.order_tech_id" show>No se puede cumplir esta órden ya que no tiene técnico asignado en Boxi</b-alert>
                            <b-alert variant="warning"
                                v-if="capabilities && !capabilities.order_fulfilled && !capabilities.order_canceled && form.finish_order" show>Si la órden requiere un cambio de equipo, la misma deberá cumplirse a través del portal</b-alert>

                            <div class="mb-3" v-if="capabilities && finalizes_order">
                                <b-form-checkbox
                                    v-if="capabilities && finalizes_order"
                                    id="checkbox-2"
                                    class="mb-1"
                                    v-model="derives_service"
                                    name="checkbox-2">
                                        Derivar a contratista
                                </b-form-checkbox>

                                <!-- Comentamos el combobox de derivar visita. Si necesitamos agregarlo, se puede descomentar -->
                                <!-- <b-form-group v-if="derives_service" id="input-group-99">
                                    <b-form-select
                                    id="input-99"
                                    name="input-99"
                                    v-model="derivation_type"
                                    :options="Object.keys(derivations)"
                                    required
                                    ></b-form-select>
                                </b-form-group> -->

                            </div>
                            
                            <b-form-group id="input-group-4" class="mt-3" v-if="form.finish_order" label="Reparación:*" label-for="input-4">
                                <b-form-select
                                id="input-4"
                                name="input-4"
                                v-model="form.repair_code"
                                :options="repair_codes"
                                required
                                ></b-form-select>
                            </b-form-group>
                            <b-form-group id="input-group-21" :label="cancels_visit ? 'Notas*:' : 'Notas:'" label-for="input-21">
                                <b-form-textarea
                                    id="textarea"
                                    v-model="form.extra_comments"
                                    placeholder="Escriba su texto..."
                                    rows="3"
                                    max-rows="6"
                                    :required="cancels_visit"
                                ></b-form-textarea>
                            </b-form-group>

                            <b-form-group id="input-group-18" v-show="finalizes_visit">
                                <p>Firma del cliente:</p>
                                <img class="mb-2" :src="form.signature" width="150px" height="150px" align="left" v-show="form.signature">
                                <p v-show="!form.signature"><i>El cliente no ha firmado la visita.</i></p>
                            </b-form-group>

                        </b-form>
                        <!-- <p class="float-left">Finalizar la visita no cierra la orden en Boxicable</p> -->
                        <b-alert variant="info" :show="finalizes_visit">Importante: esta acción finalizará la visita</b-alert>
                        <b-alert variant="warning" :show="cancels_visit">Importante: Esta acción cancelará la visita. Se recomienda reagendar la visita, a menos que no se pueda estimar una fecha de resolución</b-alert>
                        <b-alert variant="info" :show="!cancels_visit && !finalizes_visit">Esta acción no concluirá la visita</b-alert>
                    </b-card-text>
                </b-tab>
                <b-tab title="Materiales" v-if="!tabsDisabled && capabilities && !capabilities.have_materials && !capabilities.have_labor">
                    <b-card-text class="mt-4">
                        <b-input-group class=" mb-2">
                            <b-form-input v-model="queryText" placeholder="Buscar materiales" style="height:45px;"></b-form-input>
                            <b-input-group-append>
                                <b-button @click="queryText = ''"><b-icon icon="x"></b-icon></b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <div v-for="filteredMaterial, index in filteredMaterials" :key="filteredMaterial.code" class="item-row">
                            <b-row class="pt-3 pb-3 pl-2 pr-2">
                                <b-col cols="12" md="9" class="d-flex align-items-center">
                                    <p class="mb-0">{{ filteredMaterial.name }}</p>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <!-- <p><up-down-input v-model="material.amount"></up-down-input></p> -->
                                    <up-down-input :collection="materials" :filteredItemCode="filteredMaterial.code" />
                                </b-col>
                            </b-row>
                            <!-- <hr class="d-md-block d-lg-none"> -->
                            <hr class="d-md-block m-0" v-if="index < filteredMaterials.length - 1">
                        </div>
                        <div v-if="filteredMaterials.length === 0" class="mt-5 mb-5 d-flex flex-column align-items-center justify-content-center">
                            <h3 class="mb-1"><strong>Sin resultados</strong></h3>
                            <span class="text-center">No pudimos encontrar ningún resultado para tu búsqueda</span>
                        </div>
                        <hr class="mt-1">
                        <b-form-group
                            label="Otros materiales / Comentarios"
                            label-for="textarea-materials"
                            description="Otros materiales que no están en el listado anterior o comentarios acerca de los materiales"
                            class="mb-0"
                            >
                            <b-form-textarea
                                id="textarea-materiales"
                                v-model="form.material_comments"
                                placeholder="Ingrese su texto"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-card-text>
                </b-tab>
                <b-tab title="Mano de Obra" v-if="!tabsDisabled && capabilities && !capabilities.have_materials && !capabilities.have_labor">
                    <b-card-text class="mt-4">

                        <b-input-group class=" mb-2">
                            <b-form-input v-model="laborsQueryText" placeholder="Buscar mano de obra" style="height:45px;"></b-form-input>
                            <b-input-group-append>
                                <b-button @click="laborsQueryText = ''"><b-icon icon="x"></b-icon></b-button>
                            </b-input-group-append>
                        </b-input-group>

                        <div v-for="filteredLabor, index in filteredLabors" :key="filteredLabor.code" class="item-row">
                            <b-row class="pt-3 pb-3 pl-2 pr-2">
                                <b-col cols="12" md="9" class="d-flex align-items-center">
                                    <p class="mb-0">{{ filteredLabor.name }}</p>
                                </b-col>
                                <b-col cols="12" md="3">
                                    <up-down-input :collection="labors" :filteredItemCode="filteredLabor.code" />
                                </b-col>
                            </b-row>
                            <hr class="d-md-block m-0" v-if="index < filteredLabors.length - 1">
                        </div>
                        <div v-if="filteredLabors.length === 0" class="mt-5 mb-5 d-flex flex-column align-items-center justify-content-center">
                            <h3 class="mb-1"><strong>Sin resultados</strong></h3>
                            <span class="text-center">No pudimos encontrar ningun resultado para tu busqueda</span>
                        </div>
                        <hr class="mt-1">
                        <b-form-group
                                label="Otra mano de obra / Comentarios "
                                label-for="textarea-labor"
                                description="Mano de obra que no está en el listado anterior o comentarios acerca de mano de obra"
                                class="mb-0"
                                >
                                <b-form-textarea
                                    id="textarea-labor"
                                    v-model="form.labor_comments"
                                    placeholder="Ingrese su texto"
                                ></b-form-textarea>
                            </b-form-group>
                    </b-card-text>
                </b-tab>

                <b-tab title="Firma" v-bind:title-item-class="{'d-none': tabsDisabled, '': !tabsDisabled}">  <!-- Ocultamos la tab con una clase y no con v-if ya que si ingresaron la firma del cliente queremos que siga cargada aunque varien los estados del cierre de visita, ya que sino al cambiar de estado la misma se borraria y el usuario no se daria cuanta que debe firmar nuevamente -->
                    <b-card-text>
                        <b-container @click="updateSignature">
                            <VueSignaturePad
                                width="500px" 
                                height="500px"
                                :customStyle="sigStyle"
                                :options="options"  
                                ref="signaturePad" />
                        </b-container>
                        <br>
                        <b-btn class="center" @click="confirmDeleteSignature">Borrar firma</b-btn>
                    </b-card-text>
                </b-tab>

            </b-tabs>

            <template #modal-footer>
                <div class="w-100">
                    <!-- <b-button class="float-right" type="reset" variant="danger">Limpiar campos</b-button> -->
                    <b-button v-if="tabIndex == 0" class="float-right ml-2" type="submit" variant="success" :disabled="trxBusy" @click="postFinishVisit()">Guardar Cambios</b-button>
                    <!-- <b-button variant="primary" class="float-right ml-2" @click="closeFinishModal()">Descartar cambios</b-button> -->
                    </div>
            </template>
        </b-modal>
    </div>
</template>

<script>

    import axios from 'axios'
    import store from '../store'
    import UpDownInput from "./UpDownInput.vue"
    import { triggerSegmentUpdate } from '../common'

    export default {
    name: "FinishVisitModal",
    store: store,
    components: {
        UpDownInput,
    },
    data() {
        return {
            reasons: [], 
            btn_udpate_txt: "Actualizar estado",
            btn_update_variant: "success",
            finalizes_visit: false,
            finalizes_order: false,
            tabIndex: 0,
            cancels_visit: false,
            tabsDisabled: true,
            repair_codes: null,
            materials: null,
            labors: null,
            swal_text: "",
            trxBusy: false,
            warning_materials_and_labors: false,
            sigStyle: { "border": "black 0.1px solid" },
            options: {
                penColor: "#000000",
                onBegin: () => {this.$refs.signaturePad.resizeCanvas()}
            },
            capabilities: null,
            form: {
                visit_id: null,
                extra_comments: null,
                material_comments: null,
                labor_comments: null,
                signature: null,
                status: -1,
                materials: null,
                labors: null,
                finish_order: false,
                set_materials: true,
                order_number: null,
                order_type: null,
                repair_code: null,
                derivation_type: null
            },
            formOnCreate: null,
            materialsOnCreate: null,
            laborsOnCreate: null,
            queryText: "",
            laborsQueryText: "",
            derivations: {"Redes": 1, "Contratista": 2},
            derivation_type: "Contratista",
            derives_service: false
        }
    },
    created() {
        this.form.visit_id = this.visit_id
        this.form.status = this.default_status
        this.form.extra_comments = this.extra_comments
        this.getReasons()
        this.getMaterials()
        this.getLabor()
        this.getRepairCodes()
        this.getSubsidiaryCapabilities()
        this.storeFormOnCreate()
    },
    methods: {

        getSubsidiaryCapabilities: function() {
            const data = {
                'visit_id': this.visit_id,
            };

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_subsidiary_capabilities') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.capabilities = response.data.data
                    if(this.capabilities.have_materials || this.capabilities.have_labor){
                        this.form.set_materials = false // No queremos dar la posiblidad de descargar materiales si la OT ya tiene materiales cargados ya que los pisaria
                        this.warning_materials_and_labors = "La órden de trabajo ya tiene mano de obra y/o materiales asignados. Si hace falta agregar algo más, hacerlo desde Boxicable"
                    } else {
                        if(!this.capabilities.allow_enter_materials_and_labors){
                            this.form.set_materials = false // No queremos dar la posibilidad de descargar materiales si Boxi nos indica que ha pasado mucho tiempo desde el cierre de la OT
                            this.warning_materials_and_labors = "No se pueden descargar materiales ya que han pasado demasiados dias desde el cierre de la órden de trabajo"
                        }
                    }
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },

        updateSignature: function() {

            //this.$refs.signaturePad.clearCacheImages()

            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if(!isEmpty) {
                this.form.signature = data;
            }
            

        },
        confirmDeleteSignature: function() {
            if(confirm("¿Estás seguro que querés borrar la firma?")){
                this.form.signature = null;
                this.$refs.signaturePad.clearSignature();
            } 
        },
        getStatusArray: function(status_id) {
            var result = this.reasons.map(category => {
                var status = category.options.find(status => status.value == status_id)
                if (status)
                {
                    return status;
                }
            })

            result = result.find(estado => estado != undefined)
            return result;
        },
        setEstadoTxt: function() {
            // var estado = this.reasons.find(status => status.value == this.form.status)
            // Esta funcion recorrida de esta manera deja basura, todas las categorías undefined menos la categoría donde se encontró el item.
            // Se resolvió haciendo otro find más abajo.
            
            this.finalizes_visit = false;
            this.cancels_visit = false;

            let estado = this.getStatusArray(this.form.status)
            
            if (estado) {
                if (estado.cancels == true)
                {
                    this.finalizes_visit = false;
                    this.cancels_visit = true;
                    this.swal_text = "La visita ha sido cancelada correctamente"
                    this.tabsDisabled = true

                }
                else if (estado.finalizes == true)
                {
                    this.finalizes_visit = true;
                    this.cancels_visit = false;
                    this.swal_text = "La visita ha sido cerrada correctamente"
                    this.tabsDisabled = false;
                    //TODO: Limpiar materiales
                }
                else {
                    this.finalizes_visit = false;
                    this.cancels_visit = false;
                    this.swal_text = "El estado ha sido modificado correctamente"
                    this.tabsDisabled = true;
                }
                this.finalizes_order = estado.finalizes_order
                if (estado.finalizes_order == false) {
                    this.form.finish_order = false;
                    this.form.repair_code = null;
                }
            }
        },
        getReasons: function() {
            const payload = {
                'type': this.show_every_reason ? 'all': 'or',
                'show_finish': true,
                'show_cancel': true
            }
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_visits_reasons_to_finish'), payload, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.reasons = response.data.data
                    this.setEstadoTxt()
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        getMaterials: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_materials'), null, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.materials = JSON.parse(JSON.stringify(response.data.data))
                    this.materialsOnCreate = JSON.parse(JSON.stringify(response.data.data)) // Aprovechamos y creamos otra copia para guardar el objeto original. De esta manera podemos resetear materials cuando cerramos el modal
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        getRepairCodes: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_order_repair_codes'), null, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.repair_codes = response.data.data
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        getLabor: function() {

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_labor'), null, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.labors = JSON.parse(JSON.stringify(response.data.data))
                    this.laborsOnCreate = JSON.parse(JSON.stringify(response.data.data)) // Aprovechamos y creamos otra copia para guardar el objeto original. De esta manera podemos resetear labors cuando cerramos el modal
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        closeFinishModal: function()
        {
            this.resetForm()
        },
        postFinishVisit: function()
        {
            if (this.form.status < 0) 
            {
                this.$swal({ 
                        icon: 'error',
                        title: 'Actualizar estado',
                        text: "Por favor seleccione un estado de la lista"});
                return;
            }

            if (this.derives_service && !Object.keys(this.derivations).includes(this.derivation_type)) 
            {
                this.$swal({ 
                        icon: 'error',
                        title: 'Derivar service',
                        text: "No se puede derivar service"});
                return;
            }

            if (this.form.finish_order == true && this.form.repair_code == null) 
            {
                this.$swal({ 
                        icon: 'error',
                        title: 'Actualizar estado',
                        text: "Por favor seleccione un tipo de reparación de la lista"});
                return;
            }

            if ((this.form.extra_comments == null || this.form.extra_comments.length < 5 ) && this.cancels_visit == true)
            {
                this.$swal({ 
                        icon: 'error',
                        title: 'Actualizar estado',
                        text: "Por favor escriba un motivo de cancelación"});
                return;
            }

            this.trxBusy = true

            this.form.materials = this.materials.filter(material => material.amount > 0)
            this.form.labors = this.labors.filter(labor => labor.amount > 0)

            if(this.derives_service && this.finalizes_visit){
                this.form.derivation_type = this.derivations[this.derivation_type]  //Poblamos el derivation_type del form con el ID correspondiente al derivation_type auxiliar
            }

            //this.$bvModal.hide('FinishModal_' + this.visit_id)
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            this.setEstadoTxt()

            // Si los tabs se encuentran deshabilitados, borramos la firma del formulario ya que no queremos enviarla
            if(this.tabsDisabled) {
                this.form.signature = null
            }

            axios.post(this.$store.getters.getApiURL('api_visit_finish'), this.form, config)
            .then(response => {
                if (response.data.error == false)
                {

                    this.$swal({ 
                        icon: 'success',
                        text: this.swal_text + ". " + (response.data.data.order_result ? `Orden de trabajo: ${response.data.data.order_response}`: ""), 
                        }).then(() => {
                            if (this.redirect == true)
                            {
                                triggerSegmentUpdate(this.form.visit_id)
                                this.$router.push({'name': store.state.defaultView})
                            } else {
                                // Atención que se emite como String y la lista contiene Int
                                this.$emit("kill-visit", parseInt(this.visit_id))
                                this.$bvModal.hide('FinishModal_' + this.visit_id)
                            }
                        });
                } else {
                    this.$swal({ 
                        icon: 'error',
                        title: 'Error',
                        text: response.data.diagnostic }).then(() => {
                            this.$router.push({'name': store.state.defaultView})});
                }
            })
            .catch(error => {

                let text = ""
                console.log(`Log: ${error.response.data.diagnostic}`);

                if (error.response) {
                    // Se realizó el request y el servidor respondió un error
                    text = "Error modificando la visita: " + error.response.data.diagnostic
                } else if (error.request) {
                    // Se realizó el request pero no hubo respuesta
                    text = "Verifique su conexión a Internet: " + error.message
                } else {
                    // Algo sucedió en el armado del request y se recibió un error
                    text = "Error al intentar modificar la visita: Por favor recargue la página e intente nuevamente"
                    console.log(error.message);
                }

                this.$swal({ 
                        icon: 'error',
                        title: 'Modificar visita',
                        text: text });
                this.axios_error = error
                this.trxBusy = false
            })
        },

        storeFormOnCreate: function() {
            this.formOnCreate = { ...this.form }
        },

        resetForm: function() {
            this.form = { ...this.formOnCreate }
        },

        resetMaterials: function() {
            this.materials = JSON.parse(JSON.stringify(this.materialsOnCreate))
        },

        resetLabors: function() {
            this.labors = JSON.parse(JSON.stringify(this.laborsOnCreate))
        },

        resetModalData: function() {
            this.resetForm()
            this.setEstadoTxt() // Actualiza el mensaje que se muestra en el modal de acuerdo con el status de la visita
            this.resetMaterials()
            this.resetLabors()
            this.derives_service = false
            this.derivation_type = "Contratista"
        },

        latinize: function(str) {
            // "Latiniza" una cadena de texto
            // Es decir, reemplaza los caracteres con tilde por caracteres sin tilde
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        },

        addOne(collection, code) {
            collection.find(obj => obj.code === code).amount++
        },

        substractOne(collection, code) {
            let currentAmount = collection.find(obj => obj.code === code).amount
            if(currentAmount > 0) {
                collection.find(obj => obj.code === code).amount--
            }
        }
    },
    computed: {
        filteredMaterials() {
            if (this.materials) {
                const queryText = this.latinize(this.queryText.toLowerCase())
                return this.materials.filter(material => this.latinize(material.name.toLowerCase()).includes(queryText))
            } else {
                return []
            }
        },
        filteredLabors() {
            if (this.labors) {
                const laborsQueryText = this.latinize(this.laborsQueryText.toLowerCase())
                return this.labors.filter(labor => this.latinize(labor.name.toLowerCase()).includes(laborsQueryText))
            } else {
                return []
            }
        },
    },
    watch:
    {
        default_status: function (newVal) {
            this.form.status = newVal
            this.setEstadoTxt()
        },
        extra_comments: function (newVal) {
            this.form.extra_comments = newVal
        },
        finalizes_visit: function(newVal) {
            if(newVal === false) {
                this.derives_service = false
                this.derivation_type = ""
            }
        },
        derives_service: function(newVal) {
            if(newVal === false) {
                this.derivation_type = ""
            }else{
                this.derivation_type = "Contratista"
            }
        }
    },
    props: { 'visit_id': Number,
            'show_every_reason': {
                type:Boolean,
                default: false },
            'redirect': {
                type:Boolean,
                default: true },
            'default_status': {
                type: Number,
                default: 6
            },
            'extra_comments': {
                type: String,
                default: null
            },
        },
    }
</script>

<style scoped>
.number-input {
  max-width: 90px;
}

@media (max-width: 600px) {
  .number-input {
    max-width: none;
  }
}

.item-row:hover {
  background-color: #f3f3f3;
}
</style>
