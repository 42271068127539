<template>
    <div id="zones-edit">
        <b-container v-if="!subsidiaries_list">
            <b-row>
                <b-col cols="12">
                    <p>Cargando listado...</p>
                </b-col>
            </b-row>
        </b-container>
        <b-container v-if="subsidiaries_list">
            <b-row>
                <b-col cols="12">
                    <p>Listado de zonas creadas (Todas las operaciones):</p>
                    <b-form-select 
                        v-model="zone_selected"
                        :options="zone_list"
                        :select-size="4"
                        value-field="id"
                        text-field="name"
                        @change="updateZoneData"
                        >
                        <template #first>
                            <b-form-select-option :value="null">- Crear nueva zona -</b-form-select-option>
                        </template>
                        </b-form-select>
                    <!-- TODO poner loader -->
                    <hr class="mt-2 mb-2">
                    <h1>{{ this.form.id == null? "Creando": "Editando" }} {{ this.form.name == null ? "nueva zona": this.form.name }}</h1>
                    <b-form-group
                        id="input-group-1"
                        label="Nombre de la zona:"
                        label-for="input-1"
                        description="Nombre corto y descriptivo para reconocer la zona"
                    >
                        <b-form-input
                        id="input-1"
                        v-model="form.name"
                        placeholder="Nombre"
                        required
                        @keypress="setChanged"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-3" label="Operación seleccionada:" label-for="input-3">
                        <b-form-select
                        id="input-3"
                        v-model="form.subsidiary_id"
                        :options="subsidiaries_list"
                        value-field="subsidiary_id"
                        text-field="name"
                        required
                        @change="changeSubsidiary"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="!nodes">
                <b-col cols="12">
                    <p>Cargando listado de nodos....</p>
                </b-col>
            </b-row>
            <b-row v-if="nodes">
                <b-col cols="12">
                    <p>Nodos seleccionados</p>
                    <!-- Si hay que agregar opciones vacías se puede poner taggable true. Ver doc -->
                    <Multiselect 
                        v-model="form.selected_nodes" 
                        deselect-label="Can't remove this value" 
                        track-by="idzona" 
                        label="nombre" 
                        placeholder="Seleccione al menos un nodo" 
                        :multiple="true"
                        :options="nodes" 
                        :searchable="true" 
                        :allow-empty="false"
                        :close-on-select="false"
                        :clear-on-select="false"
                        openDirection="top"
                        selectLabel="Presione ENTER para seleccionar"
                        deselectLabel="Presione ENTER para deseleccionar"
                        selectedLabel="Seleccionado"
                        @change="setChanged">
                        <!-- <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> is written in<strong>  {{ option.language }}</strong></template> -->
                        <!-- <template slot="option" slot-scope="props">
                            <div class="option__desc"><span class="badge__name">{{ props.option.nombre }}</span><span class="badge__used">{{ props.option.idzona }}</span></div>
                        </template> -->
                    </Multiselect>
                </b-col>
            </b-row>
            <b-button size="md" @click="toggleSave(form)" class="mt-3 mb-2" variant="primary">Guardar</b-button>
            <b-button size="md" v-if="form.id" @click="deleteZoneModal()" class="ml-2 mt-3 mb-2" variant="danger">Eliminar zona</b-button>
            <b-button size="md" v-if="form.id" @click="createNewZone()" class="ml-2 mt-3 mb-2" variant="success">Crear nueva zona</b-button>
            <b-button size="md" v-if="!form.id && changes" @click="clearForm()" class="ml-2 mt-3 mb-2" variant="danger">Limpiar campos</b-button>
        </b-container>
    </div>
</template>

<script>
    import store from "../store"
    import axios from 'axios'
    import Multiselect from 'vue-multiselect'
    import { getUserProfile } from "../common"

    export default {
    name: "ZonesEditView",
    components: { Multiselect },
    data: function() {
        return {
            nodes: null,
            subsidiaries_list: null,
            zone_selected: null,
            zone_list: null,
            changes: false,
            // Fallback si el usuario cancela el cambio de operación
            last_succeeded_subsidiary: null,
            last_succeeded_zone: null,
            form: {
                id: null,
                name: null,
                subsidiary_id: null,
                selected_nodes: null
            }
        }
    },
    created: async function() {
        {
            let tempPerms = await getUserProfile(store.state.username, store.state.token);
            this.subsidiaries_list = tempPerms.subsidiaries;
            if (this.subsidiaries_list.length > 0)
            {
                this.form.subsidiary_id = this.subsidiaries_list[0].subsidiary_id;
                this.getNodes();
                this.getZones();
            }
        }
    },
    methods: {
        setChanged: function() {
            this.changes = true;
        },
        getZones: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'operations': store.state.subsidiaries
            }

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_zones') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.zone_list = response.data.data
                    this.last_succeeded_subsidiary = this.form.subsidiary_id;
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        updateZoneData: function()
        {
            //Antes de llamar a la API hace estos chequeos
            if (this.changes == true)
            {
                // Muestro form para anular los cambios
                this.$swal({ 
                    icon: 'question',
                    title: 'Atención',
                    text: "¿Desea descartar los cambios?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Eliminar',
                    denyButtonText: 'Cancelar',
                    }).then((result) => {
                        if (result.isDenied)
                        {
                            this.zone_selected = this.last_succeeded_zone
                            return;
                        } 
                        else if (result.isConfirmed)
                        {
                            //Si seleccionó null, borro los campos
                            if (this.zone_selected == null) {
                                this.clearForm();
                            } else {
                                this.getZone();
                            }
                        }
                });
            } else {
                if (this.zone_selected == null) {
                    this.clearForm();
                } else {
                    this.getZone();
                }
            }
        },
        createNewZone: function() {
            if (this.changes == true)
            {
                // Muestro form para anular los cambios
                this.$swal({ 
                    icon: 'question',
                    title: 'Atención',
                    text: "¿Desea descartar los cambios?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Eliminar',
                    denyButtonText: 'Cancelar',
                    }).then((result) => {
                        if (result.isDenied)
                        {
                            return;
                        } 
                        else if (result.isConfirmed)
                        {
                            this.clearForm();
                        }
                });
            } else {
                this.clearForm();
            }
        },
        clearForm: function() {
            this.form.id = null;
            this.form.name = null;
            this.form.subsidiary_id = this.last_succeeded_subsidiary;
            this.form.selected_nodes = null;
            this.zone_selected = null;
            this.getNodes();
            this.changes = false;
            this.last_succeeded_zone = null;
        },
        getZone: function() {
            
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'id': this.zone_selected
            }

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_zone_get') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.last_succeeded_zone = this.zone_selected
                    this.form = response.data.data
                    this.changeSubsidiary(this.form.subsidiary_id, true)
                    this.changes = false
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        getNodes: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'operations': this.form.subsidiary_id,
                'editing_zone': this.form.id
            }

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_zones_nodes') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.nodes = response.data.data
                    this.last_succeeded_subsidiary = this.form.subsidiary_id;
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        toggleSave: function(data) {
            console.log(data)
            this.submitContent(data)
        },
        submitContent: function(payload) {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            const wyd = this.form.id == null ? 'creada' : 'editada';
            axios.post(this.$store.getters.getApiURL('api_zone_edit') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal('Zona ' + wyd + ' correctamente!', '', 'success');
                    this.getZones();
                    this.changes = false;
                    this.clearForm();
                    //Al crear seteo el ID que me devuelve la API para que luego me pregunte si quiero eliminarla
                    //console.log(response.data.data)
                    //payload.id = response.data.data
                }
                else {
                    this.$swal('No se pudo ' + wyd + ' la zona: ' + response.data.diagnostic, '', 'error')
                }
            })
            .catch(error => {
                this.$swal('Error al procesar información: ' + error, '', 'error')
            })
        },
        changeSubsidiary: function(event, override = false){

            if (this.form.id != null && override == false) {
                this.$swal('No se permite cambiar la operación una vez creada la zona', '', 'error');
                this.form.subsidiary_id = this.last_succeeded_subsidiary;
                return;
            }

            if (this.form.selected_nodes != null && override == false)
            {
                if (this.form.selected_nodes.length > 0)
                {
                    this.$swal({ 
                            icon: 'question',
                            title: 'Atención',
                            text: "Al cambiar operación, se eliminarán los nodos seleccioados. ¿Desea continuar?",
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Eliminar',
                            denyButtonText: 'Cancelar',
                            }).then((result) => {
                                if (result.isConfirmed== true)
                                {
                                    this.form.subsidiary_id = event;
                                    this.nodes = null;
                                    this.form.selected_nodes = null;
                                    this.getNodes();
                                }
                                else if (result.isDenied) {
                                    this.form.subsidiary_id = this.last_succeeded_subsidiary;
                                }
                            });
                }
            }
            else {
                this.nodes = null;
                this.form.subsidiary_id = event;
                this.getNodes();
            }
        },
        deleteZone: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";

            const payload = {
                'id': this.form.id
            }
            
            axios.post(this.$store.getters.getApiURL('api_zone_delete') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal('Zona eliminada correctamente', '', 'success');
                    this.clearForm();
                    this.getZones();
                }
                else {
                    this.$swal('No se pudo eliminar la zona: ' + response.data.diagnostic, '', 'error')
                }
            })
            .catch(error => {
                this.$swal('Error al procesar información: ' + error, '', 'error')
            })
        },
        deleteZoneModal: function()
        {
            if (this.form.id != null)
            {
                this.$swal({ 
                    icon: 'question',
                    title: 'Atención',
                    text: `¿Desea eliminar ${this.form.name}?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Eliminar',
                    denyButtonText: 'Cancelar',
                    }).then((result) => {
                        if (result.isConfirmed== true)
                        {
                            this.deleteZone()
                        }
                    });
            }
        } 
    }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
    #zones-edit {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }

    .badge__used {
        vertical-align: middle;
        float: right;

    }

    .badge__name {
        vertical-align: middle;
        display: inline-block;
        margin-left: 5px;
        float: left;
    }

</style>

