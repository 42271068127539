<template>
    <div id="visitcalendar">
        <div id="preloader" class="loader h-100">
            Cargando...
        </div>
        <b-container v-if="!calendar_error" class="calendarcontainer d-flex flex-column" fluid>
            <b-row>
                <b-col v-if="schedule" cols="12">
                    <div class="date-changer">
                        <b-button variant="primary" @click="prevDate">&lsaquo;</b-button>
                        <div class="weeks-text-container">
                            <p><b>Se muestra la semana del {{ schedule.date_from }} al {{ schedule.date_to }}</b> <a href="#" v-if="today" @click="setToday" variant="success"><b> | Ir a semana actual</b></a></p>
                        </div>
                        <div>
                            <b-button variant="primary" @click="nextDate">&rsaquo;</b-button>
                            <font-awesome-icon title="Ayuda" icon="info-circle" class="ml-4" @click="$bvModal.show('calendar-help')"/>
                        </div>
                    </div>
                    <CalendarHelpModal></CalendarHelpModal>
                </b-col>
            </b-row>
            <b-row v-if="schedule" class="calendar" cols="12">
                <b-col v-for="(day, key, index) in schedule.data" :key="key" :class="schedule.parameters.weekdays[index].current ? 'd-flex flex-column day current-day' : 'd-flex flex-column day'">
                        <b-row class="header-date" :class="schedule.parameters.weekdays[index].current ? 'day-highlighted' : ''" :style="header_style">
                            <b-col class="align-content-center text-center" cols="12">
                                    <p>{{ schedule.parameters.weekdays[index].name }}</p>
                            </b-col>
                        </b-row>
                        <b-row v-for="(stripe, key) in day" :key="key" class="franjahoraria">
                            <div class="header-franja">
                                <b-row>
                                    <div>
                                        <p>{{ key }}</p>
                                    </div>
                                    <div>
                                        <a v-if="stripe.total_visits > 0" :href="$router.resolve({ name: 'timestripe_details', params:{ date: schedule.parameters.weekdays[index].ISODate, timestripe: stripe.timestripe_id} }).href">
                                            <img class="ml-2" src="../assets/images/icns/zoom.png" width="20px" height="20px">
                                        </a>
                                    </div>
                                </b-row>
                            </div>
                            <b-col class="visitascontainer">
                                <div v-for="(visit, key) in stripe.visits" :key="key" class="visitas" :style="'background-color:#' + visit.hex_color + ';'">
                                    <p class="mb-0 ot-number"><a :href="$router.resolve({ name: 'get_visit', params: { visit_id: visit.visit_id, mode: mode }}).href"><strong>OT {{ visit.ot_number }}</strong><br/></a></p>
                                    <p class="mb-0">
                                    {{ visit.status_friendly}}
                                    <font-awesome-icon title="Sin asignar técnico" v-if="!visit.tech_id" :style="getTechIconColor(visit.detached)" icon="user-slash" class="ml-1" />
                                    <font-awesome-icon title="Reprogramada" v-if="visit.rescheduled_next" icon="calendar-check" class="ml-1" />
                                    <font-awesome-icon title="Iba en camino" v-if="visit.delayed_while_on_the_way && visit.status_friendly === 'Cierre demorado'" icon="exclamation-triangle" class="ml-1" />
                                    </p>
                                    <p v-if="visit.derivation_type" class="mb-0">
                                        <font-awesome-icon title="Derivada" icon="arrow-right" class="mr-2" />
                                        <strong>{{ visit.derivation_type_friendly }}</strong>
                                    </p>
                                </div>
                            </b-col>
                            <div class="visita-bottom">
                                <p>{{ stripe.open_visits }} {{ (stripe.open_visits == 1 ) ? "visita abierta" : "visitas abiertas" }}</p>
                            </div>
                        </b-row>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="h-100" v-if="calendar_error">
            <b-col>
                <b-row cols="12" class="text-center">
                    <p>Error al cargar el calendario. No hay operaciones asignadas para este usuario.</p>
                </b-row>
            </b-col>
        </b-container>
    </div>
</template>

<script>

    import store from "../store"
    import axios from "axios"
    import CalendarHelpModal from "./CalendarHelpModal.vue"
    //import breakpoints from "../BreakpointsUtil";

    export default {
    name: "VisitCalendar",
    store: store,
    components: { 
        CalendarHelpModal
    },
    data() {
        return {
            schedule: null,
            //subsidiary_name_selected: null,
            //subsidiary_id_selected: null,
            calendar_error: false,
            stripe_amount: 2,
            selected_date: null,
            header_style: '',
            zones: null,
            today: false
        }
    },
    props: {
        operations: Array,
        ranges: Array,
        visits: Object,
        subsidiary_id_selected: String,
        subsidiary_name_selected: String,
        zone_selected: Number,
        start_date: Date,
        mode: String
    },
    created: function() {
        if (this.start_date != null) {
            this.selected_date = this.start_date
        } else {
            this.selected_date = new Date();
        }
        if (this.operations.length > 0)
        {
            // this.subsidiary_name_selected = this.operations[0].name
            // this.subsidiary_id_selected = this.operations[0].subsidiary_id
            // this.$emit("set-subsidiary", this.subsidiary_id_selected)
            this.getVisits()
        } else {
            this.calendar_error = true
        }

        // Para manejar el tamaño del header
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        // Habilito botón "Hoy" si recibí una fecha distinta a la de hoy
        //this.setToday();
        this.compareToday();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        franjaheight() {
            const winHeight = window.innerHeight - 400;
            //var stripeAmount = (60/(this.stripe_amount + 1)) + 10 * ( 3 - this.stripe_amount );
            var stripeHeight = (winHeight / this.stripe_amount) - (this.stripe_amount * 15);
            return {
                "height": `${stripeHeight}px !important`,
            };
        }
    }, 
    methods: {
        getTechIconColor(event) {
            if (event == true)
            {
                return { color: '#C775A8A0' }
            } else {
                return {}
            }
            //color: #C775A8A0
        },
        compareToday: function(){
            // Función que computa si mostrar el botón de Hoy
            var date1 = new Date(this.selected_date.setHours(0,0,0,0));

            // A la fecha de inicio de la semana le resto un dia para contemplar el domingo y no mostrar el boton de "Ir a Hoy"
            // date1.setDate(date1.getDate() - 1);

            var date2 = new Date(this.selected_date.setHours(23,59,59,999));
            this.today = !(new Date() >= date1 && new Date() <= date2)

        },
        setToday: function()
        {


            var date = new Date();
            //var day = date.getDate();
            //const weekday = date.getDay();
            // Si hoy es domingo, diremos que hoy es lunes. 
            //if ( weekday === 0 ) {
            //    date.setDate(day + 1);
            //    date.setTime(date.getTime() - (3*60*60*1000))
            //}

            this.selected_date = date;
            this.$emit("set-date", this.selected_date)
            this.getVisits();
            this.compareToday();
        },
        prevDate: function()
        {
            this.selected_date.setDate(this.selected_date.getDate() - 7);
            this.getVisits()
            this.$emit("set-date", this.selected_date)
            this.compareToday();

        },
        nextDate: function()
        {
            this.selected_date.setDate(this.selected_date.getDate() + 7);
            this.getVisits()
            this.$emit("set-date", this.selected_date);
            this.compareToday();
        },
        filterResults()
        {
            // Pre-ejecución de cosas antes de hacer un POST
            this.getVisits()
            //console.log(result)
        },
        setPreloaderVisibility(status)
        {
            var display = 'block';
            if (status == true)
            {
                display = 'block';
            }
            else {
                display = 'none';
            }
            var loader = document.getElementById('preloader');
            if (loader != null)
            {
                loader.style.display = display; //or
            }
            //link.style.visibility = 'hidden';
        },
        getVisits: function()
        {

            const data = {
                'start_date': this.selected_date,
                'subsidiary': this.subsidiary_id_selected,
                'zone_id': this.zone_selected
            }
            const config = {
                'headers': { Authorization: `Bearer ${ store.state.token }` }
            };
            this.setPreloaderVisibility(true);
            let user_response = axios.post(store.getters.getApiURL('api_visits') , data, config)
            .then(response => {
                if (response.data.data.error == false)
                {
                    if (this.subsidiary_id_selected == data.subsidiary)
                    {
                        this.schedule = response.data.data
                        this.stripe_amount = response.data.data.stripes
                    }
                    this.setPreloaderVisibility(false);
                } else {
                    this.$swal({ 
                        icon: 'error',
                        title: 'Obtener visitas',
                        text: response.data.data.diagnostic,
                        //footer: `<a target="_self" href="${this.$router.resolve({ name: 'edit_zones' }).href}">Editar Zonas</a>`
                        //footer: `<a href="${this.$router.resolve({ name: 'edit_zones' }).href}">Editar Franjas horarias</a>`
                        });
                    this.schedule = null;
                    this.setPreloaderVisibility(false);
                }
            })
            .catch(error => {
                console.log(error)
            });
            return user_response.data;
        },
        pushToZoneConfig: function() {
            //closeModal(); return false;
            console.log("Test");
        },
        handleResize: function() {
            // Función para que el ancho de las cabecera con el día y fecha queden todos parejos.
            if (window.innerWidth < 1595 && window.innerWidth > 1200)
            {
                this.header_style = ""
            } else {
                this.header_style = ""
            }
        }
    },
    watch: {
        subsidiary_id_selected: function () {
            console.log("Cambia subsidiary")
            this.filterResults()
        },
        zone_selected: function () {
            console.log(`Cambia zone a ${this.zone_selected}`)
            this.filterResults()
        },
    },
    }
</script>

<style lang="scss" scoped>

    $franja-height: 200px;

    @import '../assets/custom-theme.scss';

    #visitcalendar {
        height: 100%;
        background-color: theme-color("primary-light");
    }

    .calendar {
        display: flex;
        justify-content: center;
        padding: 5px 10px;
        width: 100% !important;
        max-width: 100% !important;
    }

    .calendarcontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
    }

    .franjahoraria {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        height: 33vh !important;
        margin-top: 10px;
    }

    .day-highlighted {
        background-color: theme-color("primary") !important;
    }

    .loader {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        text-align: center;
        background-color: #00000040;
        color: #ffffff;
        font-size: 3em;
        vertical-align: middle;
        line-height: 85vh;
        -webkit-transition: background-color 500ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;
    }

    .header-date {
        font-weight: 600;
        background-color: theme-color("primary-light");
        text-align: center;
        position: relative;
        width: 100% !important;
        background-color: lighten(theme-color("primary"), 10%);
        margin-bottom: 5px;
        border-radius: 5px;
    }

    .header-date p {
        margin: 0px;
        padding: 10px 0;
        width: 100%;
    }

    .header-franja {
        background-color: darken(#eeeeee, 10%);
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        width: 100% !important;
    }

    .header-franja p {
        margin: 0px;
    }

    .ot-number a {
        color: rgb(0, 0, 0);
        color: theme-color("text-primary")
    }

    .cancelada {
        background-color: theme-color("danger")
    }

    .finalizada {
        background-color: theme-color("success")
    }

    .visita-bottom {
        background-color: rgba(theme-color("primary"), 0.5);
        margin: 0px;
        padding: 5px 0;
        z-index: 10;
        width: 100%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .visita-bottom p {
        margin: 0px auto;
        padding: 0px;
    }

    .visitascontainer {
        background-color: #ebebeb;
        border-radius: 0px;
        overflow-y: auto;
        scrollbar-color: theme-color("primary") theme-color("primary-light") ;
        height: 50vh !important;
        width: 100%;
        padding-top: 7.5px;
        border-left: 1px solid darken(#eeeeee, 10%);
        border-right: 1px solid darken(#eeeeee, 10%);
    }

    .visitas {
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 5px;
    }

    .day {
        margin: 2.5px;
        border: 1px solid #ebebeb;
        display: flex;
        align-items: center; 
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        min-width: 240px !important;
        max-width: 240px;
        background-color: white;
        border-radius: 5px;
    }

    .current-day {
        border: 2px solid theme-color("primary");
        //background-color: rgba(theme-color("primary"), 0.2);
    }

    .date-changer {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .weeks-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
    }

    .weeks-text-container > p {
        margin-bottom: 0 !important;
    }
</style>
