<template>
    <div id="priority-view">
        <b-container fluid>
            <b-row>
                <b-col cols="12" class="mb-4">
                    <h1>Editor de Prioridades</h1>
                    <p>Las modificaciones son inmediatas.</p>
                </b-col>
            </b-row>
            <div v-if="priorities">
                <b-table :ref="'table'" striped hover :fields="fields" :items="priorities" :small="true">
                    <template #cell(editbtn)="data">
                                    <b-button size="sm" @click="data.toggleDetails" class="mr-2">
                                        Editar Información
                                    </b-button>
                    </template>

                    <template #row-details="data">
                                <b-card>
                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>Prioridad:</b></b-col>
                                        <b-col><b-input type="number" v-model="data.item.priority" min="0" max="99"/></b-col>
                                    </b-row>

                                    <b-row class="mb-2">
                                        <b-col class="text-sm-right">
                                            <b-button size="sm" @click="toggleSave(data)" class="mr-2" variant="primary">Guardar y cerrar</b-button>
                                        </b-col>
                                    </b-row>
                                </b-card>
                    </template>

                </b-table>
            </div>
        </b-container>    
    </div>
</template>

<script>
    import store from "../store"
    import axios from "axios"

    export default {
    name: "PrioritiesView",
    data: function() {
        return {
            priorities: null,
            fields: [
                { key: 'id', label:'ID', tdClass: 'col-1' },
                { key: 'symptom', label:'Síntoma', tdClass: 'col-5' },
                { key: 'priority', label:'Prioridad', tdClass: 'col-1' },
                // Templates
                { key: 'editbtn', label: '', tdClass: 'col-3' }
            ]
        }
    },
    created: function() {
        const config = {
            'headers': { Authorization: `Bearer ${store.state.token}` }
        };

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_priorities') , null, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.priorities = response.data.data
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
    methods: {
        toggleSave: function(data) {
            console.log(data)
            this.submitContent(data.item)
            // Envio los datos de la prioridad. La API validará este dato....
            data.toggleDetails()
        },
        submitContent: function(payload) {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_priority_edit') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal('Prioridad editada correctamente!', '', 'success')
                    //Al crear seteo el ID que me devuelve la API para que luego me pregunte si quiero eliminarla
                    //console.log(response.data.data)
                    //payload.id = response.data.data
                }
                else {
                    this.$swal('No se pudo editar la prioridad: ' + response.data.diagnostic, '', 'error')
                }
            })
            .catch(error => {
                this.$swal('Error al procesar información: ' + error, '', 'error')
            })
        },
    }
    }
</script>

<style scoped>
    #priority-view {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }
</style>

