<template>
    <div id="footer">
        <div class="d-flex justify-content-md-center align-items-center vh-100">
            <h3>Descargando información</h3>
        </div>
    </div>
</template>

<script>
    export default {
    name: "Footer"
    }
</script>
