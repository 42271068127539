<template>
    <div class="container">
        <div id="imagenfondo"></div>
        <div class="d-flex justify-content-center align-items-center vh-100 h-100">
            <div class="shadow card">
                <div class="card-header">
                    <h3>{{ login_title }}</h3>
                </div>
                <div class="card-body">
                    <form>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><font-awesome-icon icon="user" /></span>
                            </div>
                            <input v-model="username" type="text" class="form-control" placeholder="Usuario">
                            
                        </div>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><font-awesome-icon icon="key" /></span>
                            </div>
                            <input v-model="password" type="password" class="form-control" placeholder="Contraseña" v-on:keyup="listenForEnterKey">
                        </div>
                        <div class="row align-items-center errormsg">
                            <div class="col-12">
                                <p>{{ errormsg }}</p>
                            </div>
                        </div>
                        <div class="row align-items-center remember">
                            <!-- <input type="checkbox">Recordarme -->
                        </div>
                        <div class="form-group">
                            <input type="button" :value=btnText class="btn float-right login_btn" @click="iniciarSesion()">
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <!-- <a href="#">¿Olvidó su contraseña?</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import store from '../store'

    export default {
    name: "Login",
    store: store,
    data() {
        return {
        username: '',
        login_title: '',
        password: '',
        errormsg: '',
        btnText: 'Ingresar',
        btnEnabled: true
    }},
    created: function() {
        this.login_title = process.env.VUE_APP_TITLE
    },
    methods: {
        enableLoginBtn: function() {
            this.btnEnabled = true
            this.btnText = "Ingresar"
        },
        iniciarSesion: function() {
            this.btnText = "Espere..."
            const udata = {
                'username': this.username,
                'password': this.password
            }
            this.errormsg ="";
            
            if (this.btnEnabled == true)
            {
                this.btnEnabled = false
                axios.post(this.$store.getters.getApiURL('api_login_url') , udata)
                .then(response => {
                    if (response.data.code == 200)
                    {
                        if (response.data.data.error == false && response.data.data.token != null)
                        {
                            this.$store.commit('setToken', response.data.data)
                            // Redirijo al lugar correcto (Según perfil)
                            // Dejo esto de fallback vvvv
                            //this.$router.push(response.data.data.defaultView)
                            this.$router.push({ 'name' : response.data.data.defaultView })  
                        }
                        else {
                            this.errormsg = response.data.data.diagnostic
                            this.enableLoginBtn()
                        }
                    }
                })
                .catch(error => {
                    if(error.response.status === 429){
                        this.errormsg = "Demasiados intentos, por favor pruebe dentro de unos minutos"
                    }else{
                        this.errormsg = error
                    }
                    this.enableLoginBtn()
                });
            }
        },
        listenForEnterKey: function(e) {
          if (e.keyCode === 13) {
            this.iniciarSesion()
          }
        }
    }
    }
</script>


<style lang="scss" scoped>

    @import '../assets/custom-theme.scss';

    #imagenfondo {
      // background: url("../assets/images/gigawaves.svg");
      // filter: brightness(50%);
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -9999;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    html, body {
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
    }

    .container {
        height: 100%;
        align-content: center;
    }

    .card {
        height: 370px;
        margin-top: auto;
        margin-bottom: auto;
        width: 400px;
    }

    .card-header h3 {
        margin-top: 15px;
        color: theme-color("text-primary");
    }

    .social_icon {
        position: absolute;
        right: 20px;
        top: -45px;
    }

    .input-group-prepend span {
        width: 40px;
        background-color: theme-color("primary");
        color: white;
        border: 0 !important;
    }

    input:focus {
        outline: 0 0 0 0  !important;
        box-shadow: 0 0 0 0 !important;
    }

    .remember {
        input {
            width: 20px;
            height: 20px;
            margin-left: 15px;
            margin-right: 5px;
        }
    }

    .login_btn {
        color: black;
        background-color: theme-color("primary");
        width: 100px;
        color: white;

        &:hover {
            background-color: theme-color("primary-light");
        }
    }

    .links {
        color: white;

        a {
            margin-left: 4px;
        }
    }

    .errormsg {
        color: theme-color("danger");
        height: 1.75rem;
    }
</style>
