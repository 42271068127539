<template>
    <div id="visit-stripe-detail-view">
        <b-container class="mt-2" fluid>
            <b-row class="ml-1 mr-1" v-if="visits" cols="12">
                <h4>Detalle de visitas de la franja {{ this.visits.timestripe_name }} para la fecha {{ this.visits.formatted_date }}</h4>
                <RescheduleVisitModal
                    v-if="form.visit_id.length > 0"
                    :visit_id="form.visit_id"
                    modal_ref="multi"
                    behavior="emit"
                    @success="getTimestripe"
                >
                </RescheduleVisitModal>
                <SetNewTechModal
                    v-if="form.visit_id.length > 0"
                    :visit_id="form.visit_id"
                    :date_="this.date"
                    :timestrip="this.timestripe_id"
                    :operations="visits.operations"
                    modal_ref="multi"
                    behavior="emit"
                    @success="getTimestripe"
                >
                </SetNewTechModal>
                <b-table  striped hover :fields="visit_fields" :items="this.visits.visits">

                    <template #cell(check)="data">
                        <b-form-checkbox v-if="data.item.opened == true && (data.item.status_friendly !== 'En camino' && data.item.status_friendly !== 'Cierre demorado')" :value="data.item.visit_id" v-model="form.visit_id"></b-form-checkbox>
                    </template>

                    <template #cell(status_nice)="data">
                        <p>
                            <span><font-awesome-icon icon="circle" class="mr-2" :style="{ 'color': '#' + data.item.status_color }" />{{ data.item.status_friendly }}</span>
                            <font-awesome-icon title="Reprogramada" v-if="data.item.rescheduled" icon="calendar-check" class="ml-2" />
                        </p>
                    </template>
                    
                    <template #cell(derivation_type)="data">
                        <p>
                            <span>{{ data.item.derivation_type_friendly }}</span>
                        </p>
                    </template>

                    <template #cell(lupita)="data">
                        <a target="_blank" :href="$router.resolve({ name: 'get_visit', params:{ visit_id: data.item.visit_id, mode: 'jefetec' } }).href">
                            <img src="../assets/images/icns/zoom.png" width="20px" height="20px">
                        </a>
                    </template>

                </b-table>
                        <b-button class="ml-2 mr-2 mb-2" @click="showRescheduleModal" :disabled="form.visit_id.length == 0" variant="danger">Reprogramar visita{{ form.visit_id.length == 1 ? '': 's' }}</b-button>
                        <b-button class="mb-2" @click="showSetNewTechModal" :disabled="form.visit_id.length == 0" variant="success">Cambiar técnico</b-button>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import axios from 'axios';
    import store from '../store';
    import RescheduleVisitModal from "../components/RescheduleVisitModal"
    import SetNewTechModal from "../components/SetNewTechModal"

    export default {
    name: "VisitStripeDetailView",
    store: store,
    components: {
        RescheduleVisitModal,
        SetNewTechModal
    },
    data: function() {
        return {
            form: {
                visit_id: [],
            },
            timestripe_id: this.$route.params.timestripe,
            date: this.$route.params.date,
            visits: null,
            visit_fields: [
                { key: 'check', label:'', tdClass: 'col-1' },
                { key: 'ot_number', label:'Nº OT', tdClass: 'col-1' },
                //{ key: 'ot_type_friendly', label:'Tipo OT', tdClass: 'col-1' },
                { key: 'client_id', label:'Nº Cliente', tdClass: 'col-1' },
                { key: 'client_address', label:'Dirección', tdClass: 'col-1', sortable: true },
                { key: 'node_name', label:'Nodo', tdClass: 'col-1', sortable: true },
                { key: 'zone_name', label:'Zona', tdClass: 'col-1', sortable: true },
                { key: 'symptom_name', label:'Síntoma', tdClass: 'col-1', sortable: true },
                { key: 'assigned_name', label: 'Técnico', tdClass: 'col-2', sortable: true },
                { key: 'status_nice', label: 'Estado', tdClass: 'col-2' },
                { key: 'derivation_type', label: 'Derivacion', tdClass: 'col-2', sortable: true },
                { key: 'lupita', label: 'Detalle', tdClass: 'col-1' },
                // { key: 'symptom_friendly', label: 'Síntoma', tdClass: 'col-3' }
            ]
        }
    },
    created: function() {
        this.getTimestripe()
    },
    methods: {
        getTimestripe: function() {

            this.form.visit_id = []

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'timestripe_id': this.timestripe_id,
                'date': this.date,
            }

            axios.post(this.$store.getters.getApiURL('api_visit_timestripe_detail') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.visits = response.data.data
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        showRescheduleModal: function() {
            this.$bvModal.show("RescheduleModal_multi")
        },
        showSetNewTechModal: function() {
            this.$bvModal.show("SetNewTechModal_multi")
        }
    }
    }
</script>

<style scoped>

</style>
