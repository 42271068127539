<template>
    <div id="setnewtech">
        <b-modal :id="'SetNewTechModal_' + modal_ref" size="xl" title="Cambio de técnico" scrollable>
            <h1>Cambio de técnico</h1>
            <b-alert variant="warning" show v-if="visit_id.length > 0">Se modificará el técnico asignado para {{ visit_id.length }} visita{{ visit_id.length == 1 ? '' : 's'}}</b-alert>
            <b-form-group id="input-group-3" label="Técnico:" label-for="input-3">
                <b-form-select
                id="input-3"
                name="input-3"
                v-model="form.tech_id"
                :options="technicians_list"
                >
                <template #first>
                    <b-form-select-option :value="null">&nbsp;</b-form-select-option>
                </template>
                </b-form-select>

            </b-form-group>
            <template #modal-footer="{ close }">
            <!-- Emulate built in modal header close button action -->
                <b-button size="sm" variant="warning" @click="nextTickSend()">
                    <b-spinner small v-if="settingNewTech" class="mr-1"></b-spinner>
                    Cambiar técnico
                </b-button>
                <b-button size="sm" variant="primary" @click="close()">
                    Cerrar
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

    import axios from 'axios'
    import store from '../store'

    export default {
    name: "SetNewTechModal",
    //api_visit_reschedule
    props: { 
        'modal_ref': {}, 
        'visit_id': {},
        'date_': {},
        'timestrip': {}, 
        'operations': {},
        'technician_id': {},
        'behavior': {
            type: String,
            default: 'goback'
        }
    },
    store: store,
    data: function() {
        return {
            settingNewTech: false,
            technicians_list: null,
            form: {
                visit_id: null,
                tech_id: null,
                tech_name: null
            }
        }
    },
    created: function() {
        this.getTechList()
        this.form.visit_id =  this.visit_id
    },
    methods: {
        getTechList: function() {
            const data = {
                'operations': this.operations,
                'date_': this.date_,
                'timestrip': this.timestrip
            };

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };


            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_get_technicians_list') , data, config)
            .then(response => {
                if (response.data.code == 200)
                {

                    // Al nombre del tecnico le agregamos la cantidad de visitas asignadas
                    let tech = []
                    response.data.data.forEach(function(t) {
                        let txt = ""
                        if(t.assigned_visits > 0) {
                            if (t.assigned_visits == 1){
                                txt =  "(" + t.assigned_visits + " visita asignada)"
                            } else {
                                txt = "(" + t.assigned_visits + " visitas asignadas)"
                            }
                        }
                        tech.push({"text": t.text + " " + txt , "value": t.value, "name": t.text })

                    })

                    this.technicians_list = tech


                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(error => {
                this.axios_error = error
            });
        },
        updateTech: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.settingNewTech = true;

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_edit') , this.form, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.showOverlay = false;
                    this.$swal({ 
                        icon: 'success',
                        title: 'Editar visita',
                        html: "Se editó correctamente"}).then(() => {
                            if (this.behavior == "goback")
                            {
                                this.$router.push({'name': store.state.defaultView})
                            } else if (this.behavior == "emit")
                            {
                                this.$emit("success", true)
                            }
                            this.$bvModal.hide('SetNewTechModal_' + this.modal_ref)
                    });
                }
                else {
                    this.$swal({ 
                        icon: 'error',
                        title: 'Editar visita',
                        text: response.data.diagnostic }).then(() => {
                            this.$router.push({'name': store.state.defaultView})
                    });
                    this.showOverlay = false;
                }
            })
            .catch(error => {
                this.axios_error = error
            })
            .finally(() => this.settingNewTech = false);
        },
        nextTickSend: function() {
            let self = this;

            //Cargo el nombre del técnico
            if (this.form.tech_id != null)
            {
                const val = this.technicians_list.filter(tech => tech.value == this.form.tech_id)
                if (val === undefined)
                {
                    this.$swal('No se pudo obtener el nombre del técnico. Por favor realice otra selección', '', 'error');
                    return;
                } else {
                    this.form.tech_name = val[0].name;
                }
            }

            this.$nextTick()
            
            .then(function () {
                self.updateTech();
            });
        },
    },
    watch: {
        visit_id: function(newValue) {
            this.form.visit_id = newValue
        }
    }
    }

</script>

<style scoped>
</style>
