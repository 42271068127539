<template>
    <div id="footer">
        <p></p>
    </div>
</template>

<script>
    export default {
    name: "Footer"
    }
</script>

<style scoped>
    #footer {
        height: 0px;
    }
</style>

