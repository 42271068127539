<template>
    <div id="report">
        <b-container class="mr-1 ml-1" fluid>
            <b-card title="Reporte de uso de 'Voy en camino'" sub-title='Reporte que indica la frecuencia de uso del botón "Voy en camino". Sólo se muestran técnicos con visitas finalizadas.'>
                <b-row cols="12">
                    <b-col v-if="subsidiaries_list" lg="12">
                        <b-row class="mt-3 mb-2">
                            <b-col xl="6" lg="6" sm="12">
                                <b-row>
                                    <b-col xl="3" lg="3" sm="3">
                                        <h4 class="component-label">Período:</h4>
                                    </b-col>
                                    <b-col xl="5" lg="5" sm="5">
                                        <p class="month-picker-container">
                                            <MonthPickerInput lang="es" v-model="monthpicker" @change="updateMonth"></MonthPickerInput>
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-row>
                                    <b-col xl="3" lg="3" sm="3">
                                        <h4 class="component-label">Operación:</h4>
                                    </b-col>
                                    <b-col xl="5" lg="5" sm="5">
                                        <b-form-select
                                        id="input-3"
                                        v-model="form.subsidiary"
                                        :options="subsidiaries_list"
                                        value-field="subsidiary_id"
                                        text-field="name"
                                        required
                                        size="lg"
                                        ></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-btn variant="primary" @click="updateReport" class="ml-3">Actualizar</b-btn>
                </b-row>
            </b-card>
            <b-row v-if="loading" cols="12">
                <b-col class="mt-2 mb-2" lg="12">
                    <b-overlay :show="loading" rounded="sm">
                        <b-skeleton-img width="100%"></b-skeleton-img>
                    </b-overlay>
                </b-col>
            </b-row>
            <b-row v-if="report" cols="12">
                <b-col lg="12">
                    <b-table :items="report" :fields="fields">
                        <template #cell(percentage)="data">
                            {{ data.item.percentage === "None" ? "" : `${data.item.percentage}%` }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import axios from 'axios';
    import store from '../../store';

    import { getUserProfile } from "../../common"
    import { MonthPickerInput } from 'vue-month-picker'

    export default {
    name: "OnTheWayReport",
    store: store,
    components: {
        MonthPickerInput
    },
    data: function() { 
        return {
            loading: false,
            subsidiaries_list: null,
            report: null,
            monthpicker: null,
            form: {
                subsidiary: null,
                month: null,
            },
            fields: [
                { key: 'tech_name', label:'Técnico', tdClass: 'col-1', sortable: true },
                { key: 'amount_of_visits_finished', label:'Visitas finalizadas', tdClass: 'col-1', sortable: true },
                { key: 'amount_of_visits_that_were_set_on_the_way', label:'Visitas finalizadas que estuvieron en camino', tdClass: 'col-2', sortable: true },
                { key: 'percentage', label:'Porcentaje en camino', tdClass: 'col-1', sortable: true },
                { key: 'amount_rescheduled_by_client', label:'Reprogramadas por el cliente', tdClass: 'col-1', sortable: true },
                { key: 'amount_cancelled_by_client', label:'Canceladas por el cliente', tdClass: 'col-1', sortable: true },
            ],
        }
    },
    created: async function() {
        {
            let tempPerms = await getUserProfile(store.state.username, store.state.token);
            this.subsidiaries_list = tempPerms.subsidiaries;
            if (this.subsidiaries_list.length > 0)
            {
                this.form.subsidiary = this.subsidiaries_list[0].subsidiary_id;
            }
        }
    },
    methods: {
        updateMonth: function(event) {
            this.form.month = event.from
        },
        updateReport: function() {

            this.loading = true;
            this.report = null;

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            axios.post(this.$store.getters.getApiURL('api_report_on_the_way') , this.form, config)
            .then(response => {
                this.report = response.data.data.techs_data.filter(tech => tech.amount_of_visits_finished > 0);
            })
            .catch(error => {
                console.log(error);
                this.$swal({ 
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo generar el reporte'
                });
            })
            .finally(() => {
                this.loading = false;
            });
        },
    }
    }
</script>

<style>
    #report {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }

    .month-picker-input {
        font-size: 1.25rem !important;
        padding: 9px !important;
        padding-left: 20px !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        color: #495057;
    }

    .month-picker-input-container input {
        width: 100% !important;
    }

    .month-picker-input-container {
        width: 100% !important;
    }

    .month-picker-container {
        z-index: 999 !important;
        position: relative;
    }

    .month-picker__year > button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 6px;
    }

    .report-graphic {
        max-width: 100%;
    }

    .component-label {
        margin-top: 10px;
    }

    .visit-link-unclosed-report {
        color: #2892bc;
    }
    
</style>
