<template>
        <div id="indexJefeTec" v-if="userData">
        <b-container fluid id="jefeTecContainer">
            <b-row ref="subsidiaryButtonBar" class="subsidiary-button-bar hidden-print">
                <b-col cols="12" md="9">
                    <p>Operaciones:
                        <b-button 
                            v-for="operation in operations" 
                            :key="operation.name" 
                            @click="setQuery(operation)" 
                            :variant="subsidiary_name_selected == operation.name ? 'success': 'primary'" 
                            class="mt-2 mb-2 ml-2">
                            {{ operation.name }}
                        </b-button>
                    </p>
                </b-col>
                <b-col cols="12" md="3" class="zone-selector">
                    <p>Zona:</p>

                    <b-form-select
                    id="input-38"
                    name="input-38"
                    class="mb-2 mt-2"
                    v-model="zone_selected"
                    :options="zones"
                    value-field="id"
                    text-field="name"
                    @change="setZone"
                    >
                        <template #first>
                            <b-form-select-option :value="null">Todas</b-form-select-option>
                        </template>
                    </b-form-select>

                    <!-- <b-form-checkbox 
                        v-model="remember_zone"
                        name="check-button"
                        @change="setRememberZone"
                        switch>Recordar zona
                    </b-form-checkbox> -->
                </b-col>
            </b-row>
            <b-row ref="jefeView" id="jefeTecView" v-if="userData" class="hidden-print full-width">
                <b-col class="schedule d-print-block print-calendar" xl="12" md="12" lg="12" sm="12">
                    <VisitCalendar
                        mode="calendario"
                        v-if="userData && start_date" 
                        :operations="userData.subsidiaries"
                        :subsidiary_id_selected="subsidiary_selected"
                        :zone_selected="zone_selected"
                        :subsidiary_name_selected="subsidiary_name_selected"
                        :start_date="start_date"
                        @set-date="setDate"
                    ></VisitCalendar>
                </b-col>
            </b-row>
            <div v-if="!userData" style="width: 100%" class="d-flex justify-content-md-center align-items-center h-100">
                <h3>Descargando información</h3>  
            </div>
            <OrderInfoModal v-if="search" prefix="search" :ot_type="search.ot_type" :ot_number="search.ot_number" mode="editor"></OrderInfoModal>
            <ClientInfoModal v-if="search" prefix="searchClient" :client_number="search.client_number" mode="editor"></ClientInfoModal>
        </b-container>
    </div>
</template>

<script>
    // import { component } from 'vue/types/umd'
    import store from '../store'
    // import TechOrderPriorityList from '../components/TechOrderPriorityList.vue'
    import VisitCalendar from '../components/VisitCalendar.vue'
    import OrderInfoModal from '../components/OrderInfoModal.vue'
    import ClientInfoModal from '../components/ClientInfoModal.vue'
    import { getUserProfile, SessionHandler } from "../common"
    import bus from "../bus"
    import breakpoints from "../BreakpointsUtil.js";
    import axios from 'axios';

    var session = new SessionHandler("IndexSAC");

    export default {
    name: "IndexSAC",
    store: store,
    components: {
        VisitCalendar,
        OrderInfoModal,
        ClientInfoModal
    },
    data() {
      return {
        userData: null,
        errorMsg: 'asdf',
        showTechOrders: true,
        showCalendar: true,
        subsidiary_selected: null,
        subsidiary_name_selected: null,
        zone_selected: null,
        operations: null,
        zones: null,
        remember_zone: false,
        start_date: null,
        search: null
      }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        handleResize() {
            //console.log(breakpoints.screen)
            this.showCalendar = breakpoints.screen.xl || breakpoints.screen.lg || breakpoints.screen.md
            if (breakpoints.screen.xl || breakpoints.screen.lg || breakpoints.screen.md)
            {
                this.showTechOrders = true;
            }
        },
        setSubsidiary: function(event) {
            this.subsidiary_selected = event
            console.log(event)
        },
        setRememberZone: function() {
            session.setKey("remember_zone", this.remember_zone);
        },
        setDate: function(event) {
            session.setKey("date_selected", event);
            this.start_date = event;
        },
        setQuery: function(result) {

            // NextTick acá

            this.$nextTick().then(() => { 
                this.subsidiary_name_selected = result.name
                this.subsidiary_selected = result.subsidiary_id
                this.zone_selected = null
                this.getZones()

                // Navegación 
                session.setKey("subsidiary_name_selected", result.name);
                session.setKey("subsidiary_selected", result.subsidiary_id);
                session.setKey("zone_selected", null);
            })

            
        },
        setZone: function() {
            session.setKey("zone_selected", this.zone_selected);
        },
        getZones: function() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const payload = {
                'operations': this.subsidiary_selected
            }

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_zones') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.zones = response.data.data
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        },
        makeid: function(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },
    },
    created: async function() {
        store.commit('initialize')
        this.userData = await getUserProfile(this.$store.state.username, this.$store.state.token)
        //console.log(this.userData)
        this.operations = this.userData.subsidiaries
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        // Recargo sesión
        this.subsidiary_name_selected = session.getKey("subsidiary_name_selected", this.operations[0].name);
        this.subsidiary_selected = session.getKey("subsidiary_selected", this.operations[0].subsidiary_id);
        this.remember_zone = session.getKey("remember_zone", false);

        this.start_date = new Date(session.getKey("date_selected", new Date()));

        if (this.remember_zone)
            {
                // Restauro zona sólo si seleccionó el checkbox
                this.zone_selected = session.getKey("zone_selected", null);
            }
        
        this.getZones()
    },
    mounted() {
        bus.$on('setOrderListVisibility', () => {
            this.showTechOrders = true
            this.showCalendar = false
        })
        bus.$on('setCalendarVisibility', () => {
            this.showCalendar = true
            this.showTechOrders = false
        })
        bus.$on('pushStripeEditor', () => {
            this.$router.push({'name': 'edit_subsidiaries', params: {'subsidiary': this.userData}})
        })
        bus.$on('searchOT', (ot_total) => {
            // Buscador de OTs
            var self = this;
            this.search = null;
            const subsidiaries = store.state.subsidiaries.split(",");

            if (ot_total.ot_number.length != 11 || ot_total.ot_type == null || isNaN(ot_total.ot_type)) {
                this.$swal({ 
                    icon: 'error',
                    title: 'Buscar OT',
                    text: "Por favor verifique los datos ingresados"
                });
            } else if (subsidiaries.indexOf(ot_total.ot_number.substring(0,3)) < 0) {
                this.$swal({ 
                    icon: 'error',
                    title: 'Buscar OT',
                    text: "No se pueden buscar OTs de operaciones no asignadas"
                });
            } else {
                this.search = ot_total;
                this.$nextTick().then(function () {
                    self.$bvModal.show("search" + ot_total.ot_type + ot_total.ot_number)
                });
            }
        })
        bus.$on('searchClient', (client_total) => {
            var self = this;
            this.search = null;
            const subsidiaries = store.state.subsidiaries.split(",");

            // Chequeamos que el input tenga el largo correcto y sea de tipo numerico
            if (client_total.client_number.length != 11 || isNaN(client_total.client_number)) {
                this.$swal({ 
                    icon: 'error',
                    title: 'Buscar Cliente',
                    text: "Por favor verifique los datos ingresados"
                });
            } else if (subsidiaries.indexOf(client_total.client_number.substring(0,3)) < 0) {
                this.$swal({ 
                    icon: 'error',
                    title: 'Buscar Cliente',
                    text: "No se pueden buscar clientes de operaciones no asignadas"
                });
            } else {
                this.search = client_total;
                this.$nextTick().then(function() {
                    self.$bvModal.show("searchClient" + client_total.client_number)
                });
            }
        })
    },
    computed: {
        getParentSize () {
            return { 'height': `${this.initialSize}px !important` };
        },
    },
    destroyed()
    {
        bus.$off('setOrderListVisibility')
        bus.$off('setCalendarVisibility')
        bus.$off('pushStripeEditor')
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style lang="scss" scoped>

    @import '../assets/custom-theme.scss';

    #indexJefeTec {
        display: flex;
        flex-grow: 1;
    }

    #jefeTecContainer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    #jefeTecView {
        display: flex;
        flex-grow: 1;
        height: 150px;
        overflow-y: auto;
    }

    .full-width {
        display: flex;
        flex-grow: 1;
    }

    #techorderscroll {
        overflow-y: scroll;
        padding-right: 5px;
        height: 100%;
        scrollbar-color: theme-color("primary") theme-color("primary-light") ;
    }

    .move-scrollbar {
        padding-right: 5px !important;
    }

    .otlist
    {
        background-color: theme-color("primary-light");
    }

    .zone-selector {
        display: flex;
        flex-direction: row;
    }

    .zone-selector p {
        padding-top: 15px;
        padding-right: 7px;
    }

    .zone-selector .custom-control {
        color: theme-color("primary-light") !important;
        padding-left: 37px;
        padding-top: 15px;
    }

    .subsidiary-button-bar {
        min-height: 65px;
        background-color: theme-color("primary");
        display: flex;
    }
    
    .subsidiary-button-bar p {
        color: theme-color("primary-light");
    }

    @media print {
        #techorderscroll {
            display: none;
            width: 0px;
            height: 0px;
        }

        .print-calendar {
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        a {
            text-decoration: none !important;
            color: #000000 !important;
        }
    }

</style>

<style scoped>
    #footer {
        height: 40px;
    }
</style>
