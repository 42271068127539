<template>
    <div id="setup">
        <div v-if="userPerms && canReadReports()" class="reports-container">
            <div v-for="report in reports" :key="report.id" class="wrapper" @click="$router.push({ name: `${report.route}` })">
                <div class="item-container">
                    <div class="item-link">
                        <img class="img-icon" :src="require(`../assets/images/icns/flat/${report.img_type}.png`)">
                        <h2 class="title">{{ report.name }}</h2>
                        <p class="subtitle">{{ report.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="userPerms && !canReadReports()" class="reports-container">
            <h2>No poseés permisos para ver reportes.</h2>
        </div>
    </div>
</template>

<script>

    import { getUserProfile } from "../common"
    import store from "../store"

    export default {
    name: "ReportsView",
    data: function() {
        return {
            userPerms: null,
            reports: [
                {
                    id: 1,
                    route: "reports_visits_month",
                    name: "Visitas por día",
                    description: "Reporte de visitas por día por operación.",
                    img_type: "graph_icon",
                },
                {
                    id: 2,
                    route: "reports_late_schedule",
                    name: "Visitas fuera de franja horaria",
                    description: "Reporte mensual de visitas agendadas en el día, para turnos ya finalizados.",
                    img_type: "graph_icon",
                },
                {
                    id: 3,
                    route: "reports_late_finish",
                    name: "Visitas finalizadas fuera de turno",
                    description: "Reporte mensual de visitas finalizadas fuera de horario.",
                    img_type: "graph_icon",
                },
                {
                    id: 4,
                    route: "reports_cancellation_reason",
                    name: "Motivos de cancelación",
                    description: "Reporte mensual de motivos de cancelación de visitas.",
                    img_type: "graph_icon",
                },
                {
                    id: 5,
                    route: "reports_tech",
                    name: "Visitas por técnico",
                    description: "Reporte mensual de técnicos por operación.",
                    img_type: "table_icon",
                },
                {
                    id: 6,
                    route: "reports_unclosed",
                    name: "Visitas sin cerrar",
                    description: "Reporte histórico de visitas que no fueron cerradas.",
                    img_type: "table_icon",
                },
                {
                    id: 7,
                    route: "reports_reschedule",
                    name: "Visitas reagendadas",
                    description: "Visitas reagendadas por mes por operación.",
                    img_type: "table_icon",
                },
                {
                    id: 8,
                    route: "reports_derivations",
                    name: "Visitas derivadas",
                    description: "Reporte mensual de visitas derivadas.",
                    img_type: "table_icon",
                },
                {
                    id: 9,
                    route: "reports_visit_segments",
                    name: "Segmentos de visitas",
                    description: "Promedio mensual de los segmentos.",
                    img_type: "table_icon",
                },
                {
                    id: 10,
                    route: "reports_on_the_way",
                    name: '"Voy en camino" por técnico',
                    description: 'Frecuencia de uso del botón "Voy en camino" por técnico.',
                    img_type: "table_icon",
                },
                {
                    id: 11,
                    route: "reports_on_the_way_global",
                    name: '"Voy en camino" por operación',
                    description: 'Frecuencia de uso del botón "Voy en camino" por operación.',
                    img_type: "table_icon",
                },
                {
                    id: 12,
                    route: "reports_my_techs",
                    name: "Mis técnicos",
                    description: "Listado de técnicos.",
                    img_type: "table_icon",
                },
            ],
        }
    },
    created: async function() {
        {
            let tempPerms = await getUserProfile(store.state.username, store.state.token);
            this.userPerms = tempPerms.permissions;
        }
    },
    methods: {
        hasPerm(permission) {
            return this.userPerms.includes(permission);
        },
        canReadReports() {
            return this.hasPerm("can_read_reports");
        }
    }
    }
</script>

<style scoped>
    #setup {
        height: 100%;
        width: 100vw;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .reports-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .wrapper {
        flex: 0 1 400px;
        padding: 40px 25px;
        max-width: 400px;
        margin: 0.75rem;
        border-radius: 10px;
        border: 1px solid #dfdfdf;
        background-color: #fafafa;
        min-height: 175px;
    }

    .item-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
    }

    .wrapper:hover {
        border: 1px solid #68c0e3;
        cursor: pointer;
    }

    .subtitle {
        color: #605a71;
        margin: 0px;
    }

    .title {
        margin: 0px;
        margin-bottom: 3px;
        font-size: 1.5rem;
    }

    .img-icon {
        width: 60px;
        margin-bottom: 1rem;
    }

</style>

