import { render, staticRenderFns } from "./TtsInfoModal.vue?vue&type=template&id=089efe18&scoped=true&"
import script from "./TtsInfoModal.vue?vue&type=script&lang=js&"
export * from "./TtsInfoModal.vue?vue&type=script&lang=js&"
import style0 from "./TtsInfoModal.vue?vue&type=style&index=0&id=089efe18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089efe18",
  null
  
)

export default component.exports