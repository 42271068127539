<template>
    <div id="order-modal">
        <b-modal :id="prefix + client_number" size="xl" @hide="loaded = false" scrollable ok-only>
            <div slot="modal-title"><font-awesome-icon icon="user" class="mr-2"/>{{ modal_title }}</div>
            <div v-if="!loaded">
                <p>Cargando...</p>
            </div>
            <div v-else>
                <div v-if="axios_error">
                    <p>Hubo un error obteniendo los datos del cliente</p>
                </div>
                <div v-else-if="client && clientExists && !axios_error">
                    <p><strong>Nombre:</strong> {{ client.name }} ({{ client.client_id }})</p>
                    <p><strong>Estado:</strong> {{ client.client_status }}</p>
                    <p><strong>Direccion:</strong> {{ client.address }}</p>
                    <hr/>
                    <ClientPendingOrders :client_id="client_number"></ClientPendingOrders>
                    <hr/>
                    <VisitHistory :client_id="client_number"></VisitHistory>
                </div>
                <div v-else>
                    <h4 class="mt-2">Este cliente no existe</h4>
                </div>
            </div>
            <template #modal-footer="{ close }">
                <b-button size="sm" variant="primary" @click="close()">
                    Cerrar
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import store from '../store'
    import axios from 'axios'
    import ClientPendingOrders from '../components/ClientPendingOrders.vue'
    import VisitHistory from '../components/VisitHistory.vue'
    import { hasPerm } from '../common'

    export default {
        name: "ClientInfoModal",
        store: store,
        components: {
          ClientPendingOrders,
          VisitHistory
        },
        props: {
            prefix: {
                type: String,
                default: ""
            },
            client_number: String,
            mode: String
        },
        data() {
            return {
                client: null,
                clientExists: false,
                modal_title: `Cargando ${this.client_number}`,
                axios_error: "",
                loaded: false,
            }
        },
        mounted() {
            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                if (modalId == `${this.prefix}${this.client_number}`)
                    {
                        hasPerm('can_query_client').then(response => {
                            if (response == true)
                            {
                                if (modalId == `${this.prefix}${this.client_number}`)
                                {
                                    const data = {
                                        client_id: this.client_number,
                                    };
                                    const config = {
                                        headers: { Authorization: `Bearer ${store.state.token}` }
                                    };

                                    this.modal_title = `Cargando Cliente ${this.client_number}`

                                    axios.post(this.$store.getters.getApiURL('api_client_info') , data, config)
                                    .then(response => {
                                        if (response.data.code == 200)
                                        {
                                            this.client = response.data.data
                                            if(Object.keys(this.client).length > 0){
                                                this.clientExists = true
                                                this.modal_title = `Cliente ${this.client_number}` 
                                            }else{
                                                this.clientExists = false
                                                this.modal_title = `Cliente inexistente` 
                                            }
                                        }
                                        else {
                                            this.axios_error = response.data.diagnostic
                                        }
                                    })
                                    .catch(() => {
                                        this.axios_error = true;
                                    })
                                    .finally(() => {
                                        this.loaded = true
                                    })
                                }
                            } else {
                                this.$swal({ 
                                    icon: 'error',
                                    title: 'Consultar Cliente',
                                    text: "No posee permisos para consultar clientes"
                                    });
                                this.$bvModal.hide(modalId)
                            }
                        }
                    );
                }
            });
        },
    }
</script>

<style>
    .fallapre {
        font-family: 'Courier New', Courier, monospace;
    }
</style>
