<template>
    <div id="otlist-help-container">
        <b-modal title="Colores: Órdenes de trabajo" id="otlist-help" ok-only>
            <b-row>
                <b-col class="techorder normal" cols="2">
                </b-col>
                <b-col align-self="center" cols="10">
                    <p>OT sin visita asignada con prioridad Normal</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="techorder low" cols="2">
                </b-col>
                <b-col align-self="center" cols="10">
                    <p>OT sin visita asignada con prioridad Baja</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="techorder high" cols="2">
                </b-col>
                <b-col align-self="center" cols="10">
                    <p>OT sin visita asignada con prioridad Alta</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="techorder wip" cols="2">
                </b-col>
                <b-col align-self="center" cols="10">
                    <p>OT con visita asignada</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="techorder finished" cols="2">
                </b-col>
                <b-col align-self="center" cols="10">
                    <p>OT con visita asignada, y visita concluída exitosamente</p>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "OtHelpModal",

}

</script>

<style lang="scss" scoped>

    @import '../assets/custom-theme.scss';

    @mixin gradient($direction, $list) {
        background: -webkit-linear-gradient($direction, $list);
        background: -moz-linear-gradient($direction, $list);
        background: -o-linear-gradient($direction, $list);
        background: linear-gradient($direction, $list);
    }

    .techorder {
        min-height: 5em;
        width: 100%;
        padding: 0.15em;
        border-radius: 0.15em;
    }

    .techorderfield {
        font-size: 0.5em;
        width: 100px;
        font-weight: 700;
        float: left;
    }

    .normal
    {
        @include gradient(90deg, $reclamo_normal);
    }

    .low {
        @include gradient(90deg, $reclamo_baja);
    }

    .medium {
        @include gradient(90deg, $reclamo_media);
    }

    .high {
        @include gradient(90deg, $reclamo_alta);
    }

    .finished {
        @include gradient(90deg, $reclamo_finished);
    }

    .wip {
        @include gradient(90deg, $reclamo_wip);
    }

</style>