<template>
    <div id="order-modal">
         <!-- TODO: Agregar modals para error de carga de OT y loading screen  -->
        <b-modal :id="prefix + ot_type + ot_number" size="xl" scrollable ok-only>
            <div slot="modal-title"><font-awesome-icon icon="file" class="mr-2"/>{{ modal_title }}</div>
            <div v-if="!order && !axios_error">
                <p>Cargando información...</p>
            </div>
            <div v-if="axios_error">
                <p>OT inexistente</p>
            </div>
            <div v-if="order">
                <b-alert v-if="order.visits_pending > 0 && mode == 'editor'" show variant="warning">Este cliente tiene {{ order.visits_pending > 1 ? "visitas abiertas" : "una visita abierta" }} ({{ order.visits_pending  }}), {{ order.visits_pending > 1 ? "consultarlas" : "consultarla" }}: 
                <a v-for="visit in order.visits_list" :key="visit" :href="$router.resolve({ name: 'get_visit', params: { visit_id: visit, mode: 'jefetec' }}).href">#{{ visit }}  </a></b-alert>
                <b-alert v-if="order.status_id > 2" show variant="warning">Esta orden de trabajo se encuentra {{ order.status_id === 3 ? "cumplida" : "anulada" }}.</b-alert>
                <OrderInfo :ot_number="ot_number" :ot_type="ot_type" :order_data="order" :autoload="false"></OrderInfo>
                <hr/>
                <VisitHistory :client_id="order.client_id"></VisitHistory>
            </div>
            <template v-if="order && mode == 'editor'" #modal-footer="{ close }">
            <!-- Emulate built in modal header close button action -->
            <b-button v-if="order.visits_pending == 0 && order.status_id <= 2" size="sm" variant="warning" @click="goToCreateVisitForm(ot_type, ot_number)">
                Generar Visita
            </b-button>
            <b-button size="sm" variant="primary" @click="close()">
                Cerrar
            </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import store from '../store'
    import axios from 'axios'
    import OrderInfo from '../components/OrderInfo.vue'
    import VisitHistory from '../components/VisitHistory.vue'
    import { hasPerm } from '../common'

    export default {
        name: "OrderInfoModal",
        store: store,
        components: {
            OrderInfo,
            VisitHistory
        },
        props: {
            prefix: {
                type: String,
                default: ""
            },
            ot_type: String,
            ot_number: String,
            mode: String
        },
        created: function () {
            
        },
        data() {
            return {
                order: null,
                modal_title: `Cargando OT ${this.ot_number}`,
                axios_error: null,
                loaded: false,
            }
        },
        mounted() {
            //console.log("Mounted " + this.ot_type + this.ot_number)
            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                //console.log(bvEvent)
                if (modalId == `${this.prefix}${this.ot_type}${this.ot_number}`)
                    {
                        console.log(`Order after if: ${this.order}`)
                        hasPerm('can_query_order').then(response => {
                            if (response == true)
                            {
                                if (modalId == `${this.prefix}${this.ot_type}${this.ot_number}` && !this.order)
                                {
                                    console.log("[Modal] Cargo OT " + `${this.prefix}${this.ot_type}${this.ot_number}`)
                                    this.modal_title = `Cargando OT ${this.ot_number}`
                                    const data = {
                                        'ot_type': this.ot_type,
                                        'ot_number': this.ot_number
                                    };
                                    const config = {
                                        'headers': { Authorization: `Bearer ${store.state.token}` }
                                    };

                                    this.axios_error ="";
                                    axios.post(this.$store.getters.getApiURL('api_order_url') , data, config)
                                    .then(response => {
                                        if (response.data.code == 200)
                                        {
                                            this.order = response.data.data
                                            this.modal_title = "Orden " + this.ot_number + " | " + this.order.ot_type_friendly  
                                        }
                                        else {
                                            this.axios_error = response.data.diagnostic
                                        }
                                    })
                                    .catch(() => {
                                        this.axios_error = true;
                                    })
                                }
                            } else {
                                this.$swal({ 
                                    icon: 'error',
                                    title: 'Consultar OT',
                                    text: "No posee permisos para consultar OTs"});
                                this.$bvModal.hide(modalId)
                            }
                            
                            //console.log("ModalId=" + modalId)
                        });
                    }
            });
        },
        methods: {
            goToCreateVisitForm: function(ot_type, ot_number) {

                hasPerm('can_create_visit').then(response => {
                    if (response == true)
                    {
                        if(this.order.possible_timestripes.length > 0)
                        {
                            if (this.order.visits_pending == 0)
                            {
                                this.$router.push({'name': 'new_visit', params: { 'type': 'new', 'ot_type': ot_type, 'ot_number': ot_number, 'visit_id': 12 }})
                            } else {
                                this.$swal({ 
                                icon: 'error',
                                title: 'Crear visita',
                                text: "Existen visitas abiertas para este cliente. Debe cerrar o editar las visitas pendientes antes de crear nuevas."});
                            }
                        } else {
                            this.$swal({ 
                                icon: 'error',
                                title: 'Crear visita',
                                text: "La operación no posee franjas horarias para crear visitas"});
                        }
                    } else {
                        this.$swal({ 
                                icon: 'error',
                                title: 'Crear visita',
                                text: "No posee permisos para crear visitas"});
                        }
                })
            }
        },
        watch: {
            ot_number(old_value, new_value) {
                if (old_value != new_value && new_value.length == 11)
                {
                    var self = this;
                    this.$nextTick().then(function() { self.order = null; })
                }
            },
            ot_type(old_value, new_value) {
                if (old_value != new_value)
                {
                    var self = this;
                    this.$nextTick().then(function() { self.order = null; })
                }
            }
        }
    }
</script>

<style>
    .fallapre {
        font-family: 'Courier New', Courier, monospace;
    }
</style>
