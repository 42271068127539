<template>
    <div id="externalvisitview">
        <b-container>
            <b-row>
                <b-col>
                    <h1>Información de la visita</h1>
                    <OrderInfo v-if="order" :order_data="order" :autoload="false"></OrderInfo>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from 'axios'
    import store from '../store'
    import OrderInfo from "../components/OrderInfo.vue"

    export default {
    name: "ExternalVisitView",
    store: store,
    components: {
        OrderInfo
    },
    created: function() {
        this.loadOrder();
    },
    data() {
        return {
            order: null
        }
    },
    methods:
    {
        loadOrder: function()
        {
            const payload = {
                'token': this.$route.params.token,
            }
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_visit_external'), payload, config)
            .then(response => {
                if (response.data.data.error == false)
                {
                    this.order = response.data.data.data.order
                }
                else 
                {
                    this.$swal({ 
                        icon: 'error',
                        title: 'Auditar visita',
                        text: response.data.data.diagnostic,
                        backdrop: `
                            rgba(255,0,0,0.8)
                        `}).then( () => {
                            this.$router.push({'name': 'login'})
                        });
                }
            })
            .catch(error => {
                console.log(error)
                this.tts_error = true
                this.loading_tickets = false
            });
        }
    }
    }
</script>

<style scoped>
    #externalvisitview {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }
</style>
