import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Multiselect from 'vue-multiselect'
import VueSignature from 'vue-signature-pad';


import { BootstrapVue, IconsPlugin, AlertPlugin, CardPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';

// Respetar este orden de bootstrap (Theming)

import './assets/custom-theme.scss'

// VueSweetAlert: If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import '@sweetalert2/theme-bulma/bulma.scss';


// Si no quiero overrides de tema, comentar el sass de arriba y descomentar estos
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(AlertPlugin)
Vue.use(CardPlugin)
Vue.use(VueSweetalert2)
Vue.use(Multiselect)
Vue.use(VueSignature)

// import the fontawesome core 
import { library } from '@fortawesome/fontawesome-svg-core'

// import font awesome icon component 
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Comentamos esto, ya que por un tema de optimizarcion  preferimos importar icono por icono en vez de todo el set (fas)
//import { fas, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
//library.add(fas)

// import icons
import { faArrowLeft, faHouseUser, faCalendarCheck, faInfoCircle, faUserSlash, faMinusCircle, faPlusCircle, faUser, faKey, faExclamationTriangle, faFile, faArrowRight, faEdit, faClock, faCircle, faCog, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowLeft)
library.add(faHouseUser)
library.add(faCalendarCheck)
library.add(faInfoCircle)
library.add(faUserSlash)
library.add(faMinusCircle)
library.add(faPlusCircle)
library.add(faUser)
library.add(faKey)
library.add(faExclamationTriangle)
library.add(faFile)
library.add(faArrowRight)
library.add(faEdit)
library.add(faClock)
library.add(faCircle)
library.add(faCog)
library.add(faChevronRight)
library.add(faChevronLeft)

Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
