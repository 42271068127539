<template>
    <div v-if="form" id="visitstripeeditor">
        <b-container fluid>
            <b-row>
                <b-col cols="12" class="mb-4">
                    <h1>Editor de franjas horarias</h1>
                    <p>Estas son las franjas horarias que tenés asignadas. Podés editar y crear nuevas. Sólo podrás eliminar franjas, si las mismas no tienen visitas pendientes.</p>
                    <p>Tené en cuenta que esta edición editará las franjas horarias de la operación, y no la del usuario.</p>
                </b-col>
            </b-row>
        <div v-if="form">
            <div v-for="subsidiary, index in form" :key="subsidiary.id">
                <b-row>
                    <b-col cols="12">
                        <h2>Operación {{ index }}</h2>
                        <b-table :ref="'table-' + index" striped hover :fields="fields" :items="subsidiary.timestripes" :small="true">
                            
                                <template #cell(editbtn)="data">
                                    <b-button size="sm" @click="goToggleDetails(data)" class="mr-2">
                                    <!-- <b-button size="sm" @click="data.toggleDetails" class="mr-2"> -->
                                        Editar Información
                                    </b-button>
                                </template>

                                <template #cell(habilitado)="data">
                                    {{ data.item.enabled ? 'Habilitado' : 'Deshabilitado'}}
                                </template>

                                <template #row-details="data">
                                <b-card>
                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>Habilitado:</b></b-col>
                                        <b-col><b-checkbox v-model="data.item.enabled"></b-checkbox></b-col>
                                    </b-row>

                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>Nombre:</b></b-col>
                                        <b-col><b-input v-model="data.item.name"/></b-col>
                                    </b-row>

                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>Desde las:</b></b-col>
                                        <b-col><b-form-select v-model="data.item.from" :options="hours"></b-form-select></b-col>
                                        <!-- <b-col><b-input type="number" v-model="data.item.from" min="0" max="23"/></b-col> -->
                                    </b-row>

                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>Hasta las:</b></b-col>
                                        <b-col><b-form-select v-model="data.item.to" :options="hours"></b-form-select></b-col>
                                        <!-- <b-col><b-input type="number" v-model="data.item.to" min="0" max="23"/></b-col> -->
                                    </b-row>

                                    <b-row class="mb-2">
                                        <b-col class="text-sm-right">
                                            <b-button size="sm" @click="toggleSave(data)" class="mr-2" variant="primary">Guardar y cerrar</b-button>
                                            <b-button size="sm" @click="deleteTimestripe(data, subsidiary.id, index)" variant="danger">Eliminar</b-button>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </template>
                        </b-table>
                        <b-button size="sm" @click="addTimestripe(index, subsidiary.id)" variant="primary" class="mb-2 text-sm-right">Agregar franja horaria para {{ index }}</b-button>
                    </b-col>
                </b-row>
                <hr>
            </div>
        </div>
        </b-container>
    </div>
    
</template>

<script>

    import store from "../store"
    import axios from 'axios'

    export default {
    name: "VisitStripeEditor",
    data() {
        return {
            form: null,
            hours: [ 
                // {
                //     text: "00:00 hs",
                //     value: 0
                // },
                // {
                //     text: "01:00 hs",
                //     value: 1
                // },
                // {
                //     text: "02:00 hs",
                //     value: 2
                // },
                // {
                //     text: "03:00 hs",
                //     value: 3
                // },
                // {
                //     text: "04:00 hs",
                //     value: 4
                // },
                // {
                //     text: "05:00 hs",
                //     value: 5
                // },
                // {
                //     text: "06:00 hs",
                //     value: 6
                // },
                {
                    text: "07:00 hs",
                    value: 7
                },
                {
                    text: "08:00 hs",
                    value: 8
                },
                {
                    text: "09:00 hs",
                    value: 9
                },
                {
                    text: "10:00 hs",
                    value: 10
                },
                {
                    text: "11:00 hs",
                    value: 11
                },
                {
                    text: "12:00 hs",
                    value: 12
                },
                {
                    text: "13:00 hs",
                    value: 13
                },
                {
                    text: "14:00 hs",
                    value: 14
                },
                {
                    text: "15:00 hs",
                    value: 15
                },
                {
                    text: "16:00 hs",
                    value: 16
                },
                {
                    text: "17:00 hs",
                    value: 17
                },
                {
                    text: "18:00 hs",
                    value: 18
                },
                {
                    text: "19:00 hs",
                    value: 19
                },
                {
                    text: "20:00 hs",
                    value: 20
                },
                {
                    text: "21:00 hs",
                    value: 21
                },
                {
                    text: "22:00 hs",
                    value: 22
                },
                {
                    text: "23:00 hs",
                    value: 23
                },
             ],
            fields: [
                { key: 'name', label:'Nombre', tdClass: 'col-5' },
                { key: 'from', label:'Desde', tdClass: 'col-1' },
                { key: 'to', label:'Hasta', tdClass: 'col-1' },
                // Templates
                { key: 'habilitado', label: 'Habilitado', tdClass: 'col-2' },
                { key: 'editbtn', label: '', tdClass: 'col-3' }
            ]
        }
    },
    created: function() {
        this.getSubsidiaryTable()
    },
    methods: {
        getSubsidiaryTable: function()
        {
            const payload = {
                'sample': null,
            }
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_timestripes'), payload, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.form = response.data.data
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        deleteTimestripe: function(data, ref, index) {
            if (data.item.id == null)
            {
                this.form[index].timestripes.splice(data.index,1)
                return;
            } 
            this.$swal({
                title: 'Confirmar operación',
                text: `¿Desea eliminar '${data.item.name}'? Será una accion irreversible`,
                showDenyButton: true,
                icon: 'question',
                showCancelButton: false,
                confirmButtonText: 'Eliminar',
                denyButtonText: `Cancelar`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.deleteContent(data.item.id, ref, index, data.index)
                    } else if (result.isDenied) {
                        this.$swal('No se eliminó la franja', '', 'info')
                    }
                })
        },
        toggleSave: function(data) {

            if (data.item.from >= data.item.to)
            {
                this.$swal('El horario de inicio no puede ser mayor o igual al de finalización', '', 'error')
                return;
            }

            this.submitContent(data.item)
            // Envio los datos de la franja horaria. La Api se encarga de saber si es registro nuevo o existente ya que en el dato le envío 'null' como ID
            data.toggleDetails()
        },
        goToggleDetails: function(data) {
            if (data.detailsShowing == true)
            {
                this.$swal({
                title: 'Sin confirmar',
                text: `¿Desea enviar los datos de '${data.item.name}'?`,
                showDenyButton: true,
                icon: 'question',
                showCancelButton: false,
                confirmButtonText: 'Cancelar',
                denyButtonText: `Eliminar`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isDenied) {
                        this.toggleSave(data)
                    } else if (result.isConfirmed) {
                        this.getSubsidiaryTable();
                    }
                })
            }
            data.toggleDetails();
        },
        addTimestripe: function(key, subsidiary_id) {
            // Probablemente sea mejor mandar el model. WIP.
            this.form[key]['timestripes'].push({ 
                'id': null,
                'name': 'Nueva Franja',
                'from': 9,
                'to': 18,
                'enabled': true,
                'subsidiary_id': subsidiary_id,
                '_showDetails': true })
        },
        submitContent: function(payload) {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_timestripes_edit') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal(`Franja ${payload.id? 'editada': 'agregada'} exitosamente!`, '', 'success')
                    //Al crear seteo el ID que me devuelve la API para que luego me pregunte si quiero eliminarla
                    payload.id = response.data.data
                }
                else {
                    this.$swal(response.data.diagnostic, '', 'error');
                    this.getSubsidiaryTable();
                }
            })
            .catch(error => {
                this.$swal('Error al procesar información: ' + error, '', 'error')
            })
        },
        deleteContent: function(id, subsidiary, index, data_index)
        {
            // id = id del registro
            // subsidiary = id de la operacion
            // index = key del JSON (Nombre de la operacion)
            // data_index = índice de cómo mostró este ítem Bootstrap (Creado dinámicamente..)
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };
            
            const payload = {
                'subsidiary_id': subsidiary,
                'timestripe_id': id
            }

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_timestripes_delete') , payload, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.$swal('Franja eliminada exitosamente!', '', 'success')
                    // Si la API nos responde bien, eliminamos el index que nos entregó la tabla responsive de Bootstrap...
                    this.form[index].timestripes.splice(data_index,1)
                }
                else {
                    this.$swal('No se pudo eliminar la franja horaria:' + response.data.diagnostic, '', 'error')
                }
            })
            .catch(error => {
                this.$swal('Error al procesar información: ' + error, '', 'error')
            })
        }
    }
    }
</script>

<style scoped>
    #visitstripeeditor {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }
</style>
