<template>
    <div id="visitinfo">
            <div v-if="visit">
                <b-card
                    class="mt-2"
                >
                    <b-overlay :show="showOverlay" rounded="sm">
                    <div class="visit-info-container d-sm-flex justify-content-center align-items-center mt-1 mb-1">

                        <a class="ml-0 mr-auto" :class="{ invisible: !visit.rescheduled_previous }"  :href="this.$router.resolve({ name: 'get_visit', params:{ visit_id: this.visit.rescheduled_previous } }).href">
                            <b-button class="ml-0 mr-auto text-left text-sm-right reschedule-btn reschedule-btn-left" variant="outline-subtle" style="fontSize: 1.2rem; width:100%; maxWidth: 150px;">
                                <p class="m-0 text-uppercase reschedule-hint" style="fontSize: 0.7rem;">Reprogramada de</p>
                                <b-icon-arrow-left class="mr-2"></b-icon-arrow-left>#{{ visit.rescheduled_previous }}
                            </b-button>
                        </a>

                        <div class="current-visit-container text-center ml-2 mr-2">
                            <h2 class="mt-3 mt-sm-0">Visita #{{ visit.id }}</h2>
                            <div class="text-uppercase subtle-error rounded-pill pt-1 pb-1 mb-3 mb-sm-0" v-if="visit.rescheduled_next">Reprogramada</div>
                            <div class="text-uppercase subtle-info rounded-pill pt-1 pb-1 mb-3 mb-sm-0" v-if="visit.on_the_way">En camino</div>
                            <!-- <div class="text-uppercase subtle-error rounded-pill pt-1 pb-1 mb-3 mb-sm-0" v-else-if="visit.cancelled && !visit.rescheduled_next">Cancelada</div> -->
                            <!-- <div class="text-uppercase subtle-success rounded-pill pt-1 pb-1 mb-3 mb-sm-0" v-else-if="visit.finished && !visit.rescheduled_next">Finalizada</div> -->
                            <!-- <div class="text-uppercase subtle-info rounded-pill pt-1 pb-1 mb-3 mb-sm-0" v-else>Pendiente</div> -->
                        </div>
                        <a class="w-100 ml-auto mr-0" :class="{ invisible: !visit.rescheduled_next }" style="maxWidth: 150px;" :href="this.$router.resolve({ name: 'get_visit', params:{ visit_id: this.visit.rescheduled_next } }).href">

                            <b-button class="text-right text-sm-left reschedule-btn" variant="outline-subtle" style="fontSize: 1.2rem; width:100%;">
                                <p class="m-0 text-uppercase reschedule-hint" style="fontSize: 0.7rem;">Reprogramada a</p>
                                #{{ visit.rescheduled_next }}<b-icon-arrow-right class="ml-2"></b-icon-arrow-right>
                            </b-button>
                        </a>
                    </div>
                    <hr>
                    <!-- <p><b>OT Nro:</b> <a href="#" @click="showOrderModal">{{ visit.ot_number }}</a> {{ visit.subsidiary_friendly }}</p> -->
                    <p class="d-none d-sm-block d-md-none d-lg-none d-xl-none" ><b>OT: </b> {{ visit.ot_number }} ({{ visit.ot_type_friendly }})</p>
                    <b-alert v-if="visit.derivation_type" show>Derivada a <strong>{{ visit.derivation_type_friendly }}</strong></b-alert>
                    <p><b>Cliente: </b> {{ visit.client_id }}</p>
                    <div v-if="editable('status')">
                        <div v-if="canOpenStatusModal()">
                            <p><b>Estado: <a href="#" @click="showStatusModal">{{ visit.status }}</a></b></p>
                        </div>
                        <div v-else>
                            <p><b>Estado: {{ visit.status }}</b></p>
                        </div>
                    </div>
                    <div v-if="!editable('status') || ( visit.finished || visit.cancelled || visit.rescheduled )">
                        <p><b>Estado: {{ visit.status }}</b></p>
                    </div>
                    <p><b>Creador de la visita:</b> {{ visit.creator }}</p>
                    <p v-if="visit.detached_by"><b>Desasignado por:</b> {{ visit.detached_by }}</p>
                    <p v-if="visit.detached_by"><b>Comentario de desasignación:</b> {{ visit.last_detached_comments }}</p>


                    <div v-if="!editable('stripe')">
                        <p><b>Fecha de visita:</b> {{ visit.date_dispatch }} (Franja {{ visit.timestripe_friendly }})</p>
                    </div>
                    <div v-if="editable('stripe')">
                        <p><b>Fecha de visita:</b> {{ visit.date_dispatch }}</p>
                        <b-row>
                            <b-col cols="12" lg="4">
                                <p class="mt-2"><b>Franja horaria:</b></p>
                            </b-col>
                            <b-col cols="12" lg="8">
                                <b-form-select
                                    id="input-29"
                                    name="input-29"
                                    v-model="form.timestripe_id"
                                    :options="stripe_list"
                                    >
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </div>

                    <div v-if="!editable('tech')">
                        <p><b>Asignado a:</b> {{ visit.assigned_to? visit.assigned_to : "Sin asignar" }}</p>
                    </div>
                    <div v-if="editable('tech')">
                        <GigaredFormControl title="Técnico">
                            <b-form-select
                            id="input-3"
                            name="input-3"
                            v-model="form.tech_id"
                            :options="technicians_list"
                            :disabled="visit.on_the_way || visit.status == 'Cierre demorado'"
                            >   
                                <template #first>
                                    <b-form-select-option :value="null">&nbsp;</b-form-select-option>
                                </template>
                            </b-form-select>
                        </GigaredFormControl>
                    </div>

                    <!-- <p><b>Prioridad: </b> {{ visit.priority }}</p> -->
                    <p><b>Creada: </b>{{ visit.date_created }}</p>
                    <!-- <p><b>Modificada: </b>{{ visit.date_modified }}</p> -->
                    <b-form @submit="onSubmit" @reset="onReset">
                        <div v-if="visit.tech_comments">
                            <div v-if="!editable('tech_comments')">
                                <p><b>Notas de la visita:</b></p>
                                <div v-for="comment in visit.tech_comments" :key="comment">
                                    <p class="comments">{{ comment }}</p>
                                </div>  
                            </div>
                            <div v-if="editable('tech_comments')">
                                <b-row>
                                    <b-col cols="12">
                                        <p><b>Todas las notas:</b></p>
                                    </b-col>
                                    <b-col cols="12">
                                        <div v-for="comment in visit.tech_comments" :key="comment">
                                            <p class="comments">{{ comment }}</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12">
                                        <p><b>Nueva nota:</b></p>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-textarea
                                        id="textarea"
                                        v-model="form.tech_comments"
                                        placeholder="Escriba su texto..."
                                        rows="3"
                                        max-rows="6"
                                ></b-form-textarea>
                                    </b-col>
                                </b-row>
                               
                            </div>
                        </div>
                    </b-form>
                    <div v-if="visit.extra_comments">
                        <p><b>Notas de la reparación:</b></p>            
                        <p class="comments">{{ visit.extra_comments }}</p>
                    </div>
                    <div v-if="visit.reschedule_comments">
                        <p><b>Notas de la reprogramación:</b></p>            
                        <p class="comments">{{ visit.reschedule_comments }}</p>
                    </div>
                    <div v-if="visit.materials">
                        <p><b>Materiales utilizados:</b></p>
                        <b-table striped hover :fields="materials_fields" :items="visit.materials" :small="true"></b-table>
                    </div>
                    <div v-if="visit.material_comments">
                        <p><b>Otros materiales utilizados/observaciones:</b></p>
                        <p>{{ visit.material_comments }}</p>
                    </div>

                    <div v-if="visit.labors">
                        <p><b>Mano de obra:</b></p>
                        <b-table striped hover :fields="labors_fields" :items="visit.labors" :small="true"></b-table>
                    </div>
                    <div v-if="visit.labor_comments">
                        <p><b>Otros materiales utilizados/observaciones:</b></p>
                        <p>{{ visit.labor_comments }}</p>
                    </div>
                    <Timeline v-if="segment" :events="segment.for_vue"></Timeline>
                    <p v-if="segment && ( visit.finished || visit.cancelled || visit.rescheduled )"><b>Duración total: </b> {{ segment.total_time_friendly }}</p>
                    <!-- <p v-if="visit.external_token"><b>Link Externo: </b><a target="_blank" :href="this.$router.resolve({ name: 'visit_external_view', params:{ token: this.visit.external_token } }).href">Link</a></p> -->
                    </b-overlay>
                </b-card>
                <FinishVisitModal 
                        v-if="showFinishModal && subsidiary_capabilities"
                        :capabilities="subsidiary_capabilities"
                        :visit_id="visit.id" 
                        :default_status="visit.status_id"
                        :extra_comments="visit.extra_comments"
                        :show_every_reason="true"></FinishVisitModal>
            </div>
    </div>
</template>

<script>

    import store from "../store"
    import axios from "axios"
    import bus from "../bus"
    import FinishVisitModal from "./FinishVisitModal.vue"
    import GigaredFormControl from "./GigaredFormControl.vue"
    import { showErrorMsg } from "../common.js"
    import Timeline from "./Timeline.vue"

    export default {
    name: "VisitInfo",
    props: ['visit_id', 'edit'],
    components: {
        FinishVisitModal,
        GigaredFormControl,
        Timeline
    },
    data() {
        return {
            segment: null,
            visit: null,
            showOverlay: false,
            subsidiary_capabilities: null,
            showFinishModal: true,
            materials_fields: [
                { key: 'external_code', label:'Código', tdClass: 'col-2' },
                { key: 'name', label:'Nombre', tdClass: 'col-8' },
                { key: 'amount', label:'Unidades', tdClass: 'col-2' },
            ],
            labors_fields: [
                { key: 'external_code', label:'Código', tdClass: 'col-2' },
                { key: 'name', label:'Mano de obra', tdClass: 'col-8' },
                { key: 'amount', label:'Unidades', tdClass: 'col-2' },
            ],
            form: {
                tech_comments: null,
                tech_id: null,
                tech_name: null,
                timestripe_id: null,
            },
            technicians_list: null,
            stripe_list: null,
        }
    },
    mounted() {
        bus.$on('updateVisit', () => {
            this.updateVisit()
        })
    },
    beforeDestroy() {
        bus.$off('updateVisit')
    },
    created: function() {
        this.getVisit()
        this.getSegment()
        this.form.visit_id = this.visit_id
    },
    methods: {
        showStatusModal: function() {
            // var self = this
            // this.$nextTick().then(() => {
            //     self.showFinishModal = false;
            //     self.getSubsidiaryCapabilities();
            // })
            this.$bvModal.show("FinishModal_" + this.visit.id)
        },
        showOrderModal: function()
        {
            this.$bvModal.show(this.visit.ot_type + this.visit.ot_number)
        },
        editable: function(data) {
            if (this.visit.cancelled || this.visit.finished) return false;
            if (this.edit.includes(data)) return true; else return false;
        },
        canOpenStatusModal: function() {
            const userPermissions = store.state.permissions;
            const neededPermissions = ['can_assign_visit', 'can_cancel_visit', 'can_finish_visit'];

            const userHasAllPermissions = neededPermissions.every(permission => {
                return userPermissions.includes(permission);
            });

            return userHasAllPermissions;
        },
        onSubmit: function() {
            
        },
        onReset: function() {

        },
        getSegment: function()
        {
            const data = {
                'visit_id': this.visit_id,
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_report_segment') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.segment = response.data.data
                }
                else {
                    console.log(response.data)
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },
        getVisit: function()
        {
            this.showOverlay = true;
            console.log("Cargo visita " + this.visit_id)
            const data = {
                'visit_id': this.visit_id,
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_info_id') , data, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.visit = response.data.data
                    //this.form.tech_comments = this.visit.tech_comments
                    this.form.tech_id = this.visit.assigned_id
                    // Envio al parent informacion de la OT
                    this.$emit("set-ot-data", this.visit.ot_type, this.visit.ot_number)
                    this.stripe_list = response.data.data.possible_timestripes
                    this.$emit("set-timestripes", response.data.data.timestripe, this.stripe_list)
                    this.form.timestripe_id = response.data.data.timestripe

                    // Si la visita no está finalizada o cancelada, muestro los botones en el formulario padre
                    if (this.visit.cancelled == false && this.visit.finished == false)
                    {
                        this.$emit("set-buttons", true)
                    } else {
                        this.$emit("set-buttons", false)
                    }

                    this.$emit("set-status", this.visit.status_id);
                    this.$emit("set-comment", this.visit.extra_comments);
                    this.$emit("set-tech-id", this.visit.assigned_id);
                    this.$emit("set-signature", this.visit.signature);
                    this.$emit("set-on-the-way", this.visit.on_the_way);
                    this.showOverlay = false;

                    this.getSubsidiaryCapabilities()

                    
                    // Lleno la lista de técnicos (Si es editable)
                    if (this.edit.includes("tech") && this.visit.finished == false)
                    {
                        // Convertimos la fecha de dd/mm/yyyy a yyyy-mm-dd
                        let date_ = this.visit.date_dispatch.slice(6,10) + '-' + this.visit.date_dispatch.slice(3,5)  +  '-' + this.visit.date_dispatch.slice(0,2)
                        this.get_tech_list(this.visit.ot_number.substring(0,3), date_, this.form.timestripe_id);
                        // Convierto el entero a string con ceros a la izquierda. 
                        this.form.tech_id = this.visit.assigned_id.toString().padStart(6, '0')
                    }
                }
                else {
                    console.log(response.data)
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },
        updateVisit: function()
        {
            //Cargo el nombre del técnico
            if (this.form.tech_id != null)
            {
                const val = this.technicians_list.filter(tech => tech.value == this.form.tech_id)
                if (val === undefined)
                {
                    this.$swal('No se pudo obtener el nombre del técnico. Por favor realice otra selección', '', 'error');
                    return;
                } else {
                    this.form.tech_name = val[0].name;
                }
            }

            this.showOverlay = true;

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_edit') , this.form, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.showOverlay = false;
                    this.$swal({ 
                        icon: 'success',
                        title: 'Editar visita',
                        html: "Se editó correctamente la visita!"}).then(() => {
                            this.$router.push({'name': store.state.defaultView})
                    });
                }
                else {
                    this.$swal({ 
                        icon: 'error',
                        title: 'Editar visita',
                        text: response.data.diagnostic }).then(() => {
                            this.$router.push({'name': store.state.defaultView})
                    });
                    this.showOverlay = false;
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },
        get_tech_list: function(operations, date_, timestrip) {

            const data = {
                        'operations': operations,
                        'date_': date_,
                        'timestrip': timestrip
                    };


            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_get_technicians_list') , data, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    // Al nombre del tecnico le agregamos la cantidad de visitas asignadas
                    let tech = []
                    response.data.data.forEach(function(t) {
                        let txt = ""
                        if(t.assigned_visits > 0) {
                            if (t.assigned_visits == 1){
                                txt =  "(" + t.assigned_visits + " visita asignada)"
                            } else {
                                txt = "(" + t.assigned_visits + " visitas asignadas)"
                            }
                        }
                        tech.push({"text": t.text + " " + txt , "value": t.value, "name": t.text })
                    })

                    this.technicians_list = tech

                }
                else {
                    this.axios_error = response.data.diagnostic
                    showErrorMsg('No se pudo obtener la lista de técnicos')
                }
            })
            .catch(error => {
                this.axios_error = error
                showErrorMsg('No se pudo obtener la lista de técnicos', this, 'Error', 'main')
            })
        },
        getSubsidiaryCapabilities: function() {
            const data = {
                'visit_id': this.visit.id,
            };

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_subsidiary_capabilities') , data, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.subsidiary_capabilities = response.data.data
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        }
    },
    watch: {
        'visit_id' () {
            //this.getVisit()
            this.visit = null;
            var self = this;

            this.$nextTick().then(function () {
                self.getVisit();
                self.getSegment();
                self.form.visit_id = self.visit_id;
            });   

        },
        'form.tech_id' () {
            this.$emit("set-new-tech-id", this.form.tech_id);
        },
    }
}

</script>

<style lang="scss">

    @import '../assets/custom-theme.scss';

    #visitinfo {
        scrollbar-color: theme-color("primary") #00000000;
    }

    .comments {
        font-family: 'Courier New', Courier, monospace;
    }

    .visitfinished {
        color: theme-color("success")
    }

    .visitcancelled {
        color: theme-color("danger")
    }

    .reschedule-btn {
        color: #0e84b3 !important;
    }

    .reschedule-hint {
        color: #3c4a55 !important;
        font-weight: light !important;
    }

    @media (min-width:790px) and (max-width:1350px) {
        .reschedule-hint {
            display: none;
        }
    }

    @media (min-width:455px) and (max-width:650px) {
        .reschedule-hint {
            display: none;
        }

        .reschedule-btn {
            max-width: none !important;
            width: auto !important;
        }
    }

    @media (max-width:455px) {
        .reschedule-btn {
            width: 100% !important;
            margin: 0 !important;
            display: none;
            max-width: none !important;
        }

    }

    .invisible {
        visibility: hidden;
    }
</style>
