<template>
    <div id="TechOrderPriorityList" class="TechOrderPriorityList">
        <b-container>
        <b-row>
            <b-form-group v-if="Object.keys(order_types).length > 1" label="Filtrar por tipo de orden" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    v-model="order_type_selected"
                    :aria-describedby="ariaDescribedby"
                    name="order-radios"
                    @change="filterChanged"
                     >
                    <b-form-radio value="All">Todos</b-form-radio>
                    <b-form-radio v-for="item in order_types" :key="item.text" :value="item.text">{{ item.text }}</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </b-row>
        <b-row class="text-center">
            <b-col cols="12" lg="12">
                <p v-if="!loading_orders && order">{{ orders_qty }} órdenes {{ show_unscheduled ? "sin visitas": "" }} pendientes</p>
            </b-col>
        </b-row>
        <b-row align-h="end">
            <font-awesome-icon title="Ayuda" icon="info-circle" class="mb-2" @click="$bvModal.show('otlist-help')"/>
            <OtHelpModal></OtHelpModal>
        </b-row>
        </b-container>
        <div v-for="item in order_filtered" :key="item.uuid"> 
        <h1><TechOrder :order=item></TechOrder></h1>
        </div>
        <div v-if="orders_error" class="justify-content-md-center align-items-center">
            <img src="../assets/images/unplug.png" width="100px">
            <h3>Error al descargar el listado de OT</h3>
            <p>Por favor refresque esta página</p>
        </div>
        <div v-if="loading_orders && !orders_error && order.length == 0" class="justify-content-md-center align-items-center text-center">
            <h4 class="mt-4">Cargando órdenes</h4>
            <p>Se está realizando un análisis de prioridades.<br>A mayor cantidad de órdenes pendientes, mayor será la demora.</p>
        </div>
        <infinite-loading v-if="!orders_error" :identifier="infiniteId" spinner="spiral" @infinite="infiniteHandler">
            <div slot="spinner"><b-spinner style="width: 3rem; height: 3rem" class="mt-4 mb-4"></b-spinner></div>
            <div slot="no-more">No se encontraron mas resultados</div>
            <div slot="no-results">No se encontraron mas resultados</div>
        </infinite-loading>
    </div>
</template>

<script>
import store from '../store'
import TechOrder from './TechOrder.vue'
import OtHelpModal from './OtHelpModal.vue'
import axios from 'axios'
import InfiniteLoading from 'vue-infinite-loading';

export default {
    name: 'TechOrderPriorityList',
    store: store,
    components: {
        TechOrder,
        InfiniteLoading,
        OtHelpModal
    },
    props: {
        operations: Array,
        operation_selected: String,
        zone: Number,
        show_contractors: Boolean,
        show_unscheduled: Boolean
    },
    data() { 
        return {
            axiosRequests: [],
            infiniteId: +new Date(),
            loading_orders: false,
            orders_error: false,
            order_types: [],
            order_type_selected: 'All',
            order_request_type: 'priority',
            order: [],
            order_filtered: [],
            orders_offset: 0,
            orders_qty: 0,
            initialSize: null
        }
    },
    created: function() {
        
    },
    mounted: function() {
        
    },
    methods: {
        getOrders: function() {
            
        },
        filterResults: function() {
            //console.log("Filtro: " + this.order_type_selected + " + " + this.operation_selected)
            if (this.order_type_selected == "All")
            {
                this.order_filtered = this.order
            } else {
                this.order_filtered = this.order.filter(item => item.ot_type_friendly == this.order_type_selected);
            }

            if (this.operation_selected != "All")
            {
                this.order_filtered = this.order_filtered.filter(item => item.subsidiary_code == this.operation_selected)
            }
            return true;
        },
        filterChanged: function() {
            this.orders_offset = 0;
            this.order = []
            this.order_filtered = []
            this.$nextTick().then(() => {
                //this.fetchOrders()
                this.infiniteId += 1;
            })
            //this.filterResults()
        },
        sleep: function(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        fetchOrders: async function() {
            console.log(`Buscando OT de ${this.operation_selected} offset ${this.orders_offset}`)
            if (this.loading_orders == false)
            {
                const payload = {
                    'subsidiary': store.state.subsidiaries,
                    'offset': this.orders_offset,
                    'request': this.order_request_type,
                    'zone': this.zone,
                    'show_contractors': this.show_contractors,
                    'unscheduled_only': this.show_unscheduled
                }
                if (this.operation_selected != "All")
                {
                    payload.subsidiary = this.operation_selected
                }
                if (this.operation_selected == "All")
                {
                    payload.ot_type = null
                }
                const config = {
                    'headers': { Authorization: `Bearer ${store.state.token}` }
                };
                this.loading_orders = true
                try {
                    let resource = await axios.post(this.$store.getters.getApiURL('api_pending_orders_url') , payload, config)
                    if (resource.data.error == false)
                    {
                        if (payload.subsidiary == this.operation_selected && payload.zone == this.zone && payload.show_contractors == this.show_contractors && payload.unscheduled_only == this.show_unscheduled)
                        {
                            var dataLength = resource.data.data.orders.length
                            this.orders_qty = resource.data.data.orders_qty
                            if (this.orders_offset == 0)
                            {
                                // Ya no filtro las órdenes, ya me llega filtrado desde la SP. Borrar!!!!
                                this.order = resource.data.data.orders
                                //this.order_filtered = resource.data.data.orders
                            } else {
                                // Ya no filtro las órdenes, ya me llega filtrado desde la SP. Borrar!!!!
                                this.order.push(...resource.data.data.orders)
                                //this.order_filtered = resource.data.data.orders
                            }
                            //this.order_filtered.push(...resource.data.data.orders)
                            this.filterResults()
                        
                            this.orders_offset += dataLength
                            
                            // uso filter para recorrer la función porque es mas rápido que un for
                            //resource.data.data.filter(this.getUnique);
                            this.order_types = resource.data.data.order_types
                            this.loading_orders = false
                            return dataLength;
                        }
                        else{
                            console.log(`Espero subsidiary ${this.operation_selected}, pero tengo ${payload.subsidiary}`)
                            this.loading_orders = false
                            return null;
                        }
                    } else {
                        this.orders_error = true;
                        this.$swal({ 
                            icon: 'error',
                            title: 'Obtener OT',
                            text: "Error al obtener órdenes: " + resource.data.diagnostic});
                    }
                }
                catch (err) {
                   console.error(err);
                   this.orders_error = true;
                   return 0;
                }
            } else {
                return null;
            }
            return null;
        },
        infiniteHandler: async function ($state) {
            var orders_qty = await this.fetchOrders()
            //console.log("[Infinite Handler]Ordenes cargadas: " + orders_qty)
            if (orders_qty != null) {
                if ( orders_qty > 0)
                {
                    $state.loaded();
                } else {
                    $state.complete();
                }
            } else {
                //console.log("Demorando script....")
                //await this.sleep(500);
                this.infiniteId += 1;
            }
        }
    },
    watch: {
        operation_selected: function () {
            this.$nextTick().then(() => {
                this.filterChanged()
            })
        },
        zone: function() {
            this.$nextTick().then(() => {
                this.filterChanged()
            })
        },
        show_contractors: function() {
            this.$nextTick().then(() => {
                this.filterChanged()
            })
        },
        show_unscheduled: function() {
            this.$nextTick().then(() => {
                this.filterChanged()
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .TechOrderPriorityList {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        height: 150px;
        /* min-height: 70vh;
        max-height: 74vh; */
    }

</style>
