<template>
    <div id="detachvisit">
        <b-modal :id="'DetachModal_' + visit_id" size="xl" :title="'¿Desea desasignarse esta visita?'" scrollable>

        <p>Quedará disponible para todos los técnicos durante el día.</p>

        <b-form-group id="input-group-21" label-for="input-21">
            <b-form-textarea
                id="textarea"
                v-model="form.extra_comments"
                placeholder="Escriba el motivo de la desasignación de la visita..."
                rows="3"
                max-rows="6"
                maxlength="100"
                required
            ></b-form-textarea>
        </b-form-group>

        <div class="alert alert-warning" role="alert" v-if="error">{{error}}</div>

        <template #modal-footer>
            <div class="w-100">
                <b-button class="float-right ml-2" type="submit" variant="success" @click="postDetachVisit()">Aceptar</b-button>
                <b-button variant="primary" class="float-right ml-2" @click="closeDetachModal()">Cancelar</b-button>
            </div>
        </template>

       </b-modal>
    </div>
</template>



<script>

    /*import axios from 'axios'*/
    import store from '../store'

    export default {
    name: "DetachVisitModal",
    store: store,
    components: {
    },
    data() {
        return {
            form: {
                visit_id: null,
                extra_comments: null,
            },
            error: null
        }
    },
    created() {
        this.form.visit_id = this.visit_id
    },
    methods: {

        closeDetachModal: function()
        {
             this.$bvModal.hide('DetachModal_' + this.visit_id)

        },
        postDetachVisit: function()
        {
            if (this.form.extra_comments==undefined || this.form.extra_comments.length<5){
                this.error = 'Debe ingresar el motivo de la desasignación'
            } else {
                let args = { 'visit_id': this.visit_id, 'comments': this.form.extra_comments }
                this.$emit('name', args) 
            }
        }
    },
    watch:
    {
    },
    props: { 'visit_id': Number,
        },
    }
</script>

<style scoped>
   
</style>
