<template>
    <div id="VisitHistory">
        <div v-if="loaded">
            <div v-if="error">
                <b-alert variant="danger" show><strong>Error:</strong> {{ errorMessage }}</b-alert>
            </div>
            <div v-else>
                <div v-if="history && history.visits.length > 0">
                    <h4>Histórico de visitas: </h4>
                    <b-table striped hover :borderless="true" :items="history.visits" :fields="history_fields" :small="true">
                        <template #cell(status_pretty)="data">
                            <!-- <span :style="{ 'color': '#' + data.item.status_hex }">{{ data.item.status }}</span> -->
                            <span><font-awesome-icon icon="circle" class="mr-2" :style="{ 'color': '#' + data.item.status_hex }" />{{ data.item.status }}</span>
                        </template>
                        <template #cell(visit_link)="data">
                            <a :href="$router.resolve({ name: 'get_visit', params: { visit_id: data.item.id, mode: 'default' }}).href" target="_blank">Ver Visita</a>
                        </template>
                    </b-table>
                </div>
                <div v-else>
                    <h5>Este cliente no tiene visitas previas</h5>
                </div>
            </div>
        </div>
        <LoaderWithText v-else text="Cargando historico de visitas"/>
    </div>
</template>

<script>
import axios from 'axios'
import LoaderWithText from '../components/LoaderWithText.vue'
import store from '../store'

export default {
    name: 'VisitHistory',
    props: ['client_id'],
    components: {
        LoaderWithText,
    },
    data: function() {
        return {
            loaded: false,
            error: false,
            errorMessage: '',
            history: null,
            history_fields: [
                { 
                    'key': 'visit_date',
                    'label': 'Fecha',
                },
                {
                    'key': 'symptom_name',
                    'label': 'Sintoma',
                },
                {
                    'key': 'status_pretty',
                    'label': 'Estado',
                },
                {
                    'key': 'ot_number',
                    'label': 'OT',
                    'sortable': true,
                },
                {
                    'key': 'visit_link',
                    'label': '',
                },
            ],
        }
    },
    created: function() {
        const payload = {
            'client_id': this.client_id,
        };

        const config = {
            'headers': { Authorization: `Bearer ${store.state.token}` }
        };

        const url = this.$store.getters.getApiURL('api_visit_history');

        axios.post(url, payload, config)
            .then((response) => {
                if (response.data.code == 200) {
                    this.history = response.data.data
                }
            })
            .catch((error) => {
                this.error = true
                this.errorMessage = error.response.data.diagnostic || 'Error no especificado'
            })
            .finally(() => {
               setTimeout(() => {this.loaded = true}, 200)
            });
    }
}
</script>

<style scoped>

</style>
