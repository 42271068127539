<template>
    <div id="TechOrderPendingList">
        <div v-if="orders_error" class="justify-content-md-center align-items-center">
                <img src="../assets/images/unplug.png" width="100px">
                <h3>Error al descargar el listado de OT</h3>
                <p>Por favor refresque esta página</p>
        </div>
        <div v-if="loading_orders && !orders_error" class="justify-content-md-center align-items-center text-center">
            <h4 class="mt-4">Cargando órdenes</h4>
            <b-spinner style="width: 3rem; height: 3rem" class="mt-4"></b-spinner>
        </div>
        <b-row class="text-center">
            <b-col cols="12" lg="12">
                <p v-if="!loading_orders && orders">{{ orders_qty }} órdenes {{ show_unscheduled ? "sin visitas": "" }} pendientes</p>
            </b-col>
        </b-row>
        <b-table table-class="w-auto" v-if="!loading_orders" hover :items="orders" :fields="fields" :sort-compare="dateSortCompare" striped>

            <template #cell(ot_number_custom)="data">
                <OrderInfoModal :ot_type="data.item.ot_type" :ot_number="data.item.ot_number" mode="editor"></OrderInfoModal>
                <a href="#" :title="data.item.symptom_friendly" @click="showOTDialog(data.item.ot_type, data.item.ot_number)">{{ data.item.ot_number }}</a>
            </template>

            <template #cell(date_created_new)="data">
                <b>{{ data.item.date_created_only }}</b>
            </template>

        </b-table>

    </div>
</template>

<script>

    import axios from 'axios'
    import store from '../store'
    import OrderInfoModal from '../components/OrderInfoModal.vue'

    export default {
    name: "TechOrderPendingList",
    components: {
        OrderInfoModal
    },
    props: {
        operations: Array,
        operation_selected: String,
        zone: Number,
        show_contractors: Boolean,
        show_unscheduled: Boolean
    },
    created: function() {
        this.fetchOrders()
    },
    data() { 
        return {
            orders_offset: 0,
            loading_orders: false,
            orders_error: false,
            order_types: [],
            orders_qty: null,
            orders: null,
            order_request_type: 'pending',
            fields: [
                { key: 'date_created_new', label:'Fecha', tdClass: 'col-1', sortable: true, formatter: 'boldDate' },
                { key: 'node', label:'Nodo', tdClass: 'col-1', sortable: true },
                { key: 'ot_number_custom', label:'Nº OT', tdClass: 'col-2' },
                { key: 'address', label: 'Dirección', tdClass: 'col2' },
                { key: 'symptom_friendly', label: 'Síntoma', tdClass: 'col-2', sortable: true },
                { key: 'notes', label: 'Observaciones', tdClass: 'col-3', sortable: false },
            ]
        }
    },
    methods: {
        boldDate: function(value){ 
            return `<b>${value}</b>`
        },
        dateSortCompare: function(a, b, key) {
            if (key === 'date_created_new') {
                return 1;
            } else {
                return false
            }
        },
        showOTDialog: function(ot_type, ot_number) {
            this.$bvModal.show(ot_type + ot_number)
        },
        fetchOrders: async function() {
            console.log(`Buscando OT de ${this.operation_selected} offset ${this.orders_offset}`)

            const payload = {
                'subsidiary': store.state.subsidiaries,
                'offset': this.orders_offset,
                'request': this.order_request_type,
                'zone': this.zone,
                'show_contractors': this.show_contractors,
                'unscheduled_only': this.show_unscheduled
            }
            if (this.operation_selected != "All")
            {
                payload.subsidiary = this.operation_selected
            }
            if (this.operation_selected == "All")
            {
                payload.ot_type = null
            }
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };
            this.loading_orders = true
            this.orders = null;
            try {
                let resource = await axios.post(this.$store.getters.getApiURL('api_pending_orders_url') , payload, config)
                if (resource.data.error == false)
                {
                    if (payload.subsidiary == this.operation_selected && payload.zone == this.zone && payload.show_contractors == this.show_contractors && payload.unscheduled_only == this.show_unscheduled)
                    {
                        this.order_types = resource.data.data.order_types
                        this.orders = resource.data.data.orders
                        this.orders_qty = resource.data.data.orders_qty
                        this.loading_orders = false
                    }
                    else {
                        console.log(`Espero subsidiary ${this.operation_selected}, pero tengo ${payload.subsidiary}`)
                        return null;
                    }
                } else {
                    this.orders_error = true;
                    this.$swal({ 
                        icon: 'error',
                        title: 'Obtener OT',
                        text: "Error al obtener órdenes: " + resource.data.diagnostic});
                }
            }
            catch (err) {
                console.error(err);
                this.orders_error = true;
                return 0;
            }
        },
    },
    watch: {
        operation_selected: function () {
            this.$nextTick().then(() => {
                this.fetchOrders()
            })
        },
        zone: function() {
            this.$nextTick().then(() => {
                this.fetchOrders()
            })
        },
        show_contractors: function() {
            this.$nextTick().then(() => {
                this.fetchOrders()
            })
        },
        show_unscheduled: function() {
            this.$nextTick().then(() => {
                this.fetchOrders()
            })
        }
    }
    }
</script>

<style scoped>

    #TechOrderPendingList {
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            height: 150px;
        }
    
</style>
