<template>
  <div ref="app" :style="resizer" id="app">
    <div class="flex-col-header d-print-none">
      <router-view name="header" />
    </div>
    <div ref="mainContainer" id="mainContainer" class="flex-col-main">
      <router-view />
    </div>
    <div class="d-print-none">
    <!-- <router-view name="footer" /> -->
    </div>
  </div>
</template>

<script>

export default {
    name: 'App',
    components: {

    },
    data: function() {
      return {
          resizer: { 'min-height': `calc(${document.documentElement.clientHeight}px - 0px) !important`,
                      'max-height': `calc(${document.documentElement.clientHeight}px - 0px) !important` }
      }
    },
    created: function() {
        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize: function()
        {
            //console.log(document.documentElement.clientHeight)
            this.resizer["min-height"] = `calc(${document.documentElement.clientHeight}px - 0px) !important`
            this.resizer["max-height"] = `calc(${document.documentElement.clientHeight}px - 0px) !important`
        }
    },
    mounted: function() {
      //console.log(this.$refs);
    },
    destroyed: function() {
        //window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style lang="scss">
    @import './assets/custom-theme.scss';
    
    @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&family=Roboto+Condensed:ital,wght@0,300;1,400&display=swap');

    html {
      width: 100%;
      height: 100%;
      
      // display: flex;
      // min-height: 100vh;
      // flex-direction: column;
    }

    #mainContainer {
      display: flex;
      flex-grow: 1;
    }

    .hue {
      -webkit-animation: hue 1s infinite;
    }

    @-webkit-keyframes hue {
      0%, 100%  { -webkit-filter: hue-rotate(0deg); }
      50% { -webkit-filter: hue-rotate(180deg); }
    }

    .olas {
      -webkit-animation: olas 3s infinite;
    }

    .boxi {
      -webkit-filter: saturate(0);
    }

    @-webkit-keyframes olas {
      0%, 100%  { background-position-x: 300px;
                  background-position-y: 150px };
      50% { background-position-x: 150px;
            background-position-y: 0px; };
      75% { background-position-x: -150px;
            background-position-y: 150px; };
    }

    .omgwtfbbq {
      -webkit-animation: omgwtfbbq 3s infinite;
    }

    @-webkit-keyframes omgwtfbbq {
      0%, 100%  { -webkit-filter: saturate(0); }
      50% { -webkit-filter: saturate(0); }
    }

    body {
      min-height: 100%;
      display: flex;
      // min-height: 100vh;
      // flex-direction: column;
    }

    #app {
      font-family: 'Lato', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: theme-color("text-primary");
      // min-height: 100%;
      // max-height: 100%;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .flex-col-footer, .flex-col-header {
      flex-shrink: 0;
    }

    .flex-col-main {
      flex-grow: 1;
    }

    .flex-col-footer {
      min-height: 50px;
      background-color: rgba(darken(theme-color("primary"), 10%), 0.7);
    }

    @media print {
        .flex-col-main {
          height: 100% !important;
          font-family: 'Courier New', Courier, monospace !important;
          color: #000000 !important;
      }
    }



</style>
