<template>
    <div class="loader">
        <b-spinner style="width: 2rem; height: 2rem" class="mb-3"></b-spinner>
        <p>{{ text }}</p>
    </div>
</template>

<script>
    export default {
        name: "LoaderWithText",
        props: [ "text" ],
    }
</script>

<style scoped>
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
