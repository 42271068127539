<template>
    <div id="OrderInfo">
        <div v-if="!order">
            <h4>Cargando información de la OT {{ $route.params.ot_number }}...</h4>
            <h3>{{ axios_error }}</h3>
        </div>
        <div v-if="order">
            <b-row>
                <b-col cols="12" md="6">
                    <!--<p><b>OT: </b>{{ order.ot_number }}</p>-->
                    <p><b>Cliente: </b>{{ order.client_name }} ({{ order.client_id }})</p>
                    <p><b>Domicilio: </b>{{ order.client_address }}</p>
                    <p><b>Nodo: </b>{{ order.node_name }} <span v-if="order.tag"> | <b>Tag: </b>{{ order.tag }} </span></p>

                    <!-- <p><b>Estado: </b>{{ order.status_friendly }}</p> -->
                    <p><b>Operación: </b>{{ order.subsidiary_friendly }}</p>
                    <p><b>Teléfono: </b>{{ order.telephone }}</p>
                    <p v-if="order.related_orders != null && order.related_orders.length > 1"><b>OT relacionadas: </b>{{ order.related_orders.map(a => a.ot_number).join() }}</p>
                </b-col>
                <b-col cols="12" md="6">
                    <!-- <p><b>Fecha de creación: </b>{{ order.date_created }}</p> -->
                    <!-- <p v-if="order.status_id > 1"><b>Fecha de asignación: </b>{{ order.date_assigned }}</p>
                    <p v-if="order.status_id > 2"><b>Fecha de inicio: </b>{{ order.date_started }}</p>
                    <p v-if="order.status_id > 2"><b>Fecha de finalización: </b>{{ order.date_finished }}</p> -->
                </b-col>    
            </b-row>
            <hr>
            <h4>Diagnóstico:</h4>
            <b-row>
                <b-col cols="12" md="12">
                    <p><b>Síntoma: </b>{{ order.symptom_text }}</p>
                </b-col>
                <!-- <b-col cols="12" md="6">
                    <h4><b>Tipo: {{ order.ot_type_friendly }}</b></h4><h4><b>Nodo: {{ order.node_name }}</b></h4>
                </b-col> -->
            </b-row>
            <p v-if="order.status_id > 2"><b>Reparación: </b>{{ order.repair_text }}</p>
            <p><b>Información adicional: </b></p><br/>
            &bull;<span v-html="order_info_beauty"></span>
            <hr>
            <div v-if="order.services.length > 0">
                <h4>Servicios: </h4>
                <b-table striped hover :borderless="true" :items="order.services" :fields="services_fields" :small="true"></b-table>
            </div>
            <hr>
            <div v-if="order.client_devices.length > 0">
                <h4>Dispositivos: </h4>
                <b-table striped hover :borderless="true" :items="order.client_devices" :fields="devices_fields" :small="true"></b-table>
            </div>
            <p></p>
        </div>
    </div>
</template>

<script>
import store from '../store'
import axios from 'axios'

export default {
    name: "OrderInfo",
    store: store,
    props: ['ot_type', 'ot_number', 'order_data', 'autoload'],
    data() {
        return {
            order: null,
            order_info_beauty: "",
            axios_error: "",
            services_fields: [
                {
                    'key': 'service_qty',
                    'label': 'Cantidad',
                    'sortable': false
                },
                {
                    'key': 'service_name',
                    'label': 'Servicio',
                    'sortable': true
                }],
            devices_fields: [
                {
                    'key': 'type',
                    'label': 'Tipo',
                    'sortable': true
                },
                {
                    'key': 'mac',
                    'label': 'MAC Address',
                    'sortable': true
                },
                {
                    'key': 'serial',
                    'label': 'S/N',
                    'sortable': true
                }]
        }
    },
    created: function()
    {
        if (this.autoload == true || this.autoload === undefined && (this.ot_type != undefined || this.ot_number != undefined))
        {
            console.log("Creado con Autoload")
            this.loadOT()   
        } else if ((this.autoload === true || this.autoload === undefined) && (this.ot_type === undefined || this.ot_number === undefined)) {
            this.axios_error = "Componente mal inicializado: Faltan parámetros de incialización..."
        } else {
            this.order = this.order_data
            this.order_info_beauty = this.order.detail.replace("\r\n", "<br>").replace("\r", "<br>").replace("------------------------------", "<br>&bull;")
            }

    },
    methods: {
        loadOT: function() {
            //console.log("Cargo data para " + this.ot_type + this.ot_number)
            const data = {
                'ot_type': this.ot_type,
                'ot_number': this.ot_number
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_order_url') , data, config)
            .then(response => {
                if (response.data.error == 200)
                {
                    this.order = response.data.data
                    this.order_info_beauty = this.order.detail.replace("\r\n", "<br>").replace("\r", "<br>").replace("------------------------------", "&bull;")
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(() => {
                this.axios_error = "No se pudo obtener respuesta del servidor (Error 400)"
            })
        }
    },
    watch: {
        ot_number: function(val) {
            console.log("Watched Modal")
            this.order = null
            this.loadOT(val)
        }
    }
}
</script>

<style>

</style>
