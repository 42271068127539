<template>
    <div id="report">
        <b-container class="mr-1 ml-1" fluid>
            <b-card title="Parámetros" sub-title="Reporte mensual de visitas">
                <b-row cols="12">
                    <b-col v-if="subsidiaries_list" lg="12">
                        <b-row>
                            <b-col xl="6" lg="6" sm="12">
                                <b-row>
                                    <b-col xl="4" lg="4" sm="4">
                                        <h4 class="component-label">Período:</h4>
                                    </b-col>
                                    <b-col xl="8" lg="8" sm="8">
                                        <p class="month-picker-container">
                                            <MonthPickerInput lang="es" v-model="monthpicker" @change="updateMonth"></MonthPickerInput>
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-row>
                                    <b-col xl="4" lg="4" sm="4">
                                        <h4 class="component-label">Operación:</h4>
                                    </b-col>
                                    <b-col xl="8" lg="8" sm="8">
                                        <b-form-select
                                        id="input-3"
                                        v-model="form.subsidiary"
                                        :options="subsidiaries_list"
                                        value-field="subsidiary_id"
                                        text-field="name"
                                        required
                                        size="lg"
                                        ></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-btn variant="primary" @click="updateReport" class="ml-3">Actualizar</b-btn>
                </b-row>
            </b-card>
            <b-row v-if="loading" cols="12">
                <b-col class="mt-2 mb-2" lg="12">
                    <b-overlay :show="loading" rounded="sm">
                        <b-skeleton-img width="100%"></b-skeleton-img>
                    </b-overlay>
                </b-col>
            </b-row>
            <b-row v-if="report" cols="12">
                <b-col lg="12" class="align-items-center justify-content-center text-center">
                    <img class="report-graphic" :src="report[0].data">
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import axios from 'axios';
    import store from '../../store';

    //import { MonthPicker } from 'vue-month-picker'
    import { MonthPickerInput } from 'vue-month-picker'

    import { getUserProfile } from "../../common"

    export default {
    name: "MonthlyVisitReport",
    store: store,
    components: {
        //MonthPicker,
        MonthPickerInput
    },
    data: function() { 
        return {
            loading: false,
            subsidiaries_list: null,
            monthpicker: null,
            report: null,
            form: {
                month: null,
                subsidiary: null
            }
        }
    },
    created: async function() {
        {
            let tempPerms = await getUserProfile(store.state.username, store.state.token);
            this.subsidiaries_list = tempPerms.subsidiaries;
            if (this.subsidiaries_list.length > 0)
            {
                this.form.subsidiary = this.subsidiaries_list[0].subsidiary_id;
            }
        }
    },
    methods: {
        updateMonth: function(event) {
            this.form.month = event.from
        },
        updateReport: function() {

            this.loading = true;
            this.report = null;

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            axios.post(this.$store.getters.getApiURL('api_report_monthly') , this.form, config)
            .then(response => {
                if (response.data.error == false)
                {
                    this.report = response.data.data
                    this.loading = false;
                } else {
                    this.$swal({ 
                        icon: 'warning',
                        title: 'Advertencia',
                        text: response.data.diagnostic });
                    this.loading = false;
                }
            })
            .catch(error => {
                this.errormsg = error
                this.loading = false;
            });
        }
    }
    }
</script>

<style>
    #report {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }

    .month-picker-input {
        font-size: 1.25rem !important;
        padding: 9px !important;
        padding-left: 20px !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        color: #495057;
    }

    .month-picker-input-container input {
        width: 100% !important;
    }

    .month-picker-input-container {
        width: 100% !important;
    }

    .month-picker-container {
        z-index: 999 !important;
        position: relative;
    }

    .report-graphic {
        max-width: 100%;
    }

    .component-label {
        margin-top: 10px;
    }
    
</style>
