<template>
    <div id="ClientPendingOrders" class="mt-4">
        <div v-if="loaded">
            <div v-if="error">
                <b-alert variant="danger"><strong>Error:</strong> {{ errorMessage }}</b-alert>
            </div>
            <div v-else>
                <div v-if="pendingOrders.length > 0">
                    <h4>OTs pendientes:</h4>
                    <b-table striped hover :borderless="true" :items="pendingOrders" :fields="tableFields" :small="true">
                        <template #cell(ot_number)="data">
                            <a href="#" @click="emitSearch(data.item.ot_number, data.item.ot_type)">{{ data.item.ot_number }}</a>
                        </template>
                    </b-table>
                </div>
                <div v-else>
                    <h5>Este cliente no tiene OTs pendientes</h5>
                </div>
            </div>
        </div>
        <LoaderWithText v-else text="Cargando OTs pendientes"/>
    </div>
</template>

<script>
import axios from 'axios'
import bus from '../bus'
import LoaderWithText from '../components/LoaderWithText.vue'
import store from '../store'

export default {
    name: 'ClientPendingOrders',
    props: ['client_id'],
    components: {
        LoaderWithText,
    },
    data: function() {
        return {
            error: false,
            loaded: false,
            errorMessage: '',
            pendingOrders: [],
            tableFields: [
                { 
                    'key': 'ot_number',
                    'label': 'OT',
                },
                {
                    'key': 'ot_type_friendly',
                    'label': 'Tipo',
                },
            ],
        }
    },
    methods: {
        emitSearch: function(ot_number, ot_type) {
            bus.$emit('searchOT', {ot_type: ot_type, ot_number: ot_number});
        },
    },
    created: function() {
        const payload = {
            'client_id': this.client_id,
        };

        const config = {
            'headers': { Authorization: `Bearer ${store.state.token}` }
        };

        const url = this.$store.getters.getApiURL('api_client_pending_orders');

        axios.post(url, payload, config)
            .then((response) => {
                if (response.data.code == 200) {
                    this.pendingOrders = response.data.data;
                }
            })
            .catch(() => {
                this.error = true;
                this.errorMessage = 'No se pudieron obtener las OTs pendientes de este cliente';
            })
            .finally(() => {
                setTimeout(() => {this.loaded = true}, 200);
            });
    },
}
</script>

<style scoped>
</style>
