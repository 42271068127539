import Vue from "vue";
import Router from "vue-router";
import store from "./store"
import axios from 'axios'

// Vistas que usa Router
import Header from "./views/Header"
import Footer from "./views/Footer"
import Index from "./views/Index"
import IndexJefeTec from "./views/IndexJefeTec"
import IndexTecnico from "./views/IndexTecnico"
import IndexCalendario from "./views/IndexCalendario"
import ExternalVisitView from "./views/ExternalVisitView"
import VisitForm from "./views/VisitForm"
import VisitView from "./views/VisitView"
import VisitStripeEditor from "./views/VisitStripeEditor"
import VisitStripeDetailView from "./views/VisitStripeDetailView"
import SetupView from "./views/SetupView"
import PrioritiesView from "./views/PrioritiesView"
import ZonesEditView from "./views/ZonesEditView"
import Login from "./views/Login"
import Profile from "./views/Profile"

import ReportsView from "./views/ReportsView"
import MonthlyVisitReport from "./views/reports/MonthlyVisitReport"
import CancellationReasonReport from "./views/reports/CancellationReasonReport"
import LateScheduleReport from "./views/reports/LateScheduleReport"
import LateFinishReport from "./views/reports/LateFinishReport"
import MonthlyTechnicianReport from "./views/reports/MonthlyTechnicianReport"
import RescheduleReport from "./views/reports/RescheduleReport"
import VisitSegmentsReport from "./views/reports/VisitSegmentsReport"
import MonthlyDerivationsReport from "./views/reports/MonthlyDerivationsReport"
import MyTechsReport from "./views/reports/MyTechsReport"
import UnclosedReport from "./views/reports/UnclosedReport.vue";
import OnTheWayReport from "./views/reports/OnTheWayReport.vue";
import OnTheWayGlobalReport from "./views/reports/OnTheWayGlobalReport.vue";

Vue.use(Router);

const routeProfile = (to, from, next) => {
  store.commit('initialize')
  if (store.state.token != "undefined") {
    // Verifico que el token siga siendo válido
    const udata = {
      'username': store.state.username,
      'token': store.state.token
    }
    const config = {
      'headers': { Authorization: `Bearer ${store.state.token}` }
    };
    axios.post(store.getters.getApiURL('api_profile_url'), udata, config)
    .then(response => {
      if (response.data.code == 200)
        {
          next({ 'name' : response.data.data.defaultView })
        }
    })
    .catch( () => {
        next('login')
        return
    });
  } else {
    next('login')
    return
  }
}

const ifAuthenticated = (to, from, next) => {
  store.commit('initialize')
  if (store.state.token != "undefined") {
    // Verifico que el token siga siendo válido
    axios.post(store.state.api_base_url, null, {
      headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${store.state.token}`
      },
    })
    .catch(error => {
      if (error.response.status === 400) {
        store.commit('clear')
        next('/login')
        return
      }
      console.log(error)
    });
    // Sigo en el caso que haya respuesta desde el servidor
    next()
    return
  } else {
    next('/login')
    return
  }
}

const logout = (to, from, next) => {
  store.commit('clear')
  next()
  return
}

export default new Router({
    //Importante: Los menús del header, se basan en el name que se define acá.
    routes: [
      {
        path: "/",
        name: "index",
        beforeEnter: routeProfile,
        components: { default: Index, header: null, footer: null },
        props: {
          header: { colorOnScroll: 100,
                    title: "Agenda técnica" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/jefetec",
        name: "index_jefetec",
        beforeEnter: ifAuthenticated,
        components: { default: IndexJefeTec, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Agenda técnica" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/calendario",
        name: "index_calendario",
        beforeEnter: ifAuthenticated,
        components: { default: IndexCalendario, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Agenda técnica" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/tecnico",
        name: "index_tecnico",
        beforeEnter: ifAuthenticated,
        components: { default: IndexTecnico, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Agenda técnica - Visitas" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/subsidiaries/detail/:date/:timestripe",
        name: "timestripe_details",
        beforeEnter: ifAuthenticated,
        components: { default: VisitStripeDetailView, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Detalle de la franja horaria" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/subsidiaries/edit",
        name: "edit_subsidiaries",
        beforeEnter: ifAuthenticated,
        components: { default: VisitStripeEditor, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Editar Franjas",
                    parent: "setup" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/priorities/edit",
        name: "edit_priorities",
        beforeEnter: ifAuthenticated,
        components: { default: PrioritiesView, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Editar Prioridades",
                    parent: "setup" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/zones/edit",
        name: "edit_zones",
        beforeEnter: ifAuthenticated,
        components: { default: ZonesEditView, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Editar Zonas",
                    parent: "setup" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/tecnico/:nombre",
        name: "index_tecnico_params",
        beforeEnter: ifAuthenticated,
        components: { default: IndexTecnico, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Demo técnico con parámetros" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/visit/:type/:ot_type/:ot_number/:visit_id",
        name: "new_visit",
        beforeEnter: ifAuthenticated,
        components: { default: VisitForm, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Crear visita" },
          default: {
                    // Esto hace override de lo que le mande al push
                    type: "new",
                    visit_id: undefined
          },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/visit/view/:visit_id/:mode",
        name: "get_visit",
        beforeEnter: ifAuthenticated,
        components: { default: VisitView, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Detalle de visita" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/visit/external/view/:token",
        name: "visit_external_view",
        components: { default: ExternalVisitView, header: null, footer: null },
        props: {
          header: { colorOnScroll: 100,
                    title: "Ver visita técnica - Link externo" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/profile",
        name: "profile",
        beforeEnter: ifAuthenticated,
        components: { default: Profile, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Perfil de usuario" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/setup",
        name: "setup",
        beforeEnter: ifAuthenticated,
        components: { default: SetupView, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Configuración" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports",
        name: "reports",
        beforeEnter: ifAuthenticated,
        components: { default: ReportsView, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reportes" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports/monthly_report",
        name: "reports_visits_month",
        beforeEnter: ifAuthenticated,
        components: { default: MonthlyVisitReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte mensual de visitas",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports/cancellation_reason_report",
        name: "reports_cancellation_reason",
        beforeEnter: ifAuthenticated,
        components: { default: CancellationReasonReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de motivos de cancelación",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports/late_schedule_report",
        name: "reports_late_schedule",
        beforeEnter: ifAuthenticated,
        components: { default: LateScheduleReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de visitas agendadas fuera de su franja horaria",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports/late_finish_report",
        name: "reports_late_finish",
        beforeEnter: ifAuthenticated,
        components: { default: LateFinishReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de visitas agendadas fuera de su franja horaria",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports/tech_report",
        name: "reports_tech",
        beforeEnter: ifAuthenticated,
        components: { default: MonthlyTechnicianReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte mensual de técnicos",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports/reschedule_report",
        name: "reports_reschedule",
        beforeEnter: ifAuthenticated,
        components: { default: RescheduleReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de visitas reagendadas",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/reports/visit_segments",
        name: "reports_visit_segments",
        beforeEnter: ifAuthenticated,
        components: { default: VisitSegmentsReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Segmentos de visitas",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      { 
        path: "/reports/derivations_report",
        name: "reports_derivations",
        beforeEnter: ifAuthenticated,
        components: { default: MonthlyDerivationsReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte mensual de derivaciones",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      { 
        path: "/reports/my_techs",
        name: "reports_my_techs",
        beforeEnter: ifAuthenticated,
        components: { default: MyTechsReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de mis técnicos",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      { 
        path: "/reports/unclosed",
        name: "reports_unclosed",
        beforeEnter: ifAuthenticated,
        components: { default: UnclosedReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de visitas sin cerrar",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      { 
        path: "/reports/on_the_way",
        name: "reports_on_the_way",
        beforeEnter: ifAuthenticated,
        components: { default: OnTheWayReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de visitas en camino",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      { 
        path: "/reports/on_the_way_global",
        name: "reports_on_the_way_global",
        beforeEnter: ifAuthenticated,
        components: { default: OnTheWayGlobalReport, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 100,
                    title: "Reporte de visitas en camino por operación",
                    parent: "reports" },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/login",
        name: "login",
        components: { default: Login, header: null, footer: null },
        props: {
          header: { colorOnScroll: 400 },
          footer: { backgroundColor: "black" }
        }
      },
      {
        path: "/logout",
        name: "logout",
        beforeEnter: logout,
        components: { default: null, header: Header, footer: Footer },
        props: {
          header: { colorOnScroll: 400 },
          footer: { backgroundColor: "black" }
        }
      }
    ]
});
