<template>
    <div>
        <b-input-group class="d-flex justify-content-end mt-2 mt-md-0">
            <b-input-group-prepend @click="substractOne(collection, filteredItemCode)">
                <b-button variant="warning"><b-icon-dash></b-icon-dash></b-button>
            </b-input-group-prepend>
            
            <b-form-input type="text" :number="true" min=0 class="text-center number-input" :formatter="formatter" @blur="validate(collection, filteredItemCode)" v-model="collection.find(item => item.code === filteredItemCode).amount"></b-form-input>

            <b-input-group-append @click="addOne(collection, filteredItemCode)">
                <b-button variant="info"><b-icon-plus></b-icon-plus></b-button>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>

<script>
export default {
    name: "UpDownInput",
    props: ["collection", "filteredItemCode"],
    data () {
        return {
        }
    },
    methods: {
        substractOne: function(collection, filteredItemCode) {
            let amount = collection.find(item => item.code === filteredItemCode).amount
            if(amount > 0) {
                collection.find(item => item.code === filteredItemCode).amount--
            }
        },

        addOne: function(collection, filteredItemCode) {
            collection.find(item => item.code === filteredItemCode).amount++
        },

        formatter: function(value) {
            if(isNaN(value)) return "0"
            if(value.charAt(0) === " ") return "0"
            if(value < 0) return "0"
            if(value.length > 1 && value.charAt(0) === "0") return "0"
            if(value.length > 7) return value.slice(0, -1)
            return value
        },

        validate(collection, filteredItemCode) {
            if(collection.find(item => item.code === filteredItemCode).amount === "") {
                collection.find(item => item.code === filteredItemCode).amount = 0
            }
        },
    },
}

</script>

<style scoped>
.number-input {
  max-width: 90px;
}

@media (max-width: 600px) {
  .number-input {
    max-width: none;
  }
}
</style>
