import axios from 'axios'
import store from './store'

export async function getUserProfile(username = store.state.username, token = store.state.token) {
        const udata = {
            'username': username,
            'token': token
        }
        const config = {
            'headers': { Authorization: `Bearer ${token}` }
        };
        let user_response = await axios.post(store.getters.getApiURL('api_profile_url') , udata, config)
        .then(response => {
            if (response.data.code == 200)
            {
                //console.log(response.data.data.email)
                return response.data.data;
            } else {
                return null;
            }
        })
        .catch(error => {
            console.log(error)
        });
        return user_response;
    }

export function triggerSegmentUpdate(new_visit_id)
{
    const data = {
        'visit_id': new_visit_id,
    };
    const config = {
        'headers': { Authorization: `Bearer ${store.state.token}` }
    };

    axios.post(store.getters.getApiURL('api_report_segment') , data, config)
}

export function showErrorMsg(description, context = this, title = "Error", action = "OK")
{
    context.$swal({ 
        icon: 'error',
        title: title,
        text: description}).then(() => {
            if (action == "main") {
               getUserProfile().then((result) => {
                context.$router.push({ 'name': result.defaultView })
               }) 
            }
        });
}

export async function hasPerm(permission)
{
    var tempPerms = null;

    // if (store.state.permissions == "undefined")
    // {
    //     tempPerms = await getUserProfile(store.state.username, store.state.token);
    // } else {
    //     tempPerms = store.state.permissions.split(",");
    //     console.log("Uso permisos en store")
    // }
    tempPerms = await getUserProfile(store.state.username, store.state.token);

    if (tempPerms != null)
    {
        var userPerms = tempPerms.permissions;

        if (userPerms.indexOf(permission) >= 0)
            {
                return true;
            }
            return false;
    } else {
        return undefined;
    }
}

export class SessionHandler {
    constructor(view) {
        this.view = view;
    }

    setKey(key, data) {
        store.commit('setSessionState', { view: this.view, key: key, data: data });
        return true;
    }

    getKey(key, default_val) {
        var session = store.getters.getSessionState(this.view);
        if (session != undefined)
        {
            if (Object.keys(session).indexOf(key) >= 0)
            {
                return session[key];
            } else {
                return default_val;
            }
        }
        else return default_val;
    }

    // setObject(key, data) {
    //     store.commit()
    // }
}

