<template>
    <div>   
        <b-modal id="calendar-help" size="lg" title="Colores: Calendario" ok-only>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit normal">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Pendiente</p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Visita pendiente con técnico asignado</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit normal">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Pendiente<font-awesome-icon title="Sin asignar técnico" icon="user-slash" class="ml-1" /></p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Visita pendiente (Sin asignar técnico)</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit free">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Pendiente<font-awesome-icon title="Sin asignar técnico" style="color: #C775A8A0" icon="user-slash" class="ml-1" /></p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Visita pendiente (Sin asignar técnico: auto-desasignada)</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit ontheway">
                        <p><strong>OT 00100144087</strong></p>
                        <p>En camino</p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">En camino</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit delayed">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Cierre demorado</p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Cierre demorado</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit delayed">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Cierre demorado<font-awesome-icon title="Iba en camino" icon="exclamation-triangle" class="ml-1" /></p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Cierre demorado (iba en camino)</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit finished">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Concluida</p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Visita finalizada</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit finished">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Concluida</p>
                        <p><font-awesome-icon title="Derivada" icon="arrow-right" class="mr-2" /><strong>xxxx</strong></p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Visita finalizada derivada</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit cancelled">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Responsabilidad de xxxx</p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Visita cancelada</p>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="visit cancelled">
                        <p><strong>OT 00100144087</strong></p>
                        <p>Responsabilidad de xxxx<font-awesome-icon title="Reprogramada" icon="calendar-check" class="ml-1" /></p>
                    </div>
                </b-col>
                <b-col align-self="center" cols="7">
                    <p class="m-0">Visita cancelada reprogramada</p>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    export default {
    name: "CalendarHelpModal"
    }
</script>

<style scoped>
    .visit {
        padding: 8px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-family: 'Lato', sans-serif;
    }

    .visit > p {
        margin: 0;
    }

    .normal {
        background-color: #AAAAAAA0;
    }

    .finished {
        background-color: #00A679A0;
    }

    .cancelled {
        background-color: #E66964A0;
    }
    .free {
        background-color: #AAAAAAA0;
    }

    .inprogress {
        background-color: #F9F871A0;
    }

    .ontheway {
        background-color: #F1BD1FA0;
    }

    .delayed {
        background-color: #AD6DD8A0;
    }

</style>

