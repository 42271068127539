<template>
    <div>
    <b-navbar toggleable="lg" type="dark" class="header">
        <a class="btn btn-primary mr-2 align-left" v-if="$route.name != defaultView" @click="goBackAction()"><font-awesome-icon icon="arrow-left"/></a>
        <a class="btn btn-primary mr-2 align-left" v-if="parent" @click="$router.push({name: defaultView})"><font-awesome-icon icon="house-user"/></a>
        <b-navbar-brand>{{ title }}</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" v-model="showCollapse" is-nav>
        <b-navbar-nav>
            <div v-for="item in this.navigation[$route.name]" :key="item.name">
                <b-nav-item @click="sendSignal(item.type, item.emits)" :class="item.class"><b>{{ item.name }}</b></b-nav-item>
            </div>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
            <!-- Caja de texto de búsqueda -->
            <b-nav-form v-if="this.$route.name === 'index_jefetec' || this.$route.name === 'index_calendario'" @submit="emitSearch">
            <b-form-select size="sm" class="mr-sm-2" v-model="search.search_type" :options="searchTypes"></b-form-select>
            <b-form-input size="sm" class="mr-sm-2" :placeholder="searchBarPlaceholder" v-model="search.search_number"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0 mr-2" type="submit">Buscar</b-button>
            </b-nav-form>

            <div class="profilecontainer">
                <img class="profilepic" :src="buildProfilePic()">
            </div>
            <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
                <template #button-content>
                    <em>{{ username }}</em>
                </template>
                <b-dropdown-item :href="this.$router.resolve({name: 'profile'}).href">Perfil</b-dropdown-item>
                <b-dropdown-item v-if="techId && this.$route.name !== 'index_jefetec' && permissions.includes('can_switch_views')" :href="this.$router.resolve({name: 'index_jefetec'}).href">Vista Dispatcher</b-dropdown-item>
                <b-dropdown-item v-if="techId && this.$route.name !== 'index_tecnico' && permissions.includes('can_switch_views')" :href="this.$router.resolve({name: 'index_tecnico'}).href">Vista Técnico</b-dropdown-item>
                <b-dropdown-item @click="cerrarSesion">Cerrar Sesión</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</div>
</template>

<script>
    import store from '../store'
    //import axios from 'axios'
    import bus from '../bus'
    import { getUserProfile } from "../common"

    export default {
    name: "Header",
    props: ['title', 'parent'],
    store: store,
    data() {
        return {
            username: store.state.firstName,
            profilePic: '',
            defaultView: null,
            permissions: [],
            techId: null,
            searchBarPlaceholder: "Buscar OT",
            search: {
                search_type: 1,
                search_number: null,
                ot_total: {
                    ot_type: "2",
                    ot_number: null,
                },
                client_total: {
                    client_number: null,
                },
            },
            searchTypes: [
                {
                    text: "OT",
                    value: 1,
                },
                {
                    text: "Cliente",
                    value: 2,
                },
            ],
            showCollapse: false,
            navigation: {
                'index_jefetec': [
                    {
                        type: 'emit',
                        name: 'Ver Listado',
                        emits: 'setOrderListVisibility',
                        class: 'd-block d-sm-block d-md-none'
                    },
                    {
                        type: 'emit',
                        name: 'Ver Calendario',
                        emits: 'setCalendarVisibility',
                        class: 'd-block d-sm-block d-md-none'
                    },
                    {
                        type: 'push',
                        name: 'Reportes',
                        emits: 'reports',
                        class: 'd-block'
                    },
                    {
                        type: 'push',
                        name: 'Configuración',
                        emits: 'setup',
                        class: 'd-block'
                    }
                ]
            }
        }
    },
    methods: {
        goBackAction: function() {
            if (this.parent) 
            {
                this.$router.push({ 'name' : this.parent })
            } else {
                this.$router.push({ 'name' : this.defaultView })
            }
            //:href="parent ? this.$router.resolve({name: this.parent}).href : this.$router.resolve({name: this.defaultView}).href"
            this.showCollapse = false;
        },
        cerrarSesion: function() {
            this.$store.commit("clear")
            this.$router.push('/login')
        },
        emitSearch: function(event) {
            event.preventDefault()
            let searchType = this.search.search_type
            if(searchType === 1) {
                this.search.ot_total.ot_number = this.search.search_number.trim()
                bus.$emit('searchOT', this.search.ot_total);
            }else if(searchType === 2){
                this.search.client_total.client_number = this.search.search_number.trim()
                bus.$emit('searchClient', this.search.client_total);
            }
        },
        sendSignal: function(type, signal) {
            if (type == 'emit')
            {
                bus.$emit(signal)
            }
            if (type == 'push')
            {
                this.$router.push({'name': signal})
            }
        },
        buildProfilePic: function() {
            if(this.profilePic === "/static/img/default_profile_pic.png") {
                return `${this.$store.state.api_base_url}${this.profilePic}`;
            } else {
                return `data:image/png;base64,${this.profilePic}`;
            }
        },
    },
    created: function() {
        // Obtengo la imágen de perfil y la defaultView para el router
        getUserProfile(store.state.username, store.state.token).then( response => {
            this.profilePic = response['profilePic'];
            this.defaultView = response.defaultView;
            this.permissions = response.permissions;
            this.techId = response.tech_id;
        })
    },
    watch: {
        "search.search_type": function(newVal) {
            if(newVal === 1) {
                this.searchBarPlaceholder = "Buscar OT"
            }else if(newVal === 2){
                this.searchBarPlaceholder = "Buscar Cliente"
            }
            this.search.search_number = null
        }
    }
    }

</script>

<style lang="scss" scoped>
    @import '../assets/custom-theme.scss';

    .profilepic {
        height: 32px;
    }

    .header {
        background-color: theme-color("primary");
    }

    .profilecontainer {
        overflow:hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
    }

    .navbar-brand {
        font-weight: 600;
        font-size: 1.25em;
        color: #ffffff;
    }
</style>

