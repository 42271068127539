<template>
    <div id="tecvisitinfo">
            <div v-if="visit && order">

                 <b-card class="mt-4" :border-variant="onTheWay ? 'warning' : getVariant(visit.status)" :header-bg-variant="onTheWay ? 'warning' : getVariant(visit.status)"> 

                    <template #header>
                        <div class="header">
                            <h4 class="card-title mt-2">{{visit.date_dispatch}} | Turno: {{visit.timestripe_friendly}}</h4>
                            <span v-if="onTheWay" class="pill on-the-way-pill">En camino</span>
                            <span v-if="visit.status === 'Cierre demorado'" class="pill delayed-pill">Cierre demorado {{ visit.delayed_while_on_the_way ? '(iba en camino)' : '' }}</span>
                        </div>
                    </template>
                    <OrderInfoModal :ot_type="visit.ot_type" :ot_number="visit.ot_number"></OrderInfoModal>
                    <div class="visitcancelled" v-if="visit.cancelled"><h1>Visita Cancelada</h1></div>
                    <div class="visitfinished" v-if="visit.finished"><h1>Visita Finalizada</h1></div>
                    <p v-if="visit.node_name"><b>Nodo: </b>{{ visit.node_name }} <span v-if="visit.tag"><b> | Tag: </b> {{ visit.tag }}</span> <span v-if="visit.zone_name"> ({{ visit.zone_name }})</span></p>
                    <p><b>Dirección:</b> {{ order.client_address }}</p>
                    <p><b>Orden de trabajo:</b> <a href="#" @click="showOrderModal">{{ visit.ot_number }}</a> {{ visit.subsidiary_friendly }} ({{ visit.ot_type_friendly }})</p>
                    <p><b>Observaciones:</b></p>
                    <textarea class="form-control" readonly="" style="margin-bottom: 1rem; resize: none;" v-model="visit_tech_comments" />
                    <div v-if="tickets && tickets.length" v-b-toggle="'ticket' + order.ot_number">
                        <p><b><a :href="'#visit' + visit.id">Ver tickets del cliente</a></b></p>
                    </div>

                    <div v-if="tickets">
                        <!--<p v-if="tickets.length == 0">No posee tickets</p>-->
                        <b-collapse v-if="tickets" :visible="has_priority_tickets"  :id="'ticket' + order.ot_number" class="mt-2" >
                            <!--<p>Tickets del cliente:</p>-->
                            <div v-for="ticket in tickets" :key="ticket.id" :class="ticket.ot_number == order.ot_number? 'ticket ticketisot': 'ticket'">
                                <a href="#" @click="showTtsDialog(order.ot_number, ticket)">
                                <TtsInfoModal :ot_number="order.ot_number" :ticket="ticket"></TtsInfoModal>
                                <p class="tickettext" :title=ticket.body>{{ ticket.title }}<span v-if="ticket.ot_number == order.ot_number"> (De esta OT)</span></p>
                                <p class="ticketdate" :title=ticket.body>{{ ticket.timestamp }}</p>
                                </a>
                                <div v-if="ticket.has_priority_notes" id="pulsecontainer">
                                    <div class="dot"></div>
                                    <div class="pulse"></div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>

                </b-card>
            </div>
    </div>
</template>

<script>

    import store from "../store"
    import axios from "axios"
    import bus from "../bus"
    import OrderInfoModal from "./OrderInfoModal.vue"
    import TtsInfoModal from "./TtsInfoModal.vue"

    //import { showErrorMsg } from "../common.js"

    export default {
    name: "TecVisitInfo",
    props: ['visit_id', 'edit', 'onTheWay'],
    components: {
        OrderInfoModal,
        TtsInfoModal,
    },
    data() {
        return {
            visit: null,
            order: null,
            tickets: null,
            has_priority_tickets: false,
            visit_tech_comments: null,
            selected_ticket: null,
            form: {
                tech_comments: null,
                tech_id: null,
                tech_name: ''
            },
            technicians_list: null,
        }
    },
    mounted() {
        bus.$on('updateVisit', () => {
            this.updateVisit()
        })
    },
    beforeDestroy() {
        bus.$off('updateVisit')
    },
    created: function() {
        this.getVisit()
        this.form.visit_id = this.visit_id
    },
    methods: {
        showTtsDialog: function(ot_number, ticket) {
            this.selected_ticket = ticket
            this.$bvModal.show(ot_number + ticket.id)
        },
        showOrderModal: function()
        {
            this.$bvModal.show(this.visit.ot_type + this.visit.ot_number)
        },
        editable: function(data) {
            if (this.visit.cancelled || this.visit.finished) return false;
            if (this.edit.includes(data)) return true; else return false;
        },
        onSubmit: function() {
            
        },
        onReset: function() {

        },
        loadTickets: function() {
            const payload = {
                'client_id': this.visit.client_id,
            }
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_tts_ws_query_tickets'), payload, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    if (response.data.data.detail.priority_tickets_inside == true)
                    {
                        this.has_priority_tickets = true
                    }
                    if (typeof response.data.data.detail.tickets != "undefined")
                    {
                        this.tickets = response.data.data.detail.tickets
                    }
                }
                this.loading_tickets = false
            })
            .catch(error => {
                console.log(error)
                this.tts_error = true
                this.loading_tickets = false
            });
        },
        getVisit: function()
        {
            console.log("Cargo visita " + this.visit_id)
            const data = {
                'visit_id': this.visit_id,
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_visit_info_id') , data, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.visit = response.data.data
                    this.visit_tech_comments = this.visit.tech_comments.join("\n")
                    this.form.tech_comments = this.visit.tech_comments
                    this.loadOT()
                    this.loadTickets()

                    // Si la visita no está finalizada o cancelada, muestro los botones
                    if (this.visit.cancelled == false && this.visit.finished == false)
                    {
                        this.$emit("set-buttons", true)
                    }
                    // Lleno la lista de técnicos (Si es editable)
                    if (this.edit.includes("tech") && this.visit.finished == false)
                    {
                        // Convierto el entero a string con ceros a la izquierda. 
                        this.form.tech_id = this.visit.assigned_id.toString().padStart(6, '0')
                    }
                }
                else {
                    console.log(response.data)
                }
            })
            .catch(error => {
                this.axios_error = error
            })
        },
        getVariant(status) {
            if (status === "Cierre demorado") {
                return "purple";
            } else if (status === "En camino") {
                return "warning";
            } else {
                return "";
            }
        },
        loadOT: function() {
            //console.log("Cargo data para " + this.ot_type + this.ot_number)
            const data = {
                'ot_type': this.visit.ot_type,
                'ot_number': this.visit.ot_number
            };
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            this.axios_error ="";
            axios.post(this.$store.getters.getApiURL('api_order_url') , data, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.order = response.data.data
                }
                else {
                    this.axios_error = response.data.diagnostic
                }
            })
            .catch(() => {
                this.axios_error = "No se pudo obtener respuesta del servidor (Error 400)"
            })
        }
    }
}

</script>

<style lang="scss">

    @import '../assets/custom-theme.scss';

    #visitinfo {
        scrollbar-color: theme-color("primary") #00000000;
    }

    .comments {
        font-family: 'Courier New', Courier, monospace;
    }

    .visitfinished {
        color: theme-color("success")
    }

    .visitcancelled {
        color: theme-color("danger")
    }

    // Tickets

    .ticket a {
        color: #000000;
    }

    .ticket a:hover {
        text-decoration: none;
    }

    .ticket {
        background-color: #eeeeee;
        margin: 5px;
        padding: 5px;
        padding-left: 10px;
    }

    .tickettitle {
        font-size: 1.25em;
        font-weight: 600;
    }

    .tickettext {
        font-size: 0.75em;
    }

    .ticketdate {
        font-size: 0.75em;
    }

    .ticketisot {
        background-color: theme-color("info");
        font-weight: 700;
    }

    // Animacion del circulo

    #pulsecontainer {
        float: right;
        position: relative;
        margin-right: 25px;
        top: -18px;
    }

    .pulse {
        width: 10px;
        height: 10px;
        border: 5px solid #ff0000;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: #716f42;
        z-index: 10;
        position: absolute;
    }

    .dot {
        border: 10px solid #ff0000;
        background: transparent;
        -webkit-border-radius: 60px;
        -moz-border-radius: 60px;
        border-radius: 60px;
        height: 50px;
        width: 50px;
        -webkit-animation: pulse 3s ease-out;
        -moz-animation: pulse 3s ease-out;
        animation: pulse 3s ease-out;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: 1;
        opacity: 0;
    }

    .ticketquery {
        float: left;
        clear: none;
        display: flex;
    }

    @keyframes pulse {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
    }

    // Botones
    
    .buttonbar {
        display: flex;
        flex-direction: row;
    }

    .buttonbar a {
        color: #ffffff;
        font-size: 1.25em;
    }

    .btnteja {
        width: 75px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
    }

    .tejaicon {
        text-align: center;
        margin: 0 !important;
        //margin-top: 20px !important;
    }

    .rojo {
        background-color: #E36880;
    }

    .verde {
        background-color: #649F45;
    }

    .azul {
        background-color: #009AD4;
    }

    .pill {
        padding: 4px 8px;
        border-radius: 20px;
        margin-left: 10px;
    }

    .on-the-way-pill {
        background-color: #ffe082;
        color: #191d22;
    }

    .delayed-pill {
        background-color: #ca89f5;
        color: #191d22;
    }


    .header {
        display: flex;
        align-items: center;
    }

    @media only screen and (max-width: 450px) {
        .header {
            flex-direction: column;
            align-items: flex-start;
        }

        .on-the-way-pill {
            margin-left: 0;
        }
    }

</style>
