import { render, staticRenderFns } from "./TechOrderPendingList.vue?vue&type=template&id=68c1ca9b&scoped=true&"
import script from "./TechOrderPendingList.vue?vue&type=script&lang=js&"
export * from "./TechOrderPendingList.vue?vue&type=script&lang=js&"
import style0 from "./TechOrderPendingList.vue?vue&type=style&index=0&id=68c1ca9b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68c1ca9b",
  null
  
)

export default component.exports