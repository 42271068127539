 <template>                                                                                                                                                                                              
    <div id="report">
        <b-container v-if="loading" class="d-flex flex-column align-items-center justify-content-center">
            <h2>Cargando datos</h2>
            <p>Esto puede demorar unos segundos</p>
            <b-spinner class="mt-2" variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
        </b-container>
        <b-container class="mr-1 ml-1" fluid v-else>
            <b-card class="mt-2" title="Mis técnicos" sub-title="Listado de técnicos">
                <div class="subsidiaries-container justify-content-center mt-3">
                    <div v-for="subsidiaryInfo in userSubsidiaries" :key="subsidiaryInfo.subsidiary_id">
                        <b-button
                            @click="subsidiaryIDToFilter = subsidiaryInfo.subsidiary_id"
                            :variant="subsidiaryInfo.subsidiary_id === subsidiaryIDToFilter ? 'primary' : 'secondary'"
                        >
                            {{ subsidiaryInfo.name }}
                        </b-button>
                    </div>
                </div>
                <div class="techs-container" v-if="techs.length">
                    <div v-for="tech in filteredSubsidiaries" :key="tech.boxi_username" class="tech-card-container mt-4">
                        <div class="tech-card">
                            <div class="edit-container d-flex justify-content-end">
                                <b-button @click="showModal(tech.full_name)" variant="primary">
                                    <font-awesome-icon title="Mas informacion" icon="cog"/>
                                </b-button>
                            </div>
                            <div class="tech-pic">
                                <div v-if="tech.profile_pic" class="profile-container">
                                    <img :src="`data:image/jpeg;base64,${tech.profile_pic}`" alt="Foto de perfil" class="profilepic"/>
                                </div>
                                <div v-else class="profile-container">
                                    <img :src="getDefaultProfilePic()" alt="Foto de perfil" class="profilepic"/>
                                </div>
                            </div>
                            <div class="tech-info">
                                <p class="tech-name">{{ tech.full_name }}</p>
                                <div class="subsidiaries-container">
                                    <div class="mb-4" v-for="subsidiary_name in tech.subsidiaries_friendly" :key="subsidiary_name">
                                        <b-badge variant="secondary">{{ subsidiary_name }}</b-badge>
                                    </div>
                                </div>
                                <div class="pending-visits-container" @click="showModal(tech.full_name)">
                                    <p v-if="tech.pending_visits_count == 0">Sin visitas pendientes</p>
                                    <p v-else-if="tech.pending_visits_count == 1">{{ tech.pending_visits_count }} visita pendiente</p>
                                    <p v-else-if="tech.pending_visits_count > 1">{{ tech.pending_visits_count }} visitas pendientes</p>
                                    <p v-else>Sin datos sobre visitas pendientes</p>
                                </div>
                                <div class="icon-container d-flex">
                                    <b-img v-if="tech.active_on_agenda" :src="require('../../assets/images/agenda_active.png')" class="mr-1" v-b-tooltip.hover title="Usa Agenda"></b-img>
                                    <b-img v-else :src="require('../../assets/images/agenda_inactive.png')" class="mr-1" v-b-tooltip.hover title="No usa Agenda"></b-img>
                                    <b-img v-if="tech.active_on_telegram" :src="require('../../assets/images/telegram_active.png')" class="ml-1" v-b-tooltip.hover title="Usa Telegram"></b-img>
                                    <b-img v-else :src="require('../../assets/images/telegram_inactive.png')" class="ml-1" v-b-tooltip.hover title="No usa Telegram"></b-img>
                                </div>
                                <b-modal :id="tech.full_name" :title="tech.full_name" ok-only ok-title="Cerrar">
                                  <div class="pl-2 pr-2 modal-content d-flex flex-column align-items-center pt-1 border-0">
                                      <!-- <p class="mt-2"><strong>ID:</strong> {{ parseInt(tech.tech_id) }}</p> -->
                                      <!-- <p><strong>Operaciones:</strong> {{ tech.subsidiaries_friendly.join(", ") }}</p> -->
                                      <div class="w-100 text-center mt-1" v-if="tech.pending_visits_count > 0">
                                          <div class="mb-3">
                                              <h5 v-if="tech.pending_visits_count == 1"><strong>{{ tech.pending_visits_count }} visita pendiente</strong></h5>
                                              <h5 v-else><strong>{{ tech.pending_visits_count }} visitas pendientes</strong></h5>
                                              <span v-if="tech.pending_visits_are_over_limit">(Se muestran las {{ tech.pending_visits_limit }} más antiguas)</span>
                                          </div>
                                          <b-table class="mt-2" striped hover :borderless="true" :items="tech.pending_visits_data" :fields="pendingVisitsFields" :small="true">
                                              <template #cell(date_created)="data">
                                                  <p>{{ data.item.date_created }}</p>
                                              </template>
                                              <template #cell(visit_id)="data">
                                                  <a :href="$router.resolve({ name: 'get_visit', params: { visit_id: data.item.visit_id, mode: 'default' }}).href" target="_blank">{{ data.item.visit_id }}</a>
                                              </template>
                                              <template #cell(ot_number)="data">
                                                  <p>{{ data.item.ot_number }}</p>
                                              </template>
                                          </b-table>
                                      </div>
                                      <div v-else>
                                          <h5 class="mb-2"><strong>Sin visitas pendientes</strong></h5>
                                      </div>
                                      <div class="options-container w-100 text-center" v-if="tech.active_on_telegram">
                                          <div class="divider mt-1 mb-3"></div>
                                          <h5 class="mb-3"><strong>Opciones</strong></h5>
                                          <div class="telegram-btn-container" v-if="tech.active_on_telegram">
                                              <b-button block v-if="!deleting" @click="deleteTelegramChatId(tech.boxi_username)" variant="danger">Dar de baja en Telegram</b-button>
                                              <b-spinner variant="danger" v-else></b-spinner>
                                          </div>
                                      </div>
                                  </div>
                                </b-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import axios from "axios";
import store from "../../store";
import { getUserProfile } from "../../common"

export default {
    name: "MyTechsReport",
    data: function() {
        return {
            loading: true,
            subsidiaries: store.state.subsidiaries,
            techs: null,
            deleting: false,
            userData: null,
            userSubsidiaries: null,
            subsidiaryIDToFilter: null,
            pendingVisitsFields: [
                { 
                    'key': 'date_created',
                    'label': 'Fecha',
                },
                { 
                    'key': 'visit_id',
                    'label': 'Visita',
                },
                {
                    'key': 'ot_number',
                    'label': 'OT',
                },
            ],
        }
    },
    methods: {
        getTechsInfo: function() {
            const config = {
                "headers": {Authorization: `Bearer ${store.state.token}`},
            }

            const payload = {
                "subsidiaries": this.subsidiaries.split(","),
            }

            axios.post(this.$store.getters.getApiURL("api_subsidiary_get_techs"), payload, config)
                .then(response => {
                    this.techs = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteTelegramChatId: function(boxiUsername) {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const data = {boxi_username: boxiUsername}

            this.deleting = true;
            axios.post(this.$store.getters.getApiURL('api_user_delete_telegram_chat_id') , data, config)
                .then(() => {
                    this.deleting = false;
                    let i = this.techs.findIndex(tech => tech.boxi_username === boxiUsername);
                    this.techs[i].active_on_telegram = false;
                    this.$swal({ 
                        icon: 'success',
                        title: 'Exito',
                        text: "El usuario fue dado de baja correctamente"
                    });
                })
                .catch(() => {
                    this.deleting = false;
                    this.$swal({ 
                        icon: 'error',
                        title: 'Error',
                        text: "Hubo un error dando de baja al usuario",
                    });
                });
        },
        showModal(modalId) {
            this.$bvModal.show(modalId);
        },
        getDefaultProfilePic: function() {
            return `${this.$store.state.api_base_url}/static/img/default_profile_pic.png`;
        },
    },
    computed: {
      filteredSubsidiaries() {
        return this.techs.filter(item => item.subsidiaries.includes(this.subsidiaryIDToFilter));
      }
    },
    created: async function() {
        this.userData = await getUserProfile(this.$store.state.username, this.$store.state.token);
        this.userSubsidiaries = this.userData.subsidiaries;
        this.subsidiaryIDToFilter = this.userSubsidiaries[0].subsidiary_id;
        this.getTechsInfo();
        this.loading = false;
    },
}

</script>

<style scoped>
.techs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tech-card-container {
  padding: 1rem;
  margin: 1rem 0.5rem;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background-color: #fafafa;
  flex: 0 1 400px;
  max-width: 400px;
}

.tech-card {
  background-color: transparent;
}

.tech-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tech-name {
  font-size: 1.25rem;
}

.tech-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profilepic {
  height: 128px;
}

.profile-container {
  overflow:hidden;
  width: 128px;
  height: 128px;
  border-radius: 64px;
  margin-bottom: 15px;
}

.icon-container > img {
  width: 25px;
  height: 25px;
}

.subsidiaries-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pending-visits-container:hover > p {
  text-decoration: underline;
  cursor: pointer;
}

.divider {
  border-top: 1px solid #ecedee;
  width: 100%;
}
</style>

