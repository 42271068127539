<template>
    <b-container>
        <b-row class="text-center mt-4 align-items-center">
            <b-col cols="4" class="align-items-center justify-content-center">
                <div class="profilecontainer">
                        <img id="profilePic" class="profilepic" :src="buildProfilePic()">
                </div>
                <!-- <form id="uploadForm" enctype="multipart/form-data" v-on:change="uploadNewProfilePic">
                    <b-button @click="chooseFiles()">Cambiar imagen</b-button>
                    <input id="profilePicUpload" type="file" accept="image/*" hidden>
                </form> -->
            </b-col>
            <b-col cols="8">
                <h3>{{ fullName }}</h3>
                <p>Usuario: <b>{{ username }}</b></p>
                <p>{{ user['email'] }}</p>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="12">
                <p><b>Operaciones asignadas:</b> {{ user['subsidiaries'] ? user['subsidiaries'].map(subsidiary => subsidiary.name).join(", ") : "Ninguna" }}</p>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="12">
                <p><b>Tipo de perfil:</b> {{ user['profile'] }}</p>
            </b-col>
        </b-row>
        <div class="telegram-section mb-4" v-if="user.permissions.includes('can_edit_telegram_chat_id')">
            <div class="divider mt-2 mb-2"></div>
            <div class="config-info-container">
                <h4 class="mt-4 mb-3">Configuración de Telegram</h4>
                <p class="mb-2">Para suscribirte a las notificaciones de Telegram debés seguir los siguientes pasos:</p>
                <ul>
                    <li>Buscar en Telegram el bot de Gigared, llamado "AgendaTecBot"</li>
                    <li>Iniciar una conversación con el bot (el bot te brindará tu Identificador de Telegram)</li>
                    <li>Ingresar en la Agenda tu número de identificación (click en Vinculación con Telegram)</li>
                    <li>Verificar que hayas recibido un mensaje de confirmación en Telegram</li>
                </ul>
            </div>
            <div class="telegram-container mt-4 d-flex justify-content-start">
                <b-button v-if="!editTelegramChatId" variant="primary" @click="toggleEditTelegramChatId">Vinculación con Telegram</b-button>
                <div v-if="editTelegramChatId" class="d-flex flex-column justify-content-center">
                    <b-form-input v-model="telegramChatId" :state="validTelegramChatId" placeholder="Identificador de Telegram" class="mb-1"></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      El Identificador debe tener al menos 5 dígitos
                    </b-form-invalid-feedback>
                    <div class="d-flex flex-row justify-content-between mt-3">
                        <b-button variant="success" class="flex-grow-1 mr-1" @click="sendTelegramChatId">
                            <b-spinner small class="mr-2" v-if="settingTelegramChatId"></b-spinner>
                            <span>Guardar</span>
                        </b-button>
                        <b-button variant="danger" class="flex-grow-1 ml-1" @click="toggleEditTelegramChatId">Cancelar</b-button>
                    </div>
                </div>
            </div>
            <div v-if="user.active_on_telegram" class="telegram-container mt-4 d-flex justify-content-start">
                <b-button variant="danger" @click="deleteTelegramChatId">
                    <b-spinner small class="mr-2" v-if="deletingTelegramChatId"></b-spinner>
                    <span>Darse de baja de Telegram</span>
                </b-button>
            </div>
            <div class="divider mt-4 mb-2"></div>
            <div v-if="user.permissions.includes('can_subscribe_to_techs')">
                <h4 class="mt-4 mb-3">Notificaciones de mis técnicos</h4>
                <p class="mb-2">Podés suscribirte a las notificaciones de tus técnicos para recibir los mismos mensajes que ellos.</p>
                <div v-if="user.active_on_telegram">
                    <div v-if="!user.subscribed_to_techs">
                        <b-alert variant="danger" show>Actualmente no estás suscrito a las notificaciones de tus técnicos</b-alert>
                        <b-button variant="primary" @click="subscribeToTechs">
                            <b-spinner small class="mr-2" v-if="settingSubscribeToTechs"></b-spinner>
                            <span>Quiero suscribirme</span>
                        </b-button>
                    </div>
                    <div v-else>
                        <b-alert variant="success" show>Actualmente estás suscrito a las notificaciones de tus técnicos</b-alert>
                        <b-button variant="danger" @click="unsubscribeFromTechs">
                            <b-spinner small class="mr-2" v-if="settingUnsubscribeFromTechs"></b-spinner>
                            <span>Quiero desuscribirme</span>
                        </b-button>
                    </div>
                </div>
                <div v-else>
                    <b-alert variant="danger" show>Para suscribirte a las notificaciones de tus técnicos debés haber configurado Telegram</b-alert>
                </div>
                <div class="divider mt-4 mb-4"></div>
            </div>
        </div>
    </b-container>
</template>

<script>
    import store from '../store'
    import axios from 'axios'

    export default {
    name: "Profile",
    data() {
        return {
            fullName: store.state.firstName + " " + store.state.lastName,
            username: store.state.username,
            errormsg: '',
            user: {},
            editTelegramChatId: false,
            telegramChatId: null,
            settingTelegramChatId: false,
            deletingTelegramChatId: false,
            settingSubscribeToTechs: false,
            settingUnsubscribeFromTechs: false,
        }
    },
    store: store,
    methods: {
        chooseFiles() {
        document.getElementById("profilePicUpload").click()
        },
        buildProfilePic: function() {
            if(this.user.profilePic === "/static/img/default_profile_pic.png") {
                return `${this.$store.state.api_base_url}${this.user.profilePic}`;
            } else {
                return `data:image/png;base64,${this.user.profilePic}`;
            }
        },
        makeToast(title, message, append = false) {
            this.$bvToast.toast(message, {
                title: title,
                autoHideDelay: 5000,
                appendToast: append
            })
        },
        subscribeToTechs() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const data = {username: this.username}

            this.settingSubscribeToTechs = true;
            axios.post(this.$store.getters.getApiURL('api_user_subscribe_to_techs') , data, config)
                .then(() => {
                    console.log("Suscrito.");
                    this.user.subscribed_to_techs = true;
                })
                .catch((error) => {
                    const diagnostic = error.response.data.diagnostic;
                    this.$swal({ 
                        icon: 'error',
                        title: 'Error',
                        text: `Hubo un error: ${diagnostic}`,
                    });
                })
                .finally(() => {
                    this.settingSubscribeToTechs = false;
                });
        },
        unsubscribeFromTechs() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };
            console.log(store.state.token)

            const data = {username: this.username}
            
            this.settingUnsubscribeFromTechs = true;
            axios.post(this.$store.getters.getApiURL('api_user_unsubscribe_from_techs') , data, config)
                .then(() => {
                    console.log("Desuscrito.");
                    this.user.subscribed_to_techs = false;
                })
                .catch(error => {
                    const diagnostic = error.response.data.diagnostic;
                    this.$swal({ 
                        icon: 'error',
                        title: 'Error',
                        text: `Hubo un error: ${diagnostic}`,
                    });
                })
                .finally(() => {
                    this.settingUnsubscribeFromTechs = false;
                });
        },
        toggleEditTelegramChatId() {
            this.telegramChatId = null;
            this.editTelegramChatId = !this.editTelegramChatId;
        },
        sendTelegramChatId() {
            if(!this.validTelegramChatId) {
                return;
            }

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const data = {
                boxi_id: "",
                boxi_username: this.username,
                wa_number: null,
                telegram_chat_id: this.telegramChatId,
                tech_name: this.fullName,
            }

            this.settingTelegramChatId = true;
            axios.post(this.$store.getters.getApiURL('api_user_set_telegram_chat_id') , data, config)
                .then(() => {
                    this.settingTelegramChatId = false;
                    this.user.active_on_telegram = true;
                    this.$swal({ 
                        icon: 'success',
                        title: 'Datos guardados',
                        text: "Tus datos fueron guardados correctamente"
                    });
                })
                .catch(error => {
                    const diagnostic = error.response.data.diagnostic;
                    this.settingTelegramChatId = false;
                    if(diagnostic === "El numero identificador no existe.") {
                        this.user.active_on_telegram = false;
                        this.$swal({ 
                            icon: 'warning',
                            title: 'Atencion',
                            text: diagnostic,
                        });
                    } else {
                        this.$swal({ 
                            icon: 'error',
                            title: 'Error',
                            text: diagnostic,
                        });
                    }
                })
                .finally(() => {
                    this.toggleEditTelegramChatId();
                });
        },
        deleteTelegramChatId() {
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            const data = {boxi_username: this.username}

            this.deletingTelegramChatId = true;
            axios.post(this.$store.getters.getApiURL('api_user_delete_telegram_chat_id') , data, config)
                .then(() => {
                    this.deletingTelegramChatId = false;
                    this.user.active_on_telegram = false;
                    this.$swal({ 
                        icon: 'success',
                        title: 'Exito',
                        text: "Fuiste dado de baja correctamente"
                    });
                })
                .catch(error => {
                    const diagnostic = error.response.data.diagnostic;
                    this.deletingTelegramChatId = false;
                    this.$swal({ 
                        icon: 'error',
                        title: 'Error',
                        text: `Hubo un error: ${diagnostic}`,
                    });
                });
        },
    },
    computed: {
        validTelegramChatId() {
            if(this.telegramChatId) {
                return this.telegramChatId.length > 4;
            }
            return false;
        },
    },
    created: function() {
        const udata = {
            'username': store.state.username,
            'token': store.state.token
        }
        const config = {
            'headers': { Authorization: `Bearer ${store.state.token}` }
        };

            this.errormsg ="";
            axios.post(this.$store.getters.getApiURL('api_profile_url') , udata, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    this.user = response.data.data
                }
            })
            .catch(error => {
                this.errormsg = error
            });
        }
    }
</script>

<style lang="scss" scoped>
    .profilepic {
        height: 128px;
    }

    .profilecontainer {
        margin: auto;
        overflow:hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 128px;
        height: 128px;
        border-radius: 64px;
        margin-bottom: 15px;
    }

    .telegram-container {
        width: 100%;
    }

    .divider {
        border-top: 1px solid #bbb;
        width: 100%;
    }
</style>

