<template>
    <div id="tts-modal">
        <b-modal :id="ot_number + ticket.id" size="xl"  scrollable ok-only>
            <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <h4>{{ ticket.title }}</h4>
            <h5>
                <p><b>Ticket Nº: </b>{{ ticket.id }}</p>
                <p><b>Fecha: </b>{{ getDateFromRobotToHumanFormat(ticket.timestamp.substring(0,10)) }}</p>
            </h5>
            <b-button size="sm" variant="outline-info" @click="close()">
                X
            </b-button>
            </template>
            <div v-for="ticket_note in ticket.notes" :key="ticket_note.id" class="notas" :style="getStripeStyle(ticket_note.priority)">
                <!--<p><b>Nota <span v-if="ticket_note.internal">interna</span> #</b>{{ ticket_note.id }}</p>-->
                <p v-if="ticket_note.priority"><b>Nota prioritaria</b></p>
                <p><b>Fecha: </b> {{ ticket_note.timestamp }}</p>
                <p><b>De: </b> {{ ticket_note.from }}</p>
                <p><b>{{ ticket_note.title }}</b></p>
                <p>{{ ticket_note.body }}</p>
                <div v-if="ticket_note.attached_files.length > 0">
                    <p>Imágenes adjuntas:</p>
                    <div v-for="attachment in ticket_note.attached_files" :key="attachment.id">
                        <img :src="'data:image/png;base64, ' + attachment.content" class="attachment-img mt-2">
                        <p>{{ attachment.name }}</p>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
    name: "TtsInfoModal",
    props: {
        ot_number: String,
        ticket: Object
    },
    methods: {
        getStripeStyle: function(is_priority) {
            if (is_priority)
            {
                return "background-color: #EA9A27 !important;"
            }
        },
        getDateFromRobotToHumanFormat: function(fecha) {
            fecha = new Date(Date.parse(fecha));
            var dd = String(fecha.getDate()).padStart(2, '0');
            var mm = String(fecha.getMonth() + 1).padStart(2, '0');
            var yyyy = fecha.getFullYear();

            fecha = dd + '/' + mm + '/' + yyyy;
            return fecha
        },
    }
    }
</script>

<style lang="scss" scoped>

    @import '../assets/custom-theme.scss';

    .notas:nth-of-type(odd) {
        background: theme-color("primary-lighter");
    }
    .notas:nth-of-type(even) {
        background: theme-color("primary-lightest");
    }
    
    .attachment-img {
        max-width: 100%;
    }
</style>

