import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);


// Un ejemplo de uso del local Storage, con commit.
// mounted: function() {
//   this.$store.commit('clear') // Llama a la mutation clear
// }


export default new Vuex.Store({
    state: {
      token: "undefined",
      username: "undefined",
      firstName: "undefined",
      lastName: "undefined",
      subsidiaries: "undefined",
      permissions: "undefined",
      tokenExpires: "undefined",
      profilePic: "undefined",
      defaultView: "undefined",
      api_base_url: process.env.VUE_APP_API_URL,
      api_base_route: "v1",
      api_endpoints: {
	'api_client_info': 'client/info',
	'api_client_pending_orders': 'orders/pending_from_client',
        'api_labor': 'get_labor',
        'api_login_url': "login",
        'api_materials': 'get_materials',
        'api_order_url': "order",
        'api_order_repair_codes': "orders/repair_codes",
        'api_profile_url': "user/getuserinfo",
        'api_profile_uploadpic_url': "user/uploadprofilepic",
        'api_pending_orders_url': "orders/pending",
        'api_priorities': 'priorities',
        'api_priority_edit': 'priority/edit',
        'api_report_monthly': 'report/visits',
        'api_report_monthly_derivations': 'report/derivations_report',
        'api_report_cancellation_reasons': 'report/cancellation_reason',
        'api_report_late_schedule': 'report/late_schedule',
        'api_report_late_finish': 'report/late_finish',
        'api_report_on_the_way': 'report/on_the_way',
        'api_report_on_the_way_global': 'report/on_the_way_global',
        'api_report_technicians': 'report/tech_report',
        'api_report_reschedule': 'report/reschedule_report',
        'api_report_segments': 'report/segments_report',
        'api_report_segment': 'report/segment_report',
        'api_report_unclosed': 'report/unclosed',
        'api_subsidiary_capabilities': 'subsidiary/capabilities',
        'api_subsidiary_get_techs': 'subsidiary/get_techs',
        'api_timestripes': 'timestripes',
        'api_timestripes_edit': 'timestripe/edit',
        'api_timestripes_delete': 'timestripe/delete',
        'api_timestripes_eval': 'timestripe/eval',
        'api_tts_ws_query_tickets': "tickets/by_client",
        'api_user_set_telegram_chat_id': "user/set_telegram_chat_id",
        'api_user_subscribe_to_techs': "user/subscription",
        'api_user_unsubscribe_from_techs': "user/unsubscription",
        'api_user_delete_telegram_chat_id': "user/delete_telegram_chat_id",
        'api_visit_get_technicians_list': 'visit/get_technicians',
        'api_visit_get_timestripes_by_visit_id': 'visit/timestripes/by_visit_id',
        'api_visit_get_timestripes_by_date': 'visit/timestripes/by_date',
        'api_visit_create': 'visit/create',
        'api_visit_edit': 'visit/edit',
        'api_visit_set_on_the_way': 'visit/set_on_the_way',
        'api_visit_timestripe_detail': 'visits/timestripe',
        'api_visit_reschedule': 'visit/reschedule',
        'api_visit_detach': 'visit/detach',
        'api_visit_attach': 'visit/attach',
        'api_visit_finish': 'visit/finish',
        'api_visit_external': 'visit/by_token',
        'api_visits': 'visits',
        'api_visit_history': 'visit/history',
        'api_visit_info_id': 'visit/get/by_number',
        'api_visits_by_client': 'visit/get/by_client',
        'api_visits_by_tech_id': 'visit/get/by_tech_id',
        'api_visits_reasons_to_finish': 'visit/get_reasons_to_finish',
        'api_zones': 'zones',
        'api_zones_nodes': 'zones/nodes',
        'api_zone_edit': 'zones/edit',
        'api_zone_delete': 'zones/delete',
        'api_zone_get': 'zone',
        'api_allowed_subsidiaries': 'subsidiary/allowed',
      },
      sessionData: { }
    },
    mutations: {
      setSessionState: (state, payload) => {
        // Chequeo si la sesion tiene datos antes de guardarla
        const view_found = Object.keys(state.sessionData).indexOf(payload.view) > 0;
        if (!view_found) {
          state.sessionData[payload.view] = {} ;
        }
        state.sessionData[payload.view][payload.key] = payload.data;
        sessionStorage.setItem("sessionData", JSON.stringify(state.sessionData));
      },
      setToken: (state, payload) => {
        localStorage.setItem("token", payload.token)
        localStorage.setItem("username", payload.username)
        localStorage.setItem("firstName", payload.firstName)
        localStorage.setItem("lastName", payload.lastName)
        localStorage.setItem("subsidiaries", payload.subsidiaries)
        localStorage.setItem("permissions", payload.permissions)
        localStorage.setItem("tokenExpires", payload.tokenExpires)
        localStorage.setItem("profilePic", payload.profilePic)
        localStorage.setItem("defaultView", payload.defaultView)
        state.token = payload.token
        state.username = payload.username
        state.firstName = payload.firstName
        state.lastName = payload.lastName
        state.subsidiaries = payload.subsidiaries
        state.permissions = payload.permissions
        state.tokenExpires = payload.tokenExpires
        state.profilePic = payload.profilePic
        state.defaultView = payload.defaultView
      },
      setProfilePic: (state, url) => {
        localStorage.setItem("profilePic", url)
      },
      initialize: (state) => {
        if (localStorage.getItem("token") && state.token == "undefined")
        {
          state.token = localStorage.getItem("token")
        }
        if (localStorage.getItem("username") && state.username == "undefined")
        {
          state.username = localStorage.getItem("username")
        }
        if (localStorage.getItem("firstName") && state.firstName == "undefined")
        {
          state.firstName = localStorage.getItem("firstName")
        }
        if (localStorage.getItem("lastName") && state.lastName == "undefined")
        {
          state.lastName = localStorage.getItem("lastName")
        }
        if (localStorage.getItem("subsidiaries") && state.subsidiaries == "undefined")
        {
          state.subsidiaries = localStorage.getItem("subsidiaries")
        }
        if (localStorage.getItem("permissions") && state.permissions == "undefined")
        {
          state.permissions = localStorage.getItem("permissions")
        }
        if (localStorage.getItem("tokenExpires") && state.tokenExpires == "undefined")
        {
          state.tokenExpires = localStorage.getItem("tokenExpires")
        }
        if (localStorage.getItem("profilePic") && state.profilePic == "undefined")
        {
          state.profilePic = localStorage.getItem("profilePic")
        }
        if (localStorage.getItem("defaultView") && state.defaultView == "undefined")
        {
          state.defaultView = localStorage.getItem("defaultView")
        }
        //Cosas de sessionStorage
        if (sessionStorage.getItem("sessionData"))
        {
          state.sessionData = JSON.parse(sessionStorage.getItem("sessionData"));
          state.sessionData.loaded = true;
        } else {
          state.sessionData.loaded = true;
        }
      },
      clear: (state) => {
        state.token = "undefined"
        state.username = "undefined"
        state.firstName = "undefined"
        state.lastName = "undefined"
        state.subsidiaries = "undefined"
        state.permissions = "undefined"
        state.tokenExpires = "undefined"
        state.profilePic = "undefined"
        state.defaultView = "undefined"
        state.sessionData = {}
        localStorage.setItem("token", "undefined")
        localStorage.setItem("username", "undefined")
        localStorage.setItem("firstName", "undefined")
        localStorage.setItem("lastName", "undefined")
        localStorage.setItem("subsidiaries", "undefined")
        localStorage.setItem("permissions", "undefined")
        localStorage.setItem("tokenExpires", "undefined")
        localStorage.setItem("profilePic", "undefined")
        localStorage.setItem("defaultView", "undefined")
        sessionStorage.setItem("sessionData", "{}")
      }
    },
    getters: {
      getApiURL: (state) => (url) => {
        return state.api_base_url + "/" + state.api_base_route + "/" + state.api_endpoints[url]
      },
      getSessionState: (state) => (payload) => {
        const view_found = Object.keys(state.sessionData).indexOf(payload) >= 0;
        if (!view_found) {
          console.log(`No se encontró "${payload} ${new Date().getTime()}"`)
          return undefined;
        } 
        return state.sessionData[payload];
      }
    }
 });
