<template>
    <div id="report">
        <b-container class="mr-1 ml-1" fluid>
            <b-card title="Reporte de visitas sin cerrar" sub-title="Reporte histórico de visitas que no fueron cerradas.">
                <b-row cols="12">
                    <b-col v-if="subsidiaries_list" lg="12">
                        <b-row>
                            <b-col xl="6" lg="6" sm="12">
                                <b-row class="mt-3 mb-3">
                                    <b-col xl="4" lg="4" sm="4">
                                        <h4 class="component-label">Operación:</h4>
                                    </b-col>
                                    <b-col xl="8" lg="8" sm="8">
                                        <b-form-select
                                        id="input-3"
                                        v-model="form.subsidiary"
                                        :options="subsidiaries_list"
                                        value-field="subsidiary_id"
                                        text-field="name"
                                        required
                                        size="lg"
                                        ></b-form-select>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3 mb-3">
                                    <b-col>
                                        <b-form-group>
                                            <b-form-radio-group id="radio-group" v-model="form.timeframe" :options="options" size="lg" stacked></b-form-radio-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-btn variant="primary" @click="updateReport" class="ml-3">Actualizar</b-btn>
                </b-row>
            </b-card>
            <b-row v-if="loading" cols="12">
                <b-col class="mt-2 mb-2" lg="12">
                    <b-overlay :show="loading" rounded="sm">
                        <b-skeleton-img width="100%"></b-skeleton-img>
                    </b-overlay>
                </b-col>
            </b-row>
            <b-row v-if="report" cols="12">
                <b-col lg="12">
                    <b-table :items="report" :fields="fields">
                        <template #cell(id)="data">
                            <span class="mr-2">{{ data.item.id }}</span><a target="_blank" class="visit-link-unclosed-report" :href="$router.resolve({ name: 'get_visit', params:{ visit_id: data.item.id, mode: 'jefetec' } }).href">(Ver visita)</a>
                        </template>
                        <template #cell(delayed_while_on_the_way)="data">
                            {{ reportDelayedStatus(data.item) }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import axios from 'axios';
    import store from '../../store';

    import { getUserProfile } from "../../common"

    export default {
    name: "UnclosedReport",
    store: store,
    components: {},
    data: function() { 
        return {
            loading: false,
            subsidiaries_list: null,
            report: null,
            form: {
                subsidiary: null,
                timeframe: "all",
            },
            fields: [
                { key: 'id', label:'Visita', tdClass: 'col-1', sortable: true },
                { key: 'status', label:'Estado', tdClass: 'col-1', sortable: true },
                { key: 'ot', label:'OT', tdClass: 'col-1', sortable: true },
                { key: 'client_id', label:'Cliente', tdClass: 'col-1', sortable: true },
                { key: 'date_dispatch', label:'Fecha programada', tdClass: 'col-1', sortable: false },
                { key: 'tech_name', label:'Técnico', tdClass: 'col-1', sortable: true },
                { key: 'delayed_while_on_the_way', label:'Iba en camino', tdClass: 'col-1', sortable: true },
            ],
            options: [
                {text: "Todas las visitas", value: "all"},
                {text: "Sólo las de esta semana", value: "this_week"},
                {text: "Todas menos las de esta semana", value: "all_except_this_week"},
            ],
        }
    },
    created: async function() {
        {
            let tempPerms = await getUserProfile(store.state.username, store.state.token);
            this.subsidiaries_list = tempPerms.subsidiaries;
            if (this.subsidiaries_list.length > 0)
            {
                this.form.subsidiary = this.subsidiaries_list[0].subsidiary_id;
            }
        }
    },
    methods: {
        updateReport: function() {

            this.loading = true;
            this.report = null;

            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            };

            axios.post(this.$store.getters.getApiURL('api_report_unclosed') , this.form, config)
            .then(response => {
                this.report = this.applyRowVariants(this.flattenTechInfo(response.data.data.unclosed_visits));
            })
            .catch(error => {
                console.log(error);
                this.$swal({ 
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo generar el reporte'
                });
            })
            .finally(() => {
                this.loading = false;
            });
        },
        buildTechName: function(tech) {
            if(tech === null) {
                return "Sin técnico";
            } else if(Array.isArray(tech) && tech.length === 0) {
                return "No usa Agenda";
            } else {
                return `${tech[0].first_name} ${tech[0].last_name}`;
            }
        },
        flattenTechInfo: function(data) {
            return data.map(item => {
              const { tech, ...rest } = item;
              return {
                ...rest,
                tech_name: this.buildTechName(tech),
              };
            });
        },
        applyRowVariants: function(data) {
            return data.map(item => {
                if(item.delayed_while_on_the_way){
                    return {...item, _rowVariant: 'danger'}
                }
                return item;
            });
        },
        reportDelayedStatus: function(itemData) {
            if(itemData.status === "Cierre demorado") {
                if(itemData.delayed_while_on_the_way) {
                    return "Sí";
                }else{
                    return "No";
                }
            }else{
                return "";
            }
        },
    }
    }
</script>

<style>
    #report {
        overflow-y: scroll;
        display: flex;
        flex-grow: 1;
        height: 100%;
    }

    .month-picker-input {
        font-size: 1.25rem !important;
        padding: 9px !important;
        padding-left: 20px !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        color: #495057;
    }

    .month-picker-input-container input {
        width: 100% !important;
    }

    .month-picker-input-container {
        width: 100% !important;
    }

    .month-picker-container {
        z-index: 999 !important;
        position: relative;
    }

    .report-graphic {
        max-width: 100%;
    }

    .component-label {
        margin-top: 10px;
    }

    .visit-link-unclosed-report {
        color: #2892bc;
    }
    
</style>
