<template>
  <div :class="techorder_class">
      <b-container>
        <b-row>
            <b-col cols="7">
                <h4 v-if="order.repeating_failures"><img src="../assets/images/icns/fire.png" height="32">Fallas reiteradas</h4>
                <h3 class="order-number"><a href="#" @click="showOTDialog(order.ot_type, order.ot_number)">Nº Orden: {{ order.ot_number }}</a></h3>
                <OrderInfoModal :ot_type="order.ot_type" :ot_number="order.ot_number" mode="editor"></OrderInfoModal>
                <p class="priority-text">{{ order.priority_friendly }} | {{ order.priority }}</p>
                <p class="priority-text">{{ getDateFromRobotToHumanFormat(order.date_created) }}</p>
            </b-col>
            <b-col cols="5" class="text-right">
                <p class="order-type">{{order.ot_type_friendly}}</p><p class="order-node">{{ order.node }}</p>
                <p class="order-zone">{{order.node_zone}}</p>
            </b-col>
        </b-row>
        <hr>
        <b-row v-if="order.symptom_id > 0">
            <b-col cols="12">
                <div class="techorderfield">Síntoma:</div>
                <p>{{ order.symptom_friendly }}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <div class="techorderfield">NºCliente:</div>
                <p>{{ order.client_id }}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <div class="techorderfield">Dirección:</div>
                <p>{{ order.address }}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <div class="techorderfield">Localidad:</div>
                <p>{{ order.location }}</p>
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col cols="12">
                <p>OT Creada: {{ order.date_created }}</p>
            </b-col>
        </b-row> -->
        <b-row>
            <b-col cols="12">
                <div class="ticketquery">
                    <div class="techorderfield">Tickets:</div>
                    <img v-if="!loading_tickets && tickets == null && !tts_error" src="../assets/images/icns/zoom.png" width="20px" height="20px" @click="loadTickets" v-b-toggle="'ticket' + order.uuid" >
                    <img v-if="tts_error" width="18" height="18" title="No se pudo consultar los tickets en ticketera" src="../assets/images/icns/error.png">
                    <img v-if="loading_tickets" width="18" height="18" title="Cargando tickets..." src="../assets/images/icns/loading.gif">
                    <p v-if="!loading_tickets && tickets && tickets.length == 0 && !tts_error">No posee</p>
                </div>
            </b-col>
        </b-row>
        <!--<b-collapse :visible="has_priority_tickets"  :id="'ticket' + order.uuid" class="mt-2" > //00100146801-->
        <b-collapse :visible=true :id="'ticket' + order.uuid" class="mt-2" > 
            <div v-for="ticket in tickets" :key="ticket.id" :class="ticket.ot_number == order.ot_number? 'ticket ticketisot': 'ticket'">
                <!-- No enviamos a la gente a la ticketera ya que no va a tener acceso -->
                <!-- <a target="_blank" :href="url_tts_ticket + ticket.id"><p class="tickettitle" :title=ticket.body>{{ ticket.queue }}</p> -->
                <a href="#" @click="showTtsDialog(order.ot_number, ticket)">
                <!-- TODO: Mover a componente -->
                <!-- <b-modal :id=ticket.id size="xl" title="Detalles del ticket">{{ticket}}</b-modal> -->
                <TtsInfoModal :ot_number="order.ot_number" :ticket="ticket"></TtsInfoModal>
                <p class="tickettext" :title=ticket.body>{{ ticket.title }}<span v-if="ticket.ot_number == order.ot_number"> (De esta OT)</span></p>
                <p class="ticketdate" :title=ticket.body>{{ ticket.timestamp }}</p>
                </a>
                <div v-if="ticket.has_priority_notes" id="pulsecontainer">
                    <div class="dot"></div>
                    <div class="pulse"></div>
                </div>
            </div>
        </b-collapse>
      </b-container>
  </div>
</template>

<script>
import store from '../store'
import axios from 'axios'
import TtsInfoModal from './TtsInfoModal.vue'
import OrderInfoModal from './OrderInfoModal.vue'

export default {
    name: 'TechOrder',
    store: store,
    data() {
        return {
            techorder_class: 'techorder ' + this.order.priority_class,
            tickets: null,
            //   url_tts_ticket: store.state.url_tts_ticket,
            selected_ticket: null,
            tts_error: false,
            loading_tickets: false,
            has_priority_tickets: false,
        }
    },
    props: {
        order: Object
    },
    components: {
        TtsInfoModal,
        OrderInfoModal
    },
    created: function() {
        // Consulto tickets 
        // this.loadTickets()
        },
    methods: {
        getDateFromRobotToHumanFormat: function(fecha) {
            fecha = new Date(Date.parse(fecha));
            var dd = String(fecha.getDate()).padStart(2, '0');
            var mm = String(fecha.getMonth() + 1).padStart(2, '0');
            var yyyy = fecha.getFullYear();

            fecha = dd + '/' + mm + '/' + yyyy;
            return fecha
        },
        showTtsDialog: function(ot_number, ticket) {
            this.selected_ticket = ticket
            this.$bvModal.show(ot_number + ticket.id)
        },
        showOTDialog: function(ot_type, ot_number) {
            //console.log(ot_type + ot_number)
            this.$bvModal.show(ot_type + ot_number)
        },
        loadTickets: function() {
            this.loading_tickets = true
            const payload = {
                'client_id': this.order.client_id,
            }
            const config = {
                'headers': { Authorization: `Bearer ${store.state.token}` }
            }
            axios.post(this.$store.getters.getApiURL('api_tts_ws_query_tickets'), payload, config)
            .then(response => {
                if (response.data.code == 200)
                {
                    if (response.data.data.detail.priority_tickets_inside == true)
                    {
                        this.has_priority_tickets = true
                    }
                    if (typeof response.data.data.detail.tickets != "undefined")
                    {
                        this.tickets = response.data.data.detail.tickets
                    }
                }
                this.loading_tickets = false
            })
            .catch(error => {
                console.log(error)
                this.tts_error = true
                this.loading_tickets = false
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    @import '../assets/custom-theme.scss';

    p {
        font-size: 0.5em;
        margin: 0px 0px !important;
    }

    h3 {
        margin: 0px 0px !important;
    }

    .ticket a {
        color: #000000;
    }

    .ticket a:hover {
        text-decoration: none;
    }

    .ticket {
        background-color: #eeeeee;
        border-radius: 30px;
        padding: 5px;
        padding-left: 10px;
    }

    .tickettitle {
        font-size: 0.3em;
        font-weight: 600;
    }

    .tickettext {
        font-size: 0.3em;
    }

    .ticketdate {
        font-size: 0.2em;
    }

    .ticketisot {
        background-color: theme-color("info");
        font-weight: 700;
    }


    @mixin gradient($direction, $list) {
        background: -webkit-linear-gradient($direction, $list);
        background: -moz-linear-gradient($direction, $list);
        background: -o-linear-gradient($direction, $list);
        background: linear-gradient($direction, $list);
    }

    .priority-text {
        font-size: 0.4em;
    }

    .order-number {
        font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
        font-size: 0.6em;

        font-weight: 400;
    }

    .order-type {
        font-size: 0.5em;
        font-style: italic;
    }

    .ot-related {
        font-size: 0.3em;
    }

    .order-node {
        font-weight: 700;
    }

    .order-zone {
        font-size: 0.3em;
    }

    .techorder {
        min-height: 5em;
        width: 100%;
        padding: 0.15em;
        border-radius: 0.15em;
    }

    .techorderfield {
        font-size: 0.5em;
        width: 100px;
        font-weight: 700;
        float: left;
    }

    .normal
    {
        @include gradient(90deg, $reclamo_normal);
    }

    .low {
        @include gradient(90deg, $reclamo_baja);
    }

    .medium {
        @include gradient(90deg, $reclamo_media);
    }

    .high {
        @include gradient(90deg, $reclamo_alta);
    }

    .finished {
        @include gradient(90deg, $reclamo_finished);
    }

    .wip {
        @include gradient(90deg, $reclamo_wip);
    }

    // Animacion del circulo

    #pulsecontainer {
        float: right;
        position: relative;
        margin-right: 25px;
        top: -18px;
    }

    .pulse {
        width: 10px;
        height: 10px;
        border: 5px solid #ff0000;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: #716f42;
        z-index: 10;
        position: absolute;
    }

    .dot {
        border: 10px solid #ff0000;
        background: transparent;
        -webkit-border-radius: 60px;
        -moz-border-radius: 60px;
        border-radius: 60px;
        height: 50px;
        width: 50px;
        -webkit-animation: pulse 3s ease-out;
        -moz-animation: pulse 3s ease-out;
        animation: pulse 3s ease-out;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: 1;
        opacity: 0;
    }

    .ticketquery {
        float: left;
        clear: none;
        display: flex;
    }

    @keyframes pulse {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
    }

</style>
