import { render, staticRenderFns } from "./RescheduleVisitModal.vue?vue&type=template&id=104c6958&scoped=true&"
import script from "./RescheduleVisitModal.vue?vue&type=script&lang=js&"
export * from "./RescheduleVisitModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104c6958",
  null
  
)

export default component.exports