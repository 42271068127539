<template>
    <div>
        <b-row>
            <b-col cols="12" :lg="titleSize">
                <p><b>{{ title }}</b></p>
            </b-col>
            <b-col cols="12" :lg="slotSize">
                <slot></slot>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //class="d-flex align-items-md-center align-self-center"
    export default {
    name: "GigaredFormControl",
    props: {
        title: {
            type: String
        },
        titleSize: {
                type: String,
                default: "4"
        },
        slotSize: {
                type: String,
                default: "8"
        },
    }
    }
</script>

<style scoped>
</style>